<div class="dialog-hld">
  <div mat-dialog-title class="mat-dialog-title">
    <div class="title">{{ "translateRenameProfile" | translate }}</div>
  </div>

  <div mat-dialog-content class="content-hld" *ngIf="data">
    <div class="padd-t-10">
      <mat-form-field class="input-hld">
        <mat-label>{{ "translateProfileName" | translate }}</mat-label>
        <input matInput type="text" [(ngModel)]="profileName" (keydown.enter)="isValid()" (blur)="isValid()"
          autocomplete="off" />
      </mat-form-field>
      <mat-error class="error-hld" *ngIf="!validName">{{ error }}</mat-error>
    </div>
  </div>

  <div mat-dialog-actions fxLayout="row" fxLayoutAlign="end center" class="modal-footer">
    <div (click)="close()" class="action-btn">
      {{ "translateCancel" | translate }}
    </div>
    <div (click)="save()" class="action-btn">
      {{ "translateOk" | translate }}
    </div>
  </div>
</div>
