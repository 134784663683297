import { VoiceServiceResult } from '../services/intowords/intowords-voice.service';

export const voices: VoiceServiceResult = {
  limits: {
    speak: 2048,
    audioFile: 65536
  },
  voices: [
    {
      id: 'mv_da_acl',
      name: 'Stine',
      langCode: 'da-DK',
      features: [
        'TTS',
        'Math',
        'LetterName',
        'LetterSound'
      ],
      port: 8181,
      type: 'Festival',
      gender: 'Female',
      nuanceML: false
    },
    {
      id: 'mv_da_gsh',
      name: 'Anne',
      langCode: 'da-DK',
      features: [
        'TTS',
        'Math',
        'LetterName',
        'LetterSound'
      ],
      port: 8182,
      type: 'Festival',
      gender: 'Female',
      nuanceML: false
    },
    {
      id: 'mv_es_tc73',
      name: 'Manuel',
      langCode: 'es-ES',
      features: [
        'TTS',
        'Math',
        'LetterName'
      ],
      port: 8183,
      type: 'Festival',
      gender: 'Male',
      nuanceML: false
    },
    {
      id: 'mv_de_bits3',
      name: 'Felix',
      langCode: 'de-DE',
      features: [
        'TTS',
        'Math',
        'LetterName'
      ],
      port: 8184,
      type: 'Festival',
      gender: 'Male',
      nuanceML: false
    },
    {
      id: 'mv_sv_jm',
      name: 'Kristian',
      langCode: 'sv-SE',
      features: [
        'TTS',
        'Math',
        'LetterName',
        'LetterSound'
      ],
      port: 8185,
      type: 'Festival',
      gender: 'Male',
      nuanceML: false
    },
    {
      id: 'mv_nb_hk',
      name: 'Tor',
      langCode: 'nb-NO',
      features: [
        'TTS',
        'Math',
        'LetterName',
        'LetterSound'
      ],
      port: 8186,
      type: 'Festival',
      gender: 'Male',
      nuanceML: false
    },
    {
      id: 'mv_nn_kors',
      name: 'Olai',
      langCode: 'nn-NO',
      features: [
        'TTS',
        'Math',
        'LetterName',
        'LetterSound'
      ],
      port: 8187,
      type: 'Festival',
      gender: 'Male',
      nuanceML: false
    },
    {
      id: 'mv_en_crb',
      name: 'Claire',
      langCode: 'en-GB',
      features: [
        'TTS',
        'Math',
        'LetterName'
      ],
      port: 8188,
      type: 'Festival',
      gender: 'Female',
      nuanceML: false
    },
    {
      id: 'mv_es_tc72',
      name: 'Sofia',
      langCode: 'es-ES',
      features: [
        'TTS',
        'Math',
        'LetterName'
      ],
      port: 8189,
      type: 'Festival',
      gender: 'Female',
      nuanceML: false
    },
    {
      id: 'mv_de_bits4',
      name: 'Charlotte',
      langCode: 'de-DE',
      features: [
        'TTS',
        'Math',
        'LetterName'
      ],
      port: 8190,
      type: 'Festival',
      gender: 'Female',
      nuanceML: false
    },
    {
      id: 'nve_enu_samantha',
      name: 'Samantha',
      langCode: 'en-US',
      features: [
        'TTS',
        'LetterName'
      ],
      type: 'Nuance',
      gender: 'Female',
      nuanceML: false
    },
    {
      id: 'nve_enu_susan',
      name: 'Susan',
      langCode: 'en-US',
      features: [
        'TTS',
        'LetterName'
      ],
      type: 'Nuance',
      gender: 'Female',
      nuanceML: false
    },
    {
      id: 'nve_enu_tom',
      name: 'Tom',
      langCode: 'en-US',
      features: [
        'TTS',
        'LetterName'
      ],
      type: 'Nuance',
      gender: 'Male',
      nuanceML: false
    },
    {
      id: 'nve_enu_allison',
      name: 'Allison',
      langCode: 'en-US',
      features: [
        'TTS',
        'LetterName'
      ],
      type: 'Nuance',
      gender: 'Female',
      nuanceML: false
    },
    {
      id: 'nve_enu_ava',
      name: 'Ava',
      langCode: 'en-US',
      features: [
        'TTS',
        'LetterName'
      ],
      type: 'Nuance',
      gender: 'Female',
      nuanceML: true
    },
    {
      id: 'nve_dad_magnus',
      name: 'Magnus',
      langCode: 'da-DK',
      features: [
        'TTS',
        'LetterName',
        'LetterSound'
      ],
      type: 'Nuance',
      gender: 'Male',
      nuanceML: false
    },
    {
      id: 'nve_dad_sara',
      name: 'Sara',
      langCode: 'da-DK',
      features: [
        'TTS',
        'LetterName',
        'LetterSound'
      ],
      type: 'Nuance',
      gender: 'Female',
      nuanceML: false
    },
    {
      id: 'nve_eni_veena',
      name: 'Veena',
      langCode: 'en-IN',
      features: [
        'TTS',
        'LetterName'
      ],
      type: 'Nuance',
      gender: 'Female',
      nuanceML: false
    },
    {
      id: 'nve_rur_katya',
      name: 'Katya',
      langCode: 'ru-RU',
      features: [
        'TTS',
        'LetterName'
      ],
      type: 'Nuance',
      gender: 'Female',
      nuanceML: true
    },
    {
      id: 'nve_rur_yuri',
      name: 'Yuri',
      langCode: 'ru-RU',
      features: [
        'TTS',
        'LetterName'
      ],
      type: 'Nuance',
      gender: 'Male',
      nuanceML: false
    },
    {
      id: 'nve_rur_milena',
      name: 'Milena',
      langCode: 'ru-RU',
      features: [
        'TTS',
        'LetterName'
      ],
      type: 'Nuance',
      gender: 'Female',
      nuanceML: false
    },
    {
      id: 'nve_eng_kate',
      name: 'Kate',
      langCode: 'en-GB',
      features: [
        'TTS',
        'LetterName'
      ],
      type: 'Nuance',
      gender: 'Female',
      nuanceML: false
    },
    {
      id: 'nve_eng_oliver',
      name: 'Oliver',
      langCode: 'en-GB',
      features: [
        'TTS',
        'LetterName'
      ],
      type: 'Nuance',
      gender: 'Male',
      nuanceML: false
    },
    {
      id: 'nve_eng_serena',
      name: 'Serena',
      langCode: 'en-GB',
      features: [
        'TTS',
        'LetterName'
      ],
      type: 'Nuance',
      gender: 'Female',
      nuanceML: false
    },
    {
      id: 'nve_eng_daniel',
      name: 'Daniel',
      langCode: 'en-GB',
      features: [
        'TTS',
        'LetterName'
      ],
      type: 'Nuance',
      gender: 'Male',
      nuanceML: false
    },
    {
      id: 'nve_eng_malcolm',
      name: 'Malcolm',
      langCode: 'en-GB',
      features: [
        'TTS',
        'LetterName'
      ],
      type: 'Nuance',
      gender: 'Male',
      nuanceML: false
    },
    {
      id: 'nve_ged_petra',
      name: 'Petra',
      langCode: 'de-DE',
      features: [
        'TTS',
        'LetterName'
      ],
      type: 'Nuance',
      gender: 'Female',
      nuanceML: true
    },
    {
      id: 'nve_ged_markus',
      name: 'Markus',
      langCode: 'de-DE',
      features: [
        'TTS',
        'LetterName'
      ],
      type: 'Nuance',
      gender: 'Male',
      nuanceML: false
    },
    {
      id: 'nve_ged_anna',
      name: 'Anna',
      langCode: 'de-DE',
      features: [
        'TTS',
        'LetterName'
      ],
      type: 'Nuance',
      gender: 'Female',
      nuanceML: true
    },
    {
      id: 'nve_ged_yannick',
      name: 'Yannick',
      langCode: 'de-DE',
      features: [
        'TTS',
        'LetterName'
      ],
      type: 'Nuance',
      gender: 'Male',
      nuanceML: false
    },
    {
      id: 'nve_ged_viktor',
      name: 'Viktor',
      langCode: 'de-DE',
      features: [
        'TTS',
        'LetterName'
      ],
      type: 'Nuance',
      gender: 'Male',
      nuanceML: false
    },
    {
      id: 'nve_frf_audrey',
      name: 'Audrey',
      langCode: 'fr-FR',
      features: [
        'TTS',
        'LetterName'
      ],
      type: 'Nuance',
      gender: 'Female',
      nuanceML: true
    },
    {
      id: 'nve_frf_aurelie',
      name: 'Aurelie',
      langCode: 'fr-FR',
      features: [
        'TTS',
        'LetterName'
      ],
      type: 'Nuance',
      gender: 'Female',
      nuanceML: false
    },
    {
      id: 'nve_frf_thomas',
      name: 'Thomas',
      langCode: 'fr-FR',
      features: [
        'TTS',
        'LetterName'
      ],
      type: 'Nuance',
      gender: 'Male',
      nuanceML: false
    },
    {
      id: 'nve_spe_marisol',
      name: 'Marisol',
      langCode: 'es-ES',
      features: [
        'TTS',
        'LetterName'
      ],
      type: 'Nuance',
      gender: 'Female',
      nuanceML: true
    },
    {
      id: 'nve_spe_jorge',
      name: 'Jorge',
      langCode: 'es-ES',
      features: [
        'TTS',
        'LetterName'
      ],
      type: 'Nuance',
      gender: 'Male',
      nuanceML: false
    },
    {
      id: 'nve_spe_monica',
      name: 'Monica',
      langCode: 'es-ES',
      features: [
        'TTS',
        'LetterName'
      ],
      type: 'Nuance',
      gender: 'Female',
      nuanceML: true
    },
    {
      id: 'nve_sws_klara',
      name: 'Klara',
      langCode: 'sv-SE',
      features: [
        'TTS',
        'LetterName',
        'LetterSound'
      ],
      type: 'Nuance',
      gender: 'Female',
      nuanceML: false
    },
    {
      id: 'nve_sws_alva',
      name: 'Alva',
      langCode: 'sv-SE',
      features: [
        'TTS',
        'LetterName',
        'LetterSound'
      ],
      type: 'Nuance',
      gender: 'Female',
      nuanceML: false
    },
    {
      id: 'nve_sws_oskar',
      name: 'Oskar',
      langCode: 'sv-SE',
      features: [
        'TTS',
        'LetterName',
        'LetterSound'
      ],
      type: 'Nuance',
      gender: 'Male',
      nuanceML: false
    },
    {
      id: 'nve_non_nora',
      name: 'Nora',
      langCode: 'nb-NO',
      features: [
        'TTS',
        'LetterName',
        'LetterSound'
      ],
      type: 'Nuance',
      gender: 'Female',
      nuanceML: false
    },
    {
      id: 'nve_non_henrik',
      name: 'Henrik',
      langCode: 'nb-NO',
      features: [
        'TTS',
        'LetterName',
        'LetterSound'
      ],
      type: 'Nuance',
      gender: 'Male',
      nuanceML: false
    },
    {
      id: 'nve_spm_angelica',
      name: 'Angelica',
      langCode: 'es-MX',
      features: [
        'TTS',
        'LetterName'
      ],
      type: 'Nuance',
      gender: 'Female',
      nuanceML: false
    },
    {
      id: 'nve_spm_paulina',
      name: 'Paulina',
      langCode: 'es-MX',
      features: [
        'TTS',
        'LetterName'
      ],
      type: 'Nuance',
      gender: 'Female',
      nuanceML: false
    },
    {
      id: 'nve_spm_juan',
      name: 'Juan',
      langCode: 'es-MX',
      features: [
        'TTS',
        'LetterName'
      ],
      type: 'Nuance',
      gender: 'Male',
      nuanceML: false
    },
    {
      id: 'nve_idi_damayanti',
      name: 'Damayanti',
      langCode: 'id-ID',
      features: [
        'TTS',
        'LetterName'
      ],
      type: 'Nuance',
      gender: 'Female',
      nuanceML: false
    },
    {
      id: 'nve_spc_soledad',
      name: 'Soledad',
      langCode: 'es-CO',
      features: [
        'TTS',
        'LetterName'
      ],
      type: 'Nuance',
      gender: 'Female',
      nuanceML: false
    },
    {
      id: 'nve_spc_carlos',
      name: 'Carlos',
      langCode: 'es-CO',
      features: [
        'TTS',
        'LetterName'
      ],
      type: 'Nuance',
      gender: 'Male',
      nuanceML: false
    },
    {
      id: 'nve_ptp_catarina',
      name: 'Catarina',
      langCode: 'pt-PT',
      features: [
        'TTS',
        'LetterName'
      ],
      type: 'Nuance',
      gender: 'Female',
      nuanceML: false
    },
    {
      id: 'nve_ptp_joana',
      name: 'Joana',
      langCode: 'pt-PT',
      features: [
        'TTS',
        'LetterName'
      ],
      type: 'Nuance',
      gender: 'Female',
      nuanceML: false
    },
    {
      id: 'nve_ptp_joaquim',
      name: 'Joaquim',
      langCode: 'pt-PT',
      features: [
        'TTS',
        'LetterName'
      ],
      type: 'Nuance',
      gender: 'Male',
      nuanceML: false
    },
    {
      id: 'nve_enz_tessa',
      name: 'Tessa',
      langCode: 'en-ZA',
      features: [
        'TTS',
        'LetterName'
      ],
      type: 'Nuance',
      gender: 'Female',
      nuanceML: false
    },
    {
      id: 'nve_frc_amelie',
      name: 'Amelie',
      langCode: 'fr-CA',
      features: [
        'TTS',
        'LetterName'
      ],
      type: 'Nuance',
      gender: 'Female',
      nuanceML: true
    },
    {
      id: 'nve_frc_chantal',
      name: 'Chantal',
      langCode: 'fr-CA',
      features: [
        'TTS',
        'LetterName'
      ],
      type: 'Nuance',
      gender: 'Female',
      nuanceML: false
    },
    {
      id: 'nve_frc_nicolas',
      name: 'Nicolas',
      langCode: 'fr-CA',
      features: [
        'TTS',
        'LetterName'
      ],
      type: 'Nuance',
      gender: 'Male',
      nuanceML: false
    },
    {
      id: 'nve_czc_iveta',
      name: 'Iveta',
      langCode: 'cs-CZ',
      features: [
        'TTS',
        'LetterName'
      ],
      type: 'Nuance',
      gender: 'Female',
      nuanceML: false
    },
    {
      id: 'nve_czc_zuzana',
      name: 'Zuzana',
      langCode: 'cs-CZ',
      features: [
        'TTS',
        'LetterName'
      ],
      type: 'Nuance',
      gender: 'Female',
      nuanceML: true
    },
    {
      id: 'nve_trt_cem',
      name: 'Cem',
      langCode: 'tr-TR',
      features: [
        'TTS',
        'LetterName'
      ],
      type: 'Nuance',
      gender: 'Male',
      nuanceML: true
    },
    {
      id: 'nve_trt_yelda',
      name: 'Yelda',
      langCode: 'tr-TR',
      features: [
        'TTS',
        'LetterName'
      ],
      type: 'Nuance',
      gender: 'Female',
      nuanceML: false
    },
    {
      id: 'nve_ena_lee',
      name: 'Lee',
      langCode: 'en-AU',
      features: [
        'TTS',
        'LetterName'
      ],
      type: 'Nuance',
      gender: 'Male',
      nuanceML: false
    },
    {
      id: 'nve_ena_karen',
      name: 'Karen',
      langCode: 'en-AU',
      features: [
        'TTS',
        'LetterName'
      ],
      type: 'Nuance',
      gender: 'Female',
      nuanceML: false
    },
    {
      id: 'nve_dun_claire',
      name: 'Claire',
      langCode: 'nl-NL',
      features: [
        'TTS',
        'LetterName',
        'LetterSound'
      ],
      type: 'Nuance',
      gender: 'Female',
      nuanceML: true
    },
    {
      id: 'nve_dun_xander',
      name: 'Xander',
      langCode: 'nl-NL',
      features: [
        'TTS',
        'LetterName',
        'LetterSound'
      ],
      type: 'Nuance',
      gender: 'Male',
      nuanceML: false
    },
    {
      id: 'nve_spa_diego',
      name: 'Diego',
      langCode: 'es-AR',
      features: [
        'TTS',
        'LetterName'
      ],
      type: 'Nuance',
      gender: 'Male',
      nuanceML: false
    },
    {
      id: 'nve_spa_isabela',
      name: 'Isabela',
      langCode: 'es-AR',
      features: [
        'TTS',
        'LetterName'
      ],
      type: 'Nuance',
      gender: 'Female',
      nuanceML: false
    },
    {
      id: 'nve_iti_alice',
      name: 'Alice',
      langCode: 'it-IT',
      features: [
        'TTS',
        'LetterName'
      ],
      type: 'Nuance',
      gender: 'Female',
      nuanceML: true
    },
    {
      id: 'nve_iti_federica',
      name: 'Federica',
      langCode: 'it-IT',
      features: [
        'TTS',
        'LetterName'
      ],
      type: 'Nuance',
      gender: 'Female',
      nuanceML: true
    },
    {
      id: 'nve_iti_luca',
      name: 'Luca',
      langCode: 'it-IT',
      features: [
        'TTS',
        'LetterName'
      ],
      type: 'Nuance',
      gender: 'Male',
      nuanceML: false
    },
    {
      id: 'nve_iti_paola',
      name: 'Paola',
      langCode: 'it-IT',
      features: [
        'TTS',
        'LetterName'
      ],
      type: 'Nuance',
      gender: 'Female',
      nuanceML: false
    },
    {
      id: 'nve_bae_miren',
      name: 'Miren',
      langCode: 'eu-ES',
      features: [
        'TTS',
        'LetterName'
      ],
      type: 'Nuance',
      gender: 'Female',
      nuanceML: false
    },
    {
      id: 'nve_huh_mariska',
      name: 'Mariska',
      langCode: 'hu-HU',
      features: [
        'TTS',
        'LetterName'
      ],
      type: 'Nuance',
      gender: 'Female',
      nuanceML: false
    },
    {
      id: 'nve_cah_sin-ji',
      name: 'Sin-Ji',
      langCode: 'zh-HK',
      features: [
        'TTS',
        'LetterName'
      ],
      type: 'Nuance',
      gender: 'Female',
      nuanceML: true
    },
    {
      id: 'nve_mnt_mei-jia',
      name: 'Mei-Jia',
      langCode: 'zh-TW',
      features: [
        'TTS',
        'LetterName'
      ],
      type: 'Nuance',
      gender: 'Female',
      nuanceML: true
    },
    {
      id: 'nve_sks_laura',
      name: 'Laura',
      langCode: 'sk-SK',
      features: [
        'TTS',
        'LetterName'
      ],
      type: 'Nuance',
      gender: 'Female',
      nuanceML: false
    },
    {
      id: 'nve_dub_ellen',
      name: 'Ellen',
      langCode: 'nl-BE',
      features: [
        'TTS',
        'LetterName'
      ],
      type: 'Nuance',
      gender: 'Female',
      nuanceML: false
    },
    {
      id: 'nve_bgb_daria',
      name: 'Daria',
      langCode: 'bg-BG',
      features: [
        'TTS',
        'LetterName'
      ],
      type: 'Nuance',
      gender: 'Female',
      nuanceML: false
    },
    {
      id: 'nve_grg_nikos',
      name: 'Nikos',
      langCode: 'el-GR',
      features: [
        'TTS',
        'LetterName'
      ],
      type: 'Nuance',
      gender: 'Male',
      nuanceML: false
    },
    {
      id: 'nve_grg_melina',
      name: 'Melina',
      langCode: 'el-GR',
      features: [
        'TTS',
        'LetterName'
      ],
      type: 'Nuance',
      gender: 'Female',
      nuanceML: false
    },
    {
      id: 'nve_jpj_ayane',
      name: 'Ayane',
      langCode: 'ja-JP',
      features: [
        'TTS',
        'LetterName'
      ],
      type: 'Nuance',
      gender: 'Female',
      nuanceML: false
    },
    {
      id: 'nve_jpj_ichiro',
      name: 'Ichiro',
      langCode: 'ja-JP',
      features: [
        'TTS',
        'LetterName'
      ],
      type: 'Nuance',
      gender: 'Male',
      nuanceML: false
    },
    {
      id: 'nve_kok_sora',
      name: 'Sora',
      langCode: 'ko-KR',
      features: [
        'TTS',
        'LetterName'
      ],
      type: 'Nuance',
      gender: 'Female',
      nuanceML: false
    },
    {
      id: 'nve_cae_montserrat',
      name: 'Montserrat',
      langCode: 'ca-ES',
      features: [
        'TTS',
        'LetterName'
      ],
      type: 'Nuance',
      gender: 'Female',
      nuanceML: false
    },
    {
      id: 'nve_vae_empar',
      name: 'Empar',
      langCode: 'ca-ES',
      features: [
        'TTS',
        'LetterName'
      ],
      type: 'Nuance',
      gender: 'Female',
      nuanceML: false
    },
    {
      id: 'nve_cae_jordi',
      name: 'Jordi',
      langCode: 'ca-ES',
      features: [
        'TTS',
        'LetterName'
      ],
      type: 'Nuance',
      gender: 'Male',
      nuanceML: false
    },
    {
      id: 'nve_ror_ioana',
      name: 'Ioana',
      langCode: 'ro-RO',
      features: [
        'TTS',
        'LetterName'
      ],
      type: 'Nuance',
      gender: 'Female',
      nuanceML: false
    },
    {
      id: 'nve_gle_carmela',
      name: 'Carmela',
      langCode: 'gl-ES',
      features: [
        'TTS',
        'LetterName'
      ],
      type: 'Nuance',
      gender: 'Female',
      nuanceML: false
    },
    {
      id: 'nve_mnc_tian-tian',
      name: 'Tian-Tian',
      langCode: 'zh-CN',
      features: [
        'TTS',
        'LetterName'
      ],
      type: 'Nuance',
      gender: 'Female',
      nuanceML: true
    },
    {
      id: 'nve_mnc_li-li',
      name: 'Li-Li',
      langCode: 'zh-CN',
      features: [
        'TTS',
        'LetterName'
      ],
      type: 'Nuance',
      gender: 'Female',
      nuanceML: true
    },
    {
      id: 'nve_ptb_luciana',
      name: 'Luciana',
      langCode: 'pt-BR',
      features: [
        'TTS',
        'LetterName'
      ],
      type: 'Nuance',
      gender: 'Female',
      nuanceML: false
    },
    {
      id: 'nve_ptb_felipe',
      name: 'Felipe',
      langCode: 'pt-BR',
      features: [
        'TTS',
        'LetterName'
      ],
      type: 'Nuance',
      gender: 'Male',
      nuanceML: false
    },
    {
      id: 'nve_arw_maged',
      name: 'Maged',
      langCode: 'ar-WW',
      features: [
        'TTS',
        'LetterName'
      ],
      type: 'Nuance',
      gender: 'Male',
      nuanceML: true
    },
    {
      id: 'nve_arw_laila',
      name: 'Laila',
      langCode: 'ar-WW',
      features: [
        'TTS',
        'LetterName'
      ],
      type: 'Nuance',
      gender: 'Female',
      nuanceML: false
    },
    {
      id: 'nve_arw_tarik',
      name: 'Tarik',
      langCode: 'ar-WW',
      features: [
        'TTS',
        'LetterName'
      ],
      type: 'Nuance',
      gender: 'Male',
      nuanceML: false
    },
    {
      id: 'nve_hii_lekha',
      name: 'Lekha',
      langCode: 'hi-IN',
      features: [
        'TTS',
        'LetterName'
      ],
      type: 'Nuance',
      gender: 'Female',
      nuanceML: false
    },
    {
      id: 'nve_ene_moira',
      name: 'Moira',
      langCode: 'en-IE',
      features: [
        'TTS',
        'LetterName'
      ],
      type: 'Nuance',
      gender: 'Female',
      nuanceML: false
    },
    {
      id: 'nve_ens_fiona',
      name: 'Fiona',
      langCode: 'en-SC',
      features: [
        'TTS',
        'LetterName'
      ],
      type: 'Nuance',
      gender: 'Female',
      nuanceML: false
    },
    {
      id: 'nve_tht_kanya',
      name: 'Kanya',
      langCode: 'th-TH',
      features: [
        'TTS',
        'LetterName'
      ],
      type: 'Nuance',
      gender: 'Female',
      nuanceML: false
    },
    {
      id: 'nve_hei_carmit',
      name: 'Carmit',
      langCode: 'he-IL',
      features: [
        'TTS',
        'LetterName'
      ],
      type: 'Nuance',
      gender: 'Female',
      nuanceML: false
    },
    {
      id: 'nve_plp_krzysztof',
      name: 'Krzysztof',
      langCode: 'pl-PL',
      features: [
        'TTS',
        'LetterName'
      ],
      type: 'Nuance',
      gender: 'Male',
      nuanceML: false
    },
    {
      id: 'nve_plp_zosia',
      name: 'Zosia',
      langCode: 'pl-PL',
      features: [
        'TTS',
        'LetterName'
      ],
      type: 'Nuance',
      gender: 'Female',
      nuanceML: false
    },
    {
      id: 'nve_plp_ewa',
      name: 'Ewa',
      langCode: 'pl-PL',
      features: [
        'TTS',
        'LetterName'
      ],
      type: 'Nuance',
      gender: 'Female',
      nuanceML: false
    },
    {
      id: 'nve_fif_onni',
      name: 'Onni',
      langCode: 'fi-FI',
      features: [
        'TTS',
        'LetterName'
      ],
      type: 'Nuance',
      gender: 'Male',
      nuanceML: false
    },
    {
      id: 'nve_fif_satu',
      name: 'Satu',
      langCode: 'fi-FI',
      features: [
        'TTS',
        'LetterName'
      ],
      type: 'Nuance',
      gender: 'Female',
      nuanceML: false
    }
  ]
};
