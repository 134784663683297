import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { InterceptorLoaderService } from 'src/app/services/interceptor-loader.service';

/**
 * This component is the custom InterceptorLoader component.
 */
@Component({
  selector: 'app-shared-components-interceptor-loader',
  templateUrl: './interceptor-loader.component.html',
  styleUrls: ['./interceptor-loader.component.scss'],
})
export class InterceptorLoaderComponent implements AfterViewInit, OnDestroy {
  // The value used to show/hide the loader.
  isLoading = false;
  // Stores the subscribers until they're destroyed.
  private readonly destroyed = new ReplaySubject<never>();

  /**
   * Constructor function responsible for injecting the needed services.
   *
   * @param interceptorLoaderService Is an instance of InterceptorLoaderService.
   * @param cdr Is an instance of ChangeDetectorRef.
   */
  constructor(
    private interceptorLoaderService: InterceptorLoaderService,
    private readonly cdr: ChangeDetectorRef
  ) {}

  /**
   * A callback method that is invoked immediately after Angular has completed initialization of a component's view.
   * It is invoked only once when the view is instantiated.
   */
  ngAfterViewInit(): void {
    this.interceptorLoaderService.isLoading
      .pipe(takeUntil(this.destroyed))
      .subscribe((data) => {
        this.isLoading = data;
        this.cdr.detectChanges();
      });
  }

  /**
   * Unsubscribe Observables and detach event handlers to avoid memory leaks.
   */
  ngOnDestroy(): void {
    this.destroyed.next();
  }
}
