<div>
  <app-shared-components-top-menu></app-shared-components-top-menu>
  <div class="content-hld">
    <div class="padd-l-r-16">
      <div class="profile-hld">
        <div class="settings-label">
          {{ "translateProfileName" | translate }}
        </div>
        <div>
          <div class="dropdown-btn" mat-button [matMenuTriggerFor]="profileName" fxLayout="row">
            <div fxFlex>
              {{ selectedProfile.profile.name }}
            </div>
            <div fxFlex="nogrow" class="padd-t-7">
              <mat-icon>expand_more</mat-icon>
            </div>
          </div>
          <mat-menu #profileName="matMenu" class="panel-full-width">
            <app-shared-common-settings-profile-language-list [profiles]="profiles"
              (profileToEdit)="setSelectedProfile($event)">
            </app-shared-common-settings-profile-language-list>
          </mat-menu>
        </div>
      </div>

      <div class="actions-hld rename">
        <div class="reset-btn" (click)="renameProfile(selectedProfile)">
          <mat-icon [svgIcon]="'reset'" class="app-icon-hover action"></mat-icon>
          <div class="btn-name">{{ "translateRename" | translate }}</div>
        </div>
      </div>
      <div class="actions-hld">
        <div class="reset-btn red" (click)="removeProfile(selectedProfile)">
          <mat-icon [svgIcon]="'icon-Delete'" class="app-icon-hover action delete"></mat-icon>
          <div class="btn-name">{{ "translateDeleteProfile" | translate }}</div>
        </div>
      </div>
      <div class="actions-hlds">
        <div mat-button class="reset-btn blue" (click)="createProfile()">
          <mat-icon [svgIcon]="'icon-Create-new'" class="app-icon-hover action create"></mat-icon>
          <div class="btn-name">{{ "translateCreateNew" | translate }}</div>
        </div>
      </div>
    </div>

    <div class="speech-hld brd-top" (click)="goToSpeech()">
      <div class="cont">
        <mat-icon [svgIcon]="'settings_speech'" class="app-icon-hover"></mat-icon>
        <div class="title">{{ "translateSpeech" | translate }}</div>
      </div>
      <mat-icon class="right-arrow">chevron_right</mat-icon>
    </div>
    <div class="speech-hld" (click)="goToSuggestions()">
      <div class="cont">
        <mat-icon [svgIcon]="'settings_suggestions'" class="app-icon-hover"></mat-icon>
        <div class="title">{{ "translateSuggestions" | translate }}</div>
      </div>
      <mat-icon class="right-arrow">chevron_right</mat-icon>
    </div>

  </div>
</div>
