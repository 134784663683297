<div class="page-hld">
  <app-shared-components-top-menu class="top-menu-hld"></app-shared-components-top-menu>
  <div class="content-hld">
    <div>
      <div class="play-btn">
        <mat-icon svgIcon="play" class="app-icon-hover"></mat-icon>
        Play
      </div>
    </div>
    <div class="speed-hld">
      <app-shared-common-settings-speech-speed [currentProfile]="currentProfile">
      </app-shared-common-settings-speech-speed>
    </div>
    <div>
      <app-settings-suggesstions-list></app-settings-suggesstions-list>
    </div>
  </div>
</div>
