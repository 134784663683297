<div class="menu-item speed-hld" appDisableMouseClick>
  <div class="padd-l-r-8 slider-holder">
    <mat-slider
      class="readingSpeed-slider"
      [max]="maxSpeed"
      [min]="minSpeed"
      [step]="minSpeed"
      [tickInterval]="1"
      [thumbLabel]="true"
      [(ngModel)]="readingSpeed"
      (change)="updateReadingSpeed()"
    >
    </mat-slider>
  </div>
  <div fxLayout="row" fxLayoutAlign="space-between center" class="icon-hld">
    <mat-icon svgIcon="turtle" class="app-icon-hover"></mat-icon>
    <mat-icon svgIcon="rabbit" class="app-icon-hover"></mat-icon>
  </div>
  <div
    fxLayout="row"
    fxLayoutAlign="space-between center"
    class="speed-display"
  >
    <div class="speed-display-text">
      {{ "translateSpeedIsSetTo" | translate }}
    </div>
    <input
      type="number"
      class="speed-display-box"
      [(ngModel)]="readingSpeed"
      (blur)="updateReadingSpeed()"
      (keydown.enter)="updateReadingSpeed()"
    />
  </div>
</div>
