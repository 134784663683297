import { Component, OnInit, Input } from '@angular/core';

/**
 * This component is the custom spinner component.
 */
@Component({
  selector: 'app-shared-components-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
})
export class SpinnerComponent implements OnInit {
  // The input data that will show/hide the SpinnerComponent.
  @Input() preload: boolean;

  // Determine whether the loader should display on full page or inside container.
  @Input() fullPage = true;

  /**
   * A lifecycle hook that is called after Angular has initialized all data-bound properties of a directive.
   */
  ngOnInit(): void {}
}
