import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { SUGGESTION_LANGUAGE_CODES } from '../common/constants';
import { TopicDictionariesResult } from './intowords/intowords-topic-dictionaries.service';

/**
 * SuggestionStateHandlerService is used to handle suggestion and related states.
 */
@Injectable({
  providedIn: 'root',
})
export class SuggestionStateHandlerService {
  // Stores local storage suggestion key for integrity.
  private readonly suggestionStateKey = 'SUGGESTION_STATE';

  // Stores local storage terms key for integrity.
  private readonly termsStateKey = 'TERMS_STATE';

  /**
   * The subject used to controls the suggestion state communication.
   */
  private suggestionStateSource = new ReplaySubject<boolean>(1);
  /**
   * Observable instance of the source object.
   */
  private suggestionStateObservable = this.suggestionStateSource.asObservable();

  /**
   * The subject used to control the terms state communication.
   */
  private termsStateSource = new ReplaySubject<boolean>(1);
  /**
   * Observable instance of the source object.
   */
  private termsStateObservable = this.termsStateSource.asObservable();

  /**
   * Getter function for private suggestionState Observable.
   *
   * @return Observable<boolean> that listens suggestion state.
   */
  public get loadSuggestionState(): Observable<boolean> {
    return this.suggestionStateObservable;
  }

  /**
   * Getter function for private termsState Observable.
   *
   * @return Observable<boolean> that listens terms state.
   */
  public get loadTermsState(): Observable<boolean> {
    return this.termsStateObservable;
  }

  /**
   * Constructor function responsible for injecting the needed services.
   */
  constructor() {
    // Handling suggestion and terms state using local storage.
    const suggestionState = JSON.parse(
      localStorage.getItem(this.suggestionStateKey)
    );
    const termsState = JSON.parse(localStorage.getItem(this.termsStateKey));
    if (suggestionState === null || typeof suggestionState !== 'boolean') {
      this.setSuggestionState(true);
    } else {
      this.setSuggestionState(suggestionState);
    }
    if (termsState === null || typeof termsState !== 'boolean') {
      this.setTermsState(true);
    } else {
      this.setTermsState(termsState);
    }
  }

  /**
   * Calls the source of the observable, cascades the action and updates local storage state.
   *
   * @param state Is the action object you want to cascade.
   */
  setSuggestionState(state: boolean): void {
    localStorage.setItem(this.suggestionStateKey, JSON.stringify(state));
    this.suggestionStateSource.next(state);
  }

  /**
   * Calls the source of the observable, cascades the action and updates local storage state.
   *
   * @param state Is the action object you want to cascade.
   */
  setTermsState(state: boolean): void {
    localStorage.setItem(this.termsStateKey, JSON.stringify(state));
    this.termsStateSource.next(state);
  }

  /**
   * Returns true if the language supports suggestions and false otherwise.
   *
   * @param languageCode Represents the language code string.
   *
   * @returns True if the language supports suggestions and false otherwise.
   */
  getSuggestionSettingsState(languageCode: string): boolean {
    return SUGGESTION_LANGUAGE_CODES.includes(languageCode);
  }
}
