import { Observable } from 'rxjs';

/**
 * Enum that holds the tool names. Used for marking the tool type in code.
 */
export enum TOOL_TYPE {
  NONE = 'NONE',
  PREDICTION = 'PREDICTION',
  IMAGE = 'IMAGE',
  SHOW_EDIT = 'SHOW_EDIT',
  BOLD = 'BOLD',
  ITALIC = 'ITALIC',
  UNDERLINE = 'UNDERLINE',
  UNDO = 'UNDO',
  REDO = 'REDO',
  MAXIMIZE = 'MAXIMIZE',
  PRINT = 'PRINT',
  FILE_NEW = 'FILE_NEW',
  FILE_OPEN = 'FILE_OPEN',
  FILE_SAVE = 'FILE_SAVE',
  FILE_SAVE_AS = 'FILE_SAVE_AS',
  PLAY = 'PLAY',
  READING_SPEED = 'READING_SPEED',
  READ = 'READ',
  OCR = 'OCR',
  EDIT_PDF = 'EDIT_PDF',
  DIVIDER = 'DIVIDER',
  EXPORT_OCR = 'EXPORT_OCR',
  PROFILE_SETTINGS = 'PROFILE_SETTINGS',
  TERMS = 'TERMS',
  PROFILE = 'PROFILE',
  RESET = 'RESET',
  CONTENT = 'CONTENT',
}

/**
 * Enum that holds the OCR options.
 */
export enum OCR_OPTION {
  CURRENT = 'CURRENT',
  ALL_PAGES = 'ALL_PAGES',
  SPECIFIC_PAGES = 'SPECIFIC_PAGES',
}

/**
 * Enum that holds the PLAY action.
 */
export enum PLAY_ACTION {
  REQUEST = 'REQUEST',
  RESPONSE = 'RESPONSE',
}

/**
 * Enum that holds task action types.
 */
export enum TASK_ACTION_TYPE {
  INPUT = 'inputField',
}

/**
 * Enum that holds Letter case types.
 */
export enum LETTER_CASE_TYPE {
  NORMAL = 'Normal',
  DICTATION = 'Dictation',
  ALL_CAPITAL = 'All capital',
  FIRST_CAPITAL = 'First capital',
}

/**
 * Generic tool types that don't have a specific definition.
 */
export type TOOL_TYPE_GENERIC =
  | TOOL_TYPE.NONE
  | TOOL_TYPE.BOLD
  | TOOL_TYPE.ITALIC
  | TOOL_TYPE.UNDERLINE
  | TOOL_TYPE.UNDO
  | TOOL_TYPE.REDO
  | TOOL_TYPE.MAXIMIZE
  | TOOL_TYPE.IMAGE
  | TOOL_TYPE.PRINT;

/**
 * Tool type actions (play, prediction or content).
 */
export type TOOL_TYPE_ACTIONS =
  | TOOL_TYPE.PLAY
  | TOOL_TYPE.PREDICTION
  | TOOL_TYPE.CONTENT;
/**
 * ToolType collection including specific types combined with the generic type.
 */
export type ToolType =
  | ToolTypeGenericBoolean
  | ToolTypeGenericString
  | ToolTypeGenericNumber
  | ToolTypeExportOCR;

/**
 * Generic tool type definition with value as string.
 */
export interface ToolTypeGenericString {
  type: TOOL_TYPE_GENERIC;
  action: string;
  value: string;
}

/**
 * Generic tool type definition with value as boolean.
 */
export interface ToolTypeGenericBoolean {
  type: TOOL_TYPE_ACTIONS;
  action: string;
  value: boolean;
}

/**
 * Generic tool type definition with value as number.
 */
export interface ToolTypeGenericNumber {
  type: TOOL_TYPE.SHOW_EDIT;
  action: string;
  value: number;
}


/**
 * Tool type definition for OCR export options.
 */
export interface ToolTypeExportOCR {
  type: TOOL_TYPE.EXPORT_OCR;
  action: '';
  value: number[];
}

/**
 * Event types for each view (pdf, text, epub).
 */
export type EventStoreTypes =
  | 'pdf-viewer.loaded'
  | 'text-viewer.loaded'
  | 'epub-viewer.loaded'
  | 'profile.loaded';

/**
 * Event store definition.
 */
export interface EventStoreInterface {
  type: EventStoreTypes;
  observable: Observable<boolean>;
}

/**
 * Enum that holds the navigation options.
 */
export enum NAVIGATION_CONTROL {
  PREV = 'PREV',
  NEXT = 'NEXT',
}

/**
 * Enum that holds the reading options.
 */
export enum READING_IDENTIFIER {
  READ_WORD = 'READ_WORD',
  READ_SENTENCE = 'READ_SENTENCE',
  READ_CHARACTER = 'READ_CHARACTER',
}


/**
 * Enum that holds Letter case types.
 */
export enum VOCABULARY_OPTION {
  SMALL = 'Small',
  BASIS = 'Basis',
  EXTENDED = 'Extended',
  ADVANCED = 'Advanced',
}
