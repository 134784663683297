import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EncodingService } from './encoding.service';
import { IntowordsBaseService } from './intowords-base.service';
import { CookieService } from 'ngx-cookie-service';
import { LanguageHelperService } from '../language.service';
import {
  ProfileInfoExtendedDTOI,
  ProfileSettingsI,
} from 'src/app/dto/profile/profile-dto';
import { SubstitutionGroupsResponseDTO } from 'src/app/dto/profile/suggestion-rules/suggestion-rules-dto';

/**
 * Expected response format from the suggestion service.
 */
export interface WordPredictionResult {
  word: string;
  origin: string;
  spelling: string;
}

/**
 * Service responsible of working with intowords suggestions api.
 */
@Injectable()
export class IntowordsSuggestionsService extends IntowordsBaseService<
WordPredictionResult[]
> {
  private baseUrl: string;

  /**
   *
   * @param httpService Performs HTTP requests.
   * @param encoding Service responsible for encoding hmac data for intowords requests.
   * @param cookieService Service responsible for managing cookies.
   * @param languageService Service responsible for managing languages.
   */
  constructor(
    protected readonly httpService: HttpClient,
    protected readonly encoding: EncodingService,
    protected readonly cookieService: CookieService,
    protected readonly languageService: LanguageHelperService
  ) {
    super(httpService, encoding, cookieService);
    this.baseUrl = this.UrlEndpoints.wordprediction;
  }

  /**
   * Makes a call to Suggestions and returns an array of suggestions based on the context.
   *
   * @param textBeforeCursor The actual string that we get a prediction for.
   * @param profile Represents current profile.
   */
  suggest(
    textBeforeCursor: string,
    profile: ProfileInfoExtendedDTOI
  ): Observable<WordPredictionResult[]> {
    // TODO: add as param.
    const returnCase = 'Normal';

    return this.post<ProfileSettingsI, WordPredictionResult[]>(
      `${this.baseUrl}/v${this.API_VERSION}/Suggestions?context=${textBeforeCursor}&returnCase=${returnCase}`,
      profile.profile.settings
    );
  }


  /**
   * Get substitution groups.
   */
  getSubstitutionGroups(
    lang: string
  ): Observable<SubstitutionGroupsResponseDTO[]> {
    return this.get<any>(
      `${this.baseUrl}/v${this.API_VERSION}/SubstitutionGroups?userLanguage=${lang}&dictionaryLanguage=${lang}`,
      false
    );
  }
}
