import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ReplaySubject, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { ProfileInfoExtendedDTOI, SuggestionPartialSettingsI } from 'src/app/dto/profile/profile-dto';
import { CustomIconService } from 'src/app/services/custom-icon.service';
import { EventStoreService } from 'src/app/services/event-store.service';
import { ProfileHelperService } from 'src/app/services/profile-helper.service';
import { VOCABULARY_OPTION } from 'src/app/models/common/types';
import { DEFAULT_DEBOUNCE_MIN_TIME } from 'src/app/common/constants';

/**
 * This component is used as a placeholder for the app.
 */
@Component({
  selector: 'app-suggestions',
  templateUrl: './suggestions.component.html',
  styleUrls: ['./suggestions.component.scss'],
})
export class SuggestionsComponent implements OnInit, OnDestroy {
  profiles: ProfileInfoExtendedDTOI[] = [];
  // Current profile holder.
  currentProfile: ProfileInfoExtendedDTOI;
  // Stores the suggestion settings.
  suggestionSettings: SuggestionPartialSettingsI;
  // Options for shortest word and number of characters before alternative suggestions.
  defaultWordArray = [1, 2, 3, 4, 5];
  // Stores the shortest word option.
  selectedShortestWord: number;
  // Options for longest word.
  longestWordArray = [10, 20, 30, 40];
  // Stores the longest word option.
  selectedLongestWord: number;
  // Stores the selected character option.
  selectedCharacter: number;
  // Stores the selected vocabulary option.
  selectedVocabulary = '';
  // Vocabulary options (convert from enum to array).
  vocabularyOptions = Object.values(VOCABULARY_OPTION);
  // Determines whether the terms option should be displayed or not.
  shouldDisplayTerms: boolean;

  // Stores the subscribers until they're destroyed.
  private readonly destroyed = new ReplaySubject<never>();

  /**
   * Constructor function responsible for injecting the needed services.
   *
   * @param customIconService Reference to CustomIconService.
   */
  constructor(
    private readonly customIconService: CustomIconService,
    private readonly router: Router,
    private readonly eventStoreService: EventStoreService,
    private readonly profileHelperService: ProfileHelperService
  ) {
    // Returns the icons used in UI.
    this.customIconService.getCustomIcons([
      'Favicon-svg',
      'icon-Profile',
      'profile_settings',
      'settings_suggestions',
      'short_word',
      'longest_word',
      'letter_case_rules',
      'radio_checked',
      'radio_unchecked',
      'vocabulary',
      'terms'
    ]);
  }

  /**
   * A lifecycle hook that is called after Angular has initialized
   * all data-bound properties of a directive.
   */
  ngOnInit(): void {
    this.profileHelperService.currentProfileAction
      .pipe(takeUntil(this.destroyed)).subscribe((data) => {
        if (data) {
          this.profiles = data.profiles;
          this.currentProfile = data.currentProfile;
          this.suggestionSettings = {
            predictNextWord: data.currentProfile.profile.settings.predictNextWord,
            showSpelling: data.currentProfile.profile.settings.showSpelling,
            minWordLength: data.currentProfile.profile.settings.minWordLength,
            maxWordLength: data.currentProfile.profile.settings.maxWordLength,
            minPrefixLength: data.currentProfile.profile.settings.minPrefixLength,
            dictionaryType: data.currentProfile.profile.settings.dictionaryType,
            dictation: data.currentProfile.profile.settings.dictation,
          };
          this.selectedShortestWord = this.suggestionSettings.minWordLength;
          this.selectedLongestWord = this.suggestionSettings.maxWordLength;
          this.selectedCharacter = this.suggestionSettings.minPrefixLength;
          this.selectedVocabulary = this.suggestionSettings.dictionaryType;
        }
      });

    const events = this.eventStoreService.requestActions('profile.loaded');
    if (events.length) {
      // We only load the last stored course.
      events.map((event) => {
        event.observable.subscribe();
      });
    }
  }


  /**
   * Unsubscribe Observables and detach event handlers to avoid memory leaks.
   */
  ngOnDestroy(): void {
    this.destroyed.next();
  }

  /**
   * Method to toggle voice options.
   *
   * @param option Name of voice option.
   */
  toggleSettings(option: 'predictNextWord' | 'showSpelling'): void {
    // We need a debounceTime in order to allow change to trigger first but not interfere.
    const changeSubject = new Subject();
    changeSubject
      .pipe(debounceTime(DEFAULT_DEBOUNCE_MIN_TIME))
      .subscribe(() => {
        this.suggestionSettings[option] = !this.suggestionSettings[option];
        this.saveSettingsOptions();
      });
    changeSubject.next();
  }

  /**
   * Save voice's options.
   */
  saveSettingsOptions(): void {
    for (const option in this.suggestionSettings) {
      if (option) {
        this.currentProfile.profile.settings[option] = this.suggestionSettings[
          option
        ];
      }
    }
  }

  /**
   * Select the limit of the shortest word.
   *
   * @param option Represents the selected option.
   */
  selectShortest(option: number): void {
    this.selectedShortestWord = option;
    this.suggestionSettings.minWordLength = option;
    this.saveSettingsOptions();
  }

  /**
   * Select the limit of the longest word.
   *
   * @param option Represents the selected option.
   */
  selectLongest(option: number): void {
    this.selectedLongestWord = option;
    this.suggestionSettings.maxWordLength = option;
    this.saveSettingsOptions();
  }

  /**
   * Select the characters before alternative suggestion.
   *
   * @param option Represents the selected option.
   */
  selectNbrCharactersBeforeSuggestion(option: number): void {
    this.selectedCharacter = option;
    this.suggestionSettings.minPrefixLength = option;
    this.saveSettingsOptions();
  }

  /**
   * Select letter case rule.
   */
  selectCaseRule(): void {
    this.suggestionSettings.dictation = !this.suggestionSettings.dictation;
    this.saveSettingsOptions();
  }

  /**
   * Select vocabulary option.
   *
   * @param option Represents the selected option.
   */
  selectVocabulary(option: string): void {
    this.selectedVocabulary = option;
    this.suggestionSettings.dictionaryType = option;
    this.saveSettingsOptions();
  }

  /**
   * Open terms modal.
   */
  openTermsModal(): void { }

  /**
   * Navigatess to settings view.
   */
  goBack(): void {
    this.router.navigate(['/settings']);
  }
}
