import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { CreateProfileInputDataI } from '../dto/profile/profile-dto';

/**
 * LoaderService is used to handle the loader(spinner) value.
 */
@Injectable()
export class ProfileHelperService {
  /**
   * The subject used to controls the service communication.
   */
  private currentProfileSource = new Subject<CreateProfileInputDataI>();
  /**
   * Observable instance of the source object.
   */
  private currentProfileActionObservable = this.currentProfileSource.asObservable();

  /**
   * Getter function for private currentProfileAction Observable.
   *
   * @return Observable<CreateProfileInputDataI> That listens for any tool actions.
   */
  public get currentProfileAction(): Observable<CreateProfileInputDataI> {
    return this.currentProfileActionObservable;
  }

  /**
   * Calls the source of the observable and cascades the action.
   *
   * @param isLoaded Is the action object you want to cascade.
   */
  sendCurrentProfile(isLoaded: CreateProfileInputDataI): void {
    this.currentProfileSource.next(isLoaded);
  }

}
