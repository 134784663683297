import { Component, OnDestroy, OnInit } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { profiles } from 'src/app/common/profiles';
import { ProfileInfoExtendedDTOI } from 'src/app/dto/profile/profile-dto';
import { CustomIconService } from 'src/app/services/custom-icon.service';
import { EventStoreService } from 'src/app/services/event-store.service';
import { ProfileHelperService } from 'src/app/services/profile-helper.service';

/**
 * This component is used as a placeholder for the app.
 */
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, OnDestroy {
  profiles: ProfileInfoExtendedDTOI[] = profiles;
  // Current profile holder.
  currentProfile: ProfileInfoExtendedDTOI;

  // Stores the subscribers until they're destroyed.
  private readonly destroyed = new ReplaySubject<never>();

  /**
   * Constructor function responsible for injecting the needed services.
   *
   * @param customIconService Reference to CustomIconService.
   * @param eventStoreService Reference to EventStoreService.
   * @param profileHelperService Reference to ProfileHelperService.
   */
  constructor(
    private readonly customIconService: CustomIconService,
    private readonly eventStoreService: EventStoreService,
    private readonly profileHelperService: ProfileHelperService,
  ) {
    // Returns the icons used in UI.
    this.customIconService.getCustomIcons([
      'Favicon-svg',
      'icon-Profile',
      'profile_settings',
      'play'
    ]);
    this.currentProfile = this.profiles.find(el => el.isCurrent);
  }

  /**
   * A lifecycle hook that is called after Angular has initialized
   * all data-bound properties of a directive.
   */
  ngOnInit(): void {
    this.profileHelperService.currentProfileAction
      .pipe(takeUntil(this.destroyed)).subscribe((data) => {
        if (data) {
          this.profiles = data.profiles;
          this.currentProfile = data.currentProfile;
        }
      });

    const events = this.eventStoreService.requestActions('profile.loaded');
    if (events.length) {
      // We only load the last stored course.
      events.map((event) => {
        event.observable.subscribe();
      });
    }
  }

  /**
   * Unsubscribe Observables and detach event handlers to avoid memory leaks.
   */
  ngOnDestroy(): void {
    this.destroyed.next();
  }
}
