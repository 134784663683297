import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { InterceptorLoaderService } from '../services/interceptor-loader.service';
import { HttpRequestI } from '../services/auth-interceptor.service';

/**
 * This interceptor is used to change the value of the Subject.
 */
@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  /**
   * Constructor function responsible for injecting the needed services.
   *
   * @param interceptorLoaderService Is an instance of InterceptorLoaderService.
   */
  constructor(public interceptorLoaderService: InterceptorLoaderService) {}

  /**
   * Changes the subject value to true, when a request starts, and false when the request is 'finalized'.
   *
   * @param req Represents the http request.
   * @param next Represents the http handler.
   *
   * @return False after the request was finalized.
   */
  intercept(
    req: HttpRequest<HttpRequestI>,
    next: HttpHandler
  ): Observable<HttpEvent<HttpRequestI>> {
    this.interceptorLoaderService.show();
    return next
      .handle(req)
      .pipe(finalize(() => this.interceptorLoaderService.hide()));
  }
}
