import { TopicDictionariesResult } from 'src/app/services/intowords/intowords-topic-dictionaries.service';
import { VoiceResult } from 'src/app/services/intowords/intowords-voice.service';
import { ProfileEntityI, ProfileInfoEntityI } from './profile-entity';
import { SubstitutionGroupsResponseDTO } from './suggestion-rules/suggestion-rules-dto';

/**
 * Profile Info.
 */
export interface ProfileInfoDTOI {
  isCurrent: boolean;
  profile: ProfileDTOI;
}

/**
 * Terms modal data/
 */
export interface TermsModalDataDTOI {
  profile: ProfileInfoExtendedDTOI;
  terms: TopicDictionariesResult[];
}

/**
 * Extended profile that contains the voices array.
 */
export interface ProfileInfoExtendedDTOI extends ProfileInfoDTOI {
  voices?: VoiceResult[];
}

/**
 * User-Defined Type Guard performs a runtime check that guarantees the type of the object is ProfileInfoExtendedDTOI.
 *
 * @param object Represents the object that is checked.
 *
 * @return Required fields of the object.
 */
export function isProfileInfoExtendedDTOI(object: {}): object is ProfileInfoExtendedDTOI {
  return ('isCurrent' && 'profile') in object;
}

/**
 * Profile.
 */
export interface ProfileDTOI {
  id: string;
  name: string;
  language: string;
  application?: string;
  settings?: ProfileSettingsI;
}

/**
 * Suggestions general settings.
 */
export interface SuggestionGeneralSettingsI {
  userLanguage?: string;
  numberOfWords?: number;
  numberOfTopicWords?: number;
  voiceId?: string;
  wildCards?: boolean;
  useContext?: boolean;
  showHomophones?: boolean;
  minSpellingLength?: number;
  voiceSpeed?: number;
}

/**
 * Suggestions extra fields settings.
 */
export interface SuggestionExtraSettingsI {
  dictionaryLanguage?: string;
  topicDictionaryIds?: Array<number>;
  substitutionRules?: SubstitutionGroupsResponseDTO[];
  defaultSubstitutionRules?: SubstitutionGroupsResponseDTO[];
  myTerms?: [];
}

/**
 * Suggestions partial settings.
 */
export interface SuggestionPartialSettingsI {
  predictNextWord?: boolean;
  showSpelling?: boolean;
  minWordLength?: number;
  maxWordLength?: number;
  minPrefixLength?: number;
  dictionaryType?: string;
  dictation?: boolean;
}

/**
 * Suggestions settings.
 */
export interface SuggestionSettingsI
  extends SuggestionGeneralSettingsI,
  SuggestionPartialSettingsI,
  SuggestionExtraSettingsI { }

/**
 * Voices settings.
 */
export interface VoiceSettingsI {
  writeLetterName?: boolean;
  writeLetterSound?: boolean;
  writeWord?: boolean;
  writeSentence?: boolean;
  readForward?: boolean;
  highlightWord?: boolean;
}

/**
 * Profile settings.
 */
export interface ProfileSettingsI extends SuggestionSettingsI, VoiceSettingsI { }

/**
 * Create profile body interface.
 */
export interface CreateProfileBodyI {
  voiceId: string;
  language: string;
  name: string;
}

/**
 * Create profile input data interface.
 */
export interface CreateProfileInputDataI {
  profiles: ProfileInfoExtendedDTOI[];
  currentProfile: ProfileInfoExtendedDTOI;
}

/**
 * Implementation of ProfileInfoDTOI (that will be used in the app).
 */
export class ProfileInfoDTO implements ProfileInfoDTOI {
  isCurrent: boolean;
  profile: ProfileDTOI;

  static fromEntity(entity: ProfileInfoEntityI): ProfileInfoDTO {
    const instance = new ProfileInfoDTO();
    instance.isCurrent = entity.isCurrent;
    instance.profile = ProfileDTO.fromEntity(entity.profile);
    return instance;
  }
}

/**
 * Implementation of ProfileDTOI (that will be used in the app).
 */
export class ProfileDTO implements ProfileDTOI {
  id: string;
  name: string;
  language: string;
  application?: string;
  settings?: ProfileSettingsI;

  static fromEntity(entity: ProfileEntityI): ProfileDTO {
    const instance = new ProfileDTO();
    instance.id = entity.id;
    instance.language = entity.language;
    instance.name = entity.name;
    instance.application = entity.application;
    instance.settings = {
      dictionaryType: entity.settings.values.dictionaryType,
      userLanguage: entity.settings.values.userLanguage,
      numberOfWords: entity.settings.values.numberOfWords,
      numberOfTopicWords: entity.settings.values.numberOfTopicWords,
      voiceId: entity.settings.values.voiceId,
      wildCards: entity.settings.values.wildCards,
      useContext: entity.settings.values.useContext,
      predictNextWord: entity.settings.values.predictNextWord,
      showHomophones: entity.settings.values.showHomophones,
      showSpelling: entity.settings.values.showSpelling,
      minWordLength: entity.settings.values.minWordLength,
      maxWordLength: entity.settings.values.maxWordLength,
      minPrefixLength: entity.settings.values.minPrefixLength,
      minSpellingLength: entity.settings.values.minSpellingLength,
      voiceSpeed: entity.settings.values.voiceSpeed,
      substitutionRules: entity.settings.substitutionRules,
      myTerms: entity.settings.myTerms,
      writeLetterName: entity.settings.values.writeLetterName,
      writeLetterSound: entity.settings.values.writeLetterSound,
      writeWord: entity.settings.values.writeWord,
      writeSentence: entity.settings.values.writeSentence,
      readForward: entity.settings.values.readForward,
      highlightWord: entity.settings.values.highlightWord,
      dictionaryLanguage: entity.settings.values.dictLanguage,
      topicDictionaryIds: entity.settings.topicsId,
      dictation: entity.settings.values.dictation,
    };
    return instance;
  }
}
