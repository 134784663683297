import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DEFAULT_DEBOUNCE_MIN_TIME } from '../common/constants';

/**
 * This service is used to handle login and logout logic.
 */
@Injectable()
export class AuthService {
  /**
   * The subject used to controls the service communication.
   */
  private loggedInSource = new Subject<boolean>();
  /**
   * Observable instance of the source object.
   */
  private loggedInObservable = this.loggedInSource.asObservable();

  /**
   * Getter function for private loggedIn Observable.
   *
   * @return Observable<boolean> That listens for any actions.
   */
  public get loggedInAction(): Observable<boolean> {
    return this.loggedInObservable;
  }

  /**
   * Constructor function responsible for injecting the needed services.
   *
   * @param cookieService Reference to CookieService.
   * @param router Reference to Router.
   */
  constructor(
    private readonly cookieService: CookieService,
    private readonly router: Router
  ) {}

  /**
   * Calls the source of the observable and cascades the action.
   *
   * @param isLoggedIn Is the action object you want to cascade.
   */
  loggedIn(isLoggedIn: boolean): void {
    this.loggedInSource.next(isLoggedIn);
  }

  /**
   * Redirects to signon login flow.
   */
  login(): void {
    window.location.href =
      environment.UrlEndpoints.login + environment.location;
  }

  /**
   * User logout.
   */
  logout(): void {
    // TODO: Clean up text cookies.
    this.cookieService.delete('mv_session_id');
    setTimeout(() => {
      this.router.navigate(['/login']);
    }, DEFAULT_DEBOUNCE_MIN_TIME);
  }
}
