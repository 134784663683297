import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { LETTER_NAME, LETTER_SOUND, TTS } from '../common/constants';
import { ProfileInfoExtendedDTOI } from '../dto/profile/profile-dto';
import {
  PLAY_ACTION,
  READING_IDENTIFIER,
  TOOL_TYPE,
} from '../models/common/types';
import {
  HTMLWithElementCaretHelper,
  InputCaretHelper,
} from '../modules/shared/common/caret-helper';
import {
  HighlightElement,
  SelectedWord,
} from '../modules/shared/common/selection-helper';
import { Pattern } from '../modules/shared/regex.pattern';
import {
  IntowordsVoiceService,
  SpeakResult,
  SpeakTimestamp,
} from './intowords/intowords-voice.service';
import { ProfileService } from './profile.service';
import { TextEventsService } from './text-events.service';
import { ToolsService } from './tools.service';

/**
 * Extension of the audio html element to hold highlight information.
 */
class HTMLAudioElementWithTextHighlight extends Audio {
  // Stores the highlighted elements.
  highlightElements: HighlightElement[] = [];

  constructor() {
    super();

    this.addEventListener('playing', () => this.updateHighlight());
  }

  updateHighlight(): void {
    const curentValidHighlight = this.highlightElements.find(
      (el) => el.time >= this.currentTime
    );
    if (curentValidHighlight) {
      const curentHighlight = curentValidHighlight;
      if (curentHighlight && !curentHighlight.highlighted) {
        curentHighlight.highlight();
        this.highlightElements.splice(
          0,
          this.highlightElements.indexOf(curentHighlight)
        );
      }
    }

    if (!this.paused) {
      requestAnimationFrame(() => {
        this.updateHighlight();
      });
    }
  }

  /**
   * Init data.
   *
   * @param highlightElements Represents the elements that will be highlighted.
   */
  setHighlightData(highlightElements: HighlightElement[]): void {
    this.highlightElements = highlightElements;
  }

  /**
   * Play method.
   */
  play(): Promise<void> {
    return super.play();
  }
}

/**
 * SpeechService handles all speech events triggered in the application.
 */
@Injectable()
export class SpeechService {
  // Represents the current profile.
  currentProfile: ProfileInfoExtendedDTOI;

  // Responsible for handling audio events.
  private speechAudioElement: HTMLAudioElementWithTextHighlight = new HTMLAudioElementWithTextHighlight();

  // Hold speak result of selected text.
  private speakResult: SpeakResult;

  /**
   * The subject used to communicate speaktimestamp for syncing.
   */
  private getTimestamps = new Subject<SpeakTimestamp>();
  /**
   * Observable instance of the source object.
   */
  public OnTimestampChanged = this.getTimestamps.asObservable();
  /**
   * The subject used to send signal on speech termination.
   */
  private speechEnded = new Subject<boolean>();
  /**
   * Observable instance of the source object.
   */
  public OnSpeechEnd = this.speechEnded.asObservable();
  /**
   * The subject used to send signal when speech is about to play.
   */
  private speechPlay = new Subject<boolean>();
  /**
   * Observable instance of the source object.
   */
  public OnSpeechPlay = this.speechPlay.asObservable();

  // Holds element on which operations should perform.
  editorElementHelper: HTMLWithElementCaretHelper;

  // Holds input element on which operations should perform.
  editorInputHelper: InputCaretHelper;

  // Determines if sentence reading should start at speech termination or not.
  private shouldReadSentence = false;

  // Determines if it should send timestamps information while playing speech or not.
  private shouldSendTimestamps = true;

  // Sets flag that determines whether it should send timestamps or not.
  set updateTimestamp(flag: boolean) {
    this.shouldSendTimestamps = flag;
  }

  /**
   * Constructor function responsible for injecting the needed services.
   *
   * @param toolsService Service used to listen for an open file event.
   * @param intowordsVoiceService Is an instance of IntowordsVoiceService.
   * @param profileService Reference to ProfileService.
   * @param textEventService Reference to TextEventsService.
   */
  constructor(
    private toolsService: ToolsService,
    private intowordsVoiceService: IntowordsVoiceService,
    private readonly profileService: ProfileService,
    private textEventService: TextEventsService
  ) {
    // Unload speech when completed.
    this.speechAudioElement.addEventListener('ended', (ended) => {
      this.unload();
      this.speechEnded.next(true);

      // Lookup for read sentence after reading word. It should trigger when both read word and sentence are enabled.
      if (this.shouldReadSentence) {
        this.readSentenceAtCursor();
      }
    });
    this.speechAudioElement.addEventListener('playing', () =>
      this.shouldSendTimestamps ? this.syncCurrentTime() : null
    );
    this.speechAudioElement.addEventListener('play', (play) => {
      this.speechPlay.next(true);
    });

    // Listens for any changes regarding the current profile.
    this.profileService.requestCurrentProfileAction.subscribe(
      (currentProfile: ProfileInfoExtendedDTOI) => {
        this.currentProfile = currentProfile;
      }
    );
  }

  /**
   * Check if current selection is playing.
   *
   * @return True if the current selection is playing and false otherwise.
   */
  isPlaying(): boolean {
    return this.speechAudioElement && !this.speechAudioElement.paused;
  }

  /**
   * Sync speaktimestamp with speech current time.
   */
  syncCurrentTime(): void {
    for (let i = 0; i < this.speakResult.speakTimestamps.length; i++) {
      const nextSpeakTimeStamp =
        i < this.speakResult.speakTimestamps.length - 1
          ? Number(this.speakResult.speakTimestamps[i + 1].time)
          : Number(this.speakResult.speakTimestamps[i].time) + 1;
      if (
        Number(this.speakResult.speakTimestamps[i].time) <=
          Number(this.speechAudioElement.currentTime.toFixed(3)) &&
        Number(this.speechAudioElement.currentTime.toFixed(3)) <
          Number(nextSpeakTimeStamp) &&
        !this.speechAudioElement.ended
      ) {
        this.getTimestamps.next(this.speakResult.speakTimestamps[i]);
      }
    }
    if (
      Number(this.speechAudioElement.currentTime.toFixed(3)) <=
        this.speechAudioElement.duration &&
      !this.speechAudioElement.paused
    ) {
      requestAnimationFrame(this.syncCurrentTime.bind(this));
    }
  }

  /**
   * Start or resume speech for the current selected text.
   *
   * @param speechText Text to play in speech.
   * @param currentProfile Represents the current profile.
   * @param speechType Represents the type of speech. It is set to 'TTS' by default.
   */
  play(speechText: string = null, speechType: string = TTS): void {
    // Resume the paused speech if exist.
    if (this.speechAudioElement.src.length) {
      this.updatePlayIcon(true);
      this.speechAudioElement.play();
      return;
    }

    // If speech text does not exist.
    if (!speechText) {
      return;
    }
    this.updatePlayIcon(true);
    // Call api to get speak result of selected text.
    this.intowordsVoiceService
      .speak(speechText, this.currentProfile, speechType)
      .subscribe(
        (speakResult) => {
          if (speakResult.soundLink) {
            // Enable timestamp syncing.
            this.shouldSendTimestamps = true;
            this.speakResult = speakResult;
            this.speechAudioElement.src = speakResult.soundLink;
            this.speechAudioElement.load();
            this.speechAudioElement.play();
          }
        },
        (err) => this.updatePlayIcon(false)
      );
  }

  /**
   * Play demo method.
   *
   * @param speechText Text to play in speech.
   * @param currentProfile Represents the current profile.
   * @param speechType Represents the type of speech. It is set to 'TTS' by default.
   */
  playDemo(
    speechText: string,
    currentProfile: ProfileInfoExtendedDTOI = this.currentProfile,
    speechType: string = TTS
  ): void {
    // If speech text does not exist.
    if (!speechText) {
      return;
    }
    this.intowordsVoiceService
      .speak(speechText, currentProfile, speechType)
      .subscribe((speakResult) => {
        if (speakResult.soundLink) {
          // Disable timestamp syncing.
          this.shouldSendTimestamps = false;
          this.speakResult = speakResult;
          this.speechAudioElement.src = speakResult.soundLink;
          this.speechAudioElement.load();
          this.speechAudioElement.play();
        }
      });
  }

  /**
   * Start or resume speech and highlight for the current selected text.
   *
   * @param speechTextArray An array of the words to play in speech.
   * @param currentProfile Represents the current profile.
   *
   * @return An Observable of SpeakResult.
   */
  playWithHighlight(
    speechTextArray: SelectedWord[],
    currentProfile: ProfileInfoExtendedDTOI
  ): Observable<SpeakResult> {
    const speechText = speechTextArray.map((el) => el.text).join(' ');
    // Resume the paused speech if exist.
    if (this.speechAudioElement.src.length) {
      this.updatePlayIcon(true);
      this.speechAudioElement.play();
      return;
    }

    // If speech text does not exist.
    if (!speechText) {
      return;
    }
    this.updatePlayIcon(true);
    // Call api to get speak result of selected text.
    return this.intowordsVoiceService
      .speak(speechText, currentProfile, 'TTS')
      .pipe(
        catchError((error) => {
          throw error;
        }),
        mergeMap((data: SpeakResult) => {
          if (data.soundLink) {
            const highlightElements: HighlightElement[] = speechTextArray.map(
              (el, index) => {
                const newElement = new HighlightElement();
                newElement.text = el.text;
                newElement.start = el.node.start;
                newElement.end = el.node.end;
                newElement.time = data.speakTimestamps[index].time * 1;
                return newElement;
              }
            );

            this.speakResult = data;
            this.speechAudioElement.src = data.soundLink;
            this.speechAudioElement.load();
            this.speechAudioElement.setHighlightData(highlightElements);
            this.speechAudioElement.play();
            return of(data);
          }
        })
      );
  }

  /**
   * Method Responsible for unloading the speech.
   */
  unload(): void {
    if (!this.speechAudioElement.src || !this.speakResult) {
      return;
    }
    this.pause();
    this.speechAudioElement.removeAttribute('src');
  }

  /**
   * Pause the current running speech audio speech.
   */
  pause(): void {
    this.speechAudioElement.pause();
    this.updatePlayIcon(false);
  }

  /**
   * Update the ui play/pause icon with respect to speech events.
   *
   * @param isPlaying Represent the state of ui play/pause icon.
   */
  updatePlayIcon(isPlaying: boolean): void {
    this.toolsService.selectedTool({
      type: TOOL_TYPE.PLAY,
      action: PLAY_ACTION.RESPONSE,
      value: isPlaying,
    });
  }

  /**
   * Returns true if speech is ended and false otherwise.
   *
   * @returns True if speech is ended and false otherwise.
   */
  isSpeechEnded(): boolean {
    return this.speechAudioElement.src ? false : true;
  }

  /**
   * Handles reading while writing.
   *
   * @param event Represents the keyboard event.
   * @param elementHelper Represents text holder of type HTMLWithElementCaretHelper.
   * @param inputHelper Represents text holder of type InputCaretHelper.
   */
  handleReading(
    character: string,
    elementHelper?: HTMLWithElementCaretHelper,
    inputHelper?: InputCaretHelper
  ): void {
    this.editorElementHelper = elementHelper;
    this.editorInputHelper = inputHelper;
    switch (this.textEventService.getReadingType(character)) {
      case READING_IDENTIFIER.READ_SENTENCE:
        this.readSentence();
        break;
      case READING_IDENTIFIER.READ_WORD:
        this.readWord();
        break;
      case READING_IDENTIFIER.READ_CHARACTER:
        this.readCharacterOrSound(character);
        break;
      default:
        break;
    }
  }

  /**
   * Reads character or character sound based on profile settings.
   *
   * @param character Represents the input character.
   */
  readCharacterOrSound(character: string): void {
    if (character && character.length === 1) {
      if (this.currentProfile.profile.settings.writeLetterName) {
        this.playDemo(character, this.currentProfile, LETTER_NAME);
      } else if (this.currentProfile.profile.settings.writeLetterSound) {
        this.playDemo(character, this.currentProfile, LETTER_SOUND);
      }
    }
  }

  /**
   * Reads the word at caret position if read word option in profile settings is enabled.
   *
   * @returns True if read word option in profile settings is enabled and false otherwise.
   */
  readWord(): boolean {
    if (this.currentProfile.profile.settings.writeWord) {
      const text = this.editorElementHelper
        ? this.editorElementHelper.getWordAtCursor().text
        : this.editorInputHelper.getSelectedWord().text;
      this.playDemo(text);
      return true;
    } else {
      return false;
    }
  }

  /**
   * Triggers reading the sentence at caret position if read sentence option in profile settings is enabled.
   * If read word is enabled then reads the word first and then sentence.
   */
  readSentence(): void {
    const readWord = this.readWord();
    if (readWord) {
      this.shouldReadSentence = true;
    } else if (this.currentProfile.profile.settings.writeSentence) {
      this.readSentenceAtCursor();
    }
  }

  /**
   * Reads the sentence at caret position.
   */
  readSentenceAtCursor(): void {
    const sentenceResponse = this.editorElementHelper
      ? this.editorElementHelper.getProgressiveSentenceAtCursor()
      : this.editorInputHelper.getSelectedWord();
    const isDot = (d) => Pattern.fullStopRegex.exec(d);
    const nodeText = sentenceResponse.node.nodeValue
      ? sentenceResponse.node.nodeValue
      : (sentenceResponse.node as HTMLInputElement).value;
    let start = sentenceResponse.start;
    while (start > 0 && !isDot(nodeText[start - 1])) {
      start -= 1;
    }
    const end = nodeText.indexOf('.', start + 1);
    sentenceResponse.end = end !== -1 ? end : sentenceResponse.end;
    const sentence = nodeText.slice(start, sentenceResponse.end);
    this.shouldReadSentence = false;
    this.playDemo(sentence);
  }
}
