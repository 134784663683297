/**
 * Holds various patterns used throughout the application.
 */
export class Pattern {
  // Pattern for white space.
  public static whiteSpaceRegex = '^\\s+$';

  // Pattern for full stop.
  public static fullStopRegex = /\./;

  // Pattern for alphabets (may or may not be capitalized).
  public static alphabetRegex = /^[a-zA-Z]+$/i;

  // Pattern for read word lookup.
  public static wordReadRegex = '^[,+<>]+$';

  // Pattern for read sentence lookup.
  public static sentenceReadRegex = '^[.?!]+$';

  // Pattern for HTML tag.
  public static HTMLRegex = /<\/?[a-z][\s\S]*>/i;

  // Pattern for letter lookup of all languages.
  public static letterRegex = '^\\pL+$';

  // Pattern for number lookup.
  public static numberRegex = '^[0-9]$';
}
