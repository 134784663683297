<div>
  <app-shared-components-top-menu></app-shared-components-top-menu>
  <div class="content-hld">
    <div>
      <div *ngFor="let profile of profiles" class="lang-hld mrg-6" (click)="selectProfile(profile)">
        <div class="flex-display">
          <mat-icon svgIcon="{{ profile.profile.language }}" class="app-icon-hover flag-icon"></mat-icon>
          <span class="profile-name" [ngClass]="{ 'active-profile': profile.isCurrent }">
            {{ profile.profile.name}}</span>
        </div>
        <mat-icon [svgIcon]="profile.isCurrent ? 'radio_checked' : 'radio_unchecked'" class="app-icon-hover check-icon">
        </mat-icon>
      </div>
    </div>
  </div>
</div>
