import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { voices } from 'src/app/common/voices';
import { ProfileInfoExtendedDTOI } from 'src/app/dto/profile/profile-dto';
import { CustomIconService } from 'src/app/services/custom-icon.service';
import { EventStoreService } from 'src/app/services/event-store.service';
import { VoiceResult } from 'src/app/services/intowords/intowords-voice.service';
import { ProfileHelperService } from 'src/app/services/profile-helper.service';
import { ProfileService, SortedVoicesInterface } from 'src/app/services/profile.service';

/**
 * This component is used as a placeholder for the app.
 */
@Component({
  selector: 'app-speech',
  templateUrl: './speech.component.html',
  styleUrls: ['./speech.component.scss'],
})
export class SpeechComponent implements OnInit, OnDestroy {
  profiles: ProfileInfoExtendedDTOI[] = [];
  // Current profile holder.
  currentProfile: ProfileInfoExtendedDTOI;
  // Stores the voices data.
  voices: VoiceResult[];
  // Selected voice.
  selectedVoice: VoiceResult;
  // Available languages for profile.
  availableProfileLanguages: SortedVoicesInterface[] = [];

  // Stores the subscribers until they're destroyed.
  private readonly destroyed = new ReplaySubject<never>();

  /**
   * Constructor function responsible for injecting the needed services.
   *
   * @param customIconService Reference to CustomIconService.
   * @param router Reference to Router.
   * @param eventStoreService Reference to EventStoreService.
   * @param profileHelperService Reference to ProfileHelperService.
   * @param profileService Reference to ProfileService.
   */
  constructor(
    private readonly customIconService: CustomIconService,
    private readonly router: Router,
    private readonly eventStoreService: EventStoreService,
    private readonly profileHelperService: ProfileHelperService,
    private readonly profileService: ProfileService
  ) {
    // Returns the icons used in UI.
    this.customIconService.getCustomIcons([
      'Favicon-svg',
      'icon-Profile',
      'profile_settings',
      'settings_speech',
      'speed',
      'voice_profile',
    ]);
  }

  /**
   * Get all available languages with voices data.
   */
  getLanguageVoices(): void {
    this.availableProfileLanguages = this.profileService.sortVoicesByLanguages(
      voices.voices
    );
    const currentLang = this.availableProfileLanguages.find(el => el.language === this.currentProfile.profile.language);
    this.voices = currentLang.voices;
    this.selectedVoice = currentLang.voices.find(el =>
      el.id === this.currentProfile.profile.settings.voiceId);
  }

  /**
   * A lifecycle hook that is called after Angular has initialized
   * all data-bound properties of a directive.
   */
  ngOnInit(): void {
    this.profileHelperService.currentProfileAction
      .pipe(takeUntil(this.destroyed)).subscribe((data) => {
        if (data) {
          this.profiles = data.profiles;
          this.currentProfile = data.currentProfile;
          this.getLanguageVoices();
        }
      });

    const events = this.eventStoreService.requestActions('profile.loaded');
    if (events.length) {
      // We only load the last stored course.
      events.map((event) => {
        event.observable.subscribe();
      });
    }
  }

  /**
   * Navigates to settings view.
   */
  goBack(): void {
    this.router.navigate(['/settings']);
  }

  /**
   * Select voice.
   *
   * @param voice Represents the selected voice.
   */
  selectVoice(voice: VoiceResult): void {
    this.selectedVoice = voice;
  }

  /**
   * Unsubscribe Observables and detach event handlers to avoid memory leaks.
   */
  ngOnDestroy(): void {
    this.destroyed.next();
  }

  /**
   * Listen demo.
   */
  listenDemo(): void {
    const text =
      this.demoText(this.selectedVoice.langCode) +
      ' ' +
      this.selectedVoice.name;
  }

  /**
   * Demo text based on the selected voice.
   *
   * @param languageCode Represents the selected language code.
   *
   * @return Demo text translated.
   */
  demoText(languageCode: string): string {
    let demoTxt = '';
    switch (languageCode) {
      case 'en-GB':
        demoTxt = 'Hello my name is';
        break;
      case 'da-DK':
        demoTxt = 'Hej mit navn er';
        break;
      case 'es-AR':
      case 'es-CO':
      case 'es-ES':
      case 'es-MX':
        demoTxt = 'Hola mi nombre es';
        break;
      case 'de-DE':
        demoTxt = 'Hallo Ich heisse';
        break;
      case 'sv-SE':
        demoTxt = 'Hej mitt namn är';
        break;
      case 'nb-NO':
      case 'nn-NO':
        demoTxt = 'Hei mitt navn er';
        break;
      case 'fr-CA':
      case 'fr-FR':
        demoTxt = 'Bonjour mon nom est';
        break;
      case 'it-IT':
        demoTxt = 'Ciao il mio nome è';
        break;
      case 'pl-PL':
        demoTxt = 'Cześć mam na imię';
        break;
      case 'nl-BE':
      case 'nl-NL':
        demoTxt = 'hallo mijn naam is';
        break;
      case 'hu-HU':
        demoTxt = 'Helló az én nevem';
        break;
      case 'fi-FI':
        demoTxt = 'Hei minun nimeni on';
        break;
      case 'ru-RU':
        demoTxt = 'Привет меня зовут';
        break;
      case 'ro-RO':
        demoTxt = 'Buna numele meu este';
        break;
      default:
        demoTxt = 'Hello my name is';
        break;
    }
    return demoTxt;
  }
}
