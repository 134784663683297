import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EncodingService } from './encoding.service';
import { IntowordsBaseService } from './intowords-base.service';

/**
 * Dictionary service information interface.
 */
export interface DictionaryServiceInfo {
  methodname: string;
  version: number;
  servicenumber: number;
  servicename: string;
}

/**
 * Dictionary response status interface.
 */
export interface DictionaryResponseStatusInterface {
  res_msg: string;
  res_code: number;
}

/**
 * Dictionary interface.
 */
export interface DictionaryInterface {
  Lang1: string;
  dictID: number;
  Lang2: string;
  Name: string;
}

/**
 * Dictionary search string interface.
 */
export interface DictionarySearchInterface {
  Word: string;
  Homograph: number;
  Key: string;
}

/**
 * Dictionary response result interface.
 */
export interface DictionaryResultInterface extends DictionaryServiceInfo {
  result: {
    method_result: DictionaryResponseStatusInterface;
    value: [DictionaryInterface];
  };
}

/**
 * Dictionary search string response result interface.
 */
export interface DictionarySearchResultInterface extends DictionaryServiceInfo {
  result: {
    method_result: DictionaryResponseStatusInterface;
    value: [DictionarySearchInterface];
  };
}

/**
 * Dictionary article response result interface.
 */
export interface DictionaryArticleResultInterface
  extends DictionaryServiceInfo {
  result: {
    method_result: DictionaryResponseStatusInterface;
    value: string;
  };
}

/**
 * Dictionary settings interface.
 */
export interface DictionarySettingsInterface {
  readAloud: boolean;
  showWordFormations: boolean;
  showInflections: boolean;
  showMeaning: boolean;
  showIdioms: boolean;
}

/**
 * Service responsible of working with dictionary.
 */
@Injectable()
export class IntowordsDictionaryService extends IntowordsBaseService<
  DictionaryInterface[]
> {
  // Represents the base url.
  private baseUrl: string;

  /**
   *
   * @param httpService Performs HTTP requests.
   * @param encoding Service responsible for encoding hmac data for intowords requests.
   * @param cookieService Service responsible for managing cookies.
   */
  constructor(
    protected readonly httpService: HttpClient,
    protected readonly encoding: EncodingService,
    protected readonly cookieService: CookieService
  ) {
    super(httpService, encoding, cookieService);
    this.baseUrl = this.UrlEndpoints.dictionary;
  }

  /**
   * Get all dictionaries.
   */
  getDictionaries(): Observable<DictionaryInterface[]> {
    const body = {
      methodname: 'getDictionaries',
      args: {
        session_id: this.cookieService.get('mv_session_id'),
      },
    };
    return this.post(this.baseUrl, body).pipe(
      map((response: DictionaryResultInterface) => {
        if (this.responseHandler(response.result.method_result.res_code)) {
          return response.result.value;
        } else {
          throw new Error(response.result.method_result.res_msg);
        }
      })
    );
  }

  /**
   * Search word in selective dictionary.
   *
   * @param searchString Represents the string to be searched in dictionary.
   * @param dictID Represents the id of dictionary.
   * @param settings Represent settings of dictionary.
   */
  search(
    searchString: string,
    dictID: number,
    settings: DictionarySettingsInterface = {
      readAloud: true,
      showWordFormations: true,
      showInflections: true,
      showMeaning: true,
      showIdioms: true,
    }
  ): Observable<DictionarySearchInterface[]> {
    const body = {
      methodname: 'search',
      args: {
        session_id: this.cookieService.get('mv_session_id'),
        searchString,
        dictID,
        settings,
      },
    };
    return this.post(this.baseUrl, body).pipe(
      map((response: DictionarySearchResultInterface) => {
        if (this.responseHandler(response.result.method_result.res_code)) {
          return response.result.value;
        } else {
          throw new Error(response.result.method_result.res_msg);
        }
      })
    );
  }

  /**
   * Retrieves the dictionary description for a specific word.
   *
   * @param word Represents the word obtained in search response.
   * @param key Represents the key obtained in search response.
   * @param dictID Represents the id of dictionary.
   * @param settings Represent settings of dictionary.
   */
  getArticle(
    word: string,
    key: string,
    dictID: number,
    settings: DictionarySettingsInterface = {
      readAloud: true,
      showWordFormations: true,
      showInflections: true,
      showMeaning: true,
      showIdioms: true,
    }
  ): Observable<string> {
    const body = {
      methodname: 'getArticle',
      args: {
        session_id: this.cookieService.get('mv_session_id'),
        word,
        key,
        dictID,
        settings,
      },
    };
    return this.post(this.baseUrl, body).pipe(
      map((response: DictionaryArticleResultInterface) => {
        if (this.responseHandler(response.result.method_result.res_code)) {
          return response.result.value;
        } else {
          throw new Error(response.result.method_result.res_msg);
        }
      })
    );
  }

  /**
   * Handles response based on response code.
   *
   * @param responseCode Represents response code obtained in api response.
   *
   * @return Boolean indicating if everything went well. True if no errors, false otherwise.
   */
  responseHandler(responseCode: number): boolean {
    if (responseCode === 0) {
      return true;
    } else {
      return false;
    }
  }
}
