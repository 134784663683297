import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

/**
 * Translation object interface.
 */
export interface TranslationsI {
  [key: string]: string;
}

/**
 * LanguageHelperService is used to detect any changes regarding user's language.
 * The default language will be the first language from the available languages array.
 * When the browser's language is detected or the user changes the language, the app will
 * user the selected language.
 */
@Injectable()
export class LanguageHelperService {
  // Stores the translations.
  private translationsStrings: TranslationsI;
  // Current language.
  private currentLanguage: string;
  // Available languages.
  readonly availableLanguages = environment.availableLanguages;
  // Stores users's browser language.
  private userLang: string;

  /**
   * Gets the translations object.
   */
  get translationObject(): TranslationsI {
    return this.translationsStrings;
  }

  /**
   * Gets the current language.
   */
  get currentLangUsed(): string {
    return this.currentLanguage;
  }

  private getTransSource = new Subject<TranslationsI>();
  /**
   * Observable instance of the source object.
   */
  public OnLanguageChanged = this.getTransSource.asObservable();

  /**
   * Constructor function responsible for injecting the needed services.
   *
   * @param translateService Is an instance of TranslateService from TranslateModule.
   */
  constructor(private readonly translateService: TranslateService) {
    // Add languages to the translations array.
    this.translateService.addLangs(this.availableLanguages);
    /**
     * The first language from the available languages array will be used as a fallback when a translation isn't found
     * into the current language.
     */
    this.translateService.setDefaultLang(this.availableLanguages[0]);
    // Gets the user's browser language.
    this.userLang = navigator.language;

    // Checks if the browser language was detected.
    if (this.userLang) {
      // Find the language in the existing array.
      let findLanguage = '';

      if (this.userLang.length === 2) {
        findLanguage = this.availableLanguages.find(
          (lang) => lang.split('-')[0] === this.userLang
        );
      } else {
        findLanguage = this.availableLanguages.find(
          (lang) => lang === this.userLang
        );
      }

      if (findLanguage) {
        // If it's available then the app will use it.
        this.setLanguage(findLanguage);
      } else {
        // Otherwise the app will use the first language stored in the available languages array.
        this.setLanguage(this.availableLanguages[0]);
      }
    } else {
      // Otherwise the app will use the first language stored in the available languages array.
      this.setLanguage(this.availableLanguages[0]);
    }

    // An EventEmitter to listen to language change events.
    this.translateService.onLangChange.subscribe((data) => {
      return this.getCurrentLanguageAndTranslations(data.lang);
    });
  }

  /**
   * Changes the language currently used.
   *
   * @param language Represents the selected language.
   */
  setLanguage(language: string): void {
    this.translateService.use(language);
  }

  /**
   * Get current language and get translations for it.
   *
   * @param language: The language to use as a string.
   */
  getCurrentLanguageAndTranslations(language: string): void {
    this.translateService.getTranslation(language).subscribe((trans) => {
      this.currentLanguage = this.translateService.currentLang;
      this.translationsStrings = trans;
      this.getTransSource.next(this.translationsStrings);
    });
  }
}
