<div class="rules-container" *ngIf="suggestionRules.length > 0">
  <div class="rules-hld">
    <div class="rules-header flex-display">
      <div class="flex-display">
        <div class="icon-container">
          <mat-icon svgIcon="suggestion_rules"></mat-icon>
        </div>
        {{ "translateSuggestionRules" | translate }}
      </div>

    </div>

    <div class="sugg-hld">
      <div class="action-hld">
        <div (click)="navigateTo(0)" *ngIf="currentIndex===1" class="flex-display">
          <mat-icon>chevron_left</mat-icon>
          1 Level
        </div>
        <div (click)="navigateTo(1)" *ngIf="currentIndex===0" class="ml-auto flex-display">
          2 Level
          <mat-icon>chevron_right</mat-icon>
        </div>
        <div (click)="navigateTo(1)" *ngIf="currentIndex===2" class="flex-display">
          <mat-icon>chevron_left</mat-icon>
          2 Level
        </div>
        <div (click)="navigateTo(2)" *ngIf="currentIndex===1" class="ml-auto flex-display">
          3 Level
          <mat-icon>chevron_right</mat-icon>
        </div>
      </div>
      <mat-tab-group #matTabs [selectedIndex]="currentIndex" style="min-height:310px;" dynamicHeight>

        <mat-tab>
          <div *ngFor="let rule of suggestionRules" (click)="selectGroup(rule)" class="flex-display level-hld pointer"
            [ngClass]="{ active: rule.selected }">
            <div class="icon-container default-cursor" appDisableMouseClick (click)="toggleDefaultGroup(rule)">
              <mat-icon [svgIcon]="
                            rule.defaultEnabled
                              ? 'rules_checkbox_checked'
                              : 'rules_checkbox'
                          " class="rule-icon"></mat-icon>
            </div>
            <div class="flex-display select-hld">
              {{ rule.name }}
              <div class="icon-container ml-auto">
                <mat-icon [svgIcon]="
                              rule.selected ? 'rules_group_checked' : 'rules_group'
                            " class="group-icon"></mat-icon>
              </div>
            </div>
          </div>
        </mat-tab>

        <mat-tab>
          <div *ngIf="selectedGroup" [ngClass]="{ disabled: !selectedGroup.defaultEnabled }">
            <div *ngFor="let group of selectedGroup.subGroups" (click)="selectGroupRule(group)"
              class="flex-display level-hld pointer" [ngClass]="{ active: group.selected }">
              <div class="icon-container default-cursor" appDisableMouseClick (click)="toggleDefaultSubGroup(group)">
                <mat-icon [svgIcon]="
                            group.defaultEnabled
                              ? 'rules_checkbox_checked'
                              : 'rules_checkbox'
                          " class="rule-icon"></mat-icon>
              </div>
              <div class="flex-display select-hld">
                {{ group.name }}
                <div class="icon-container ml-auto">
                  <mat-icon [svgIcon]="
                              group.selected ? 'rules_group_checked' : 'rules_group'
                            " class="group-icon"></mat-icon>
                </div>
              </div>
            </div>
          </div>
        </mat-tab>

        <mat-tab>
          <div *ngIf="selectedSubGroup" [ngClass]="{
                      disabled:
                        !selectedGroup.defaultEnabled || !selectedSubGroup.defaultEnabled
                    }">
            <div *ngFor="let groupRule of selectedSubGroup.rules" class="flex-display level-hld">
              <div class="icon-container" (click)="toggleDefaultSubGroupRule(groupRule)">
                <mat-icon [svgIcon]="
                            groupRule.defaultEnabled
                              ? 'rules_checkbox_checked'
                              : 'rules_checkbox'
                          " class="rule-icon">
                </mat-icon>
              </div>
              {{ groupRule.written }} - {{ groupRule.alternative }}
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>

    </div>

    <div class="padd-l-45">
      <div class="test-btn" (click)="testSuggestion()">
        {{ "translateTest" | translate }}
      </div>

      <div *ngIf="toggleTest">
        <div class="search-hld">
          <input placeholder="Enter text" [(ngModel)]="textToTest" (keydown)="getSuggestions()" />
        </div>
        <div class="padd-t-20">
          <app-settings-suggesstions-list></app-settings-suggesstions-list>
        </div>
      </div>
    </div>

  </div>
</div>
