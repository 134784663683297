import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ErrorModalComponent } from '../modules/shared/components/error-modal/error-modal.component';

/**
 * This service is used to open the error modal.
 */
@Injectable()
export class ErrorModalService {
  /**
   * Constructor function responsible for injecting the needed services.
   *
   * @param dialog Is an instance of MatDialog.
   */
  constructor(public dialog: MatDialog) {}

  /**
   * Opens the error modal.
   *
   * @param message Represents the error message.
   */
  openModal(message: string): void {
    const dialogRef = this.dialog.open(ErrorModalComponent, {
      panelClass: 'error-class',
      data: message,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
      }
    });
  }
}
