import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LanguageHelperService } from 'src/app/services/language.service';
import { RouterModule } from '@angular/router';
import { NgxPrinterModule } from 'ngx-printer';
import { TranslateModule } from '@ngx-translate/core';
import { NgxPaginationModule } from 'ngx-pagination';
import { MaterialModule } from './material.module';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { InterceptorLoaderComponent } from './components/interceptor-loader/interceptor-loader.component';
import { ErrorModalComponent } from './components/error-modal/error-modal.component';
import { TopMenuComponent } from './components/top-menu/top-menu.component';
import { SettingsProfileLanguageListComponent } from './common/settings/profile/language-list/language-list.component';
import { SettingsModalsProfileCreateComponent } from './common/settings/modals/profile/create/create.component';
import { SettingsModalsProfileRenameComponent } from './common/settings/modals/profile/rename/rename.component';
import { WarningModalComponent } from './components/warning-modal/warning-modal.component';
import { SettingsProfileVoiceListComponent } from './common/settings/profile/voice-list/voice-list.component';
import { SettingsSpeechReadComponent } from './common/settings/speech/read/read.component';
import { SettingsSpeechSpeedComponent } from './common/settings/speech/speed/speed.component';
import { SettingsSuggestionsRulesComponent } from './common/settings/suggestions/rules/rules.component';
import { SuggestionsListComponent } from './common/settings/suggestions/list/list.component';

/**
 * Module holding all component shared dependencies.
 */
@NgModule({
  declarations: [
    SpinnerComponent,
    InterceptorLoaderComponent,
    ErrorModalComponent,
    TopMenuComponent,
    SettingsProfileLanguageListComponent,
    SettingsModalsProfileCreateComponent,
    SettingsModalsProfileRenameComponent,
    WarningModalComponent,
    SettingsProfileVoiceListComponent,
    SettingsSpeechReadComponent,
    SettingsSpeechSpeedComponent,
    SettingsSuggestionsRulesComponent,
    SuggestionsListComponent,
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    FlexLayoutModule,
    MaterialModule,
    RouterModule,
    NgxPrinterModule,
    TranslateModule.forChild(),
    NgxPaginationModule,
  ],
  providers: [LanguageHelperService],
  exports: [
    SpinnerComponent,
    InterceptorLoaderComponent,
    ErrorModalComponent,
    TopMenuComponent,
    SettingsProfileLanguageListComponent,
    SettingsModalsProfileCreateComponent,
    SettingsModalsProfileRenameComponent,
    WarningModalComponent,
    SettingsProfileVoiceListComponent,
    SettingsSpeechReadComponent,
    SettingsSpeechSpeedComponent,
    SettingsSuggestionsRulesComponent,
    SuggestionsListComponent,
  ],
})
export class SharedModule { }
