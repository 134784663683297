import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { WarningModalComponent } from '../modules/shared/components/warning-modal/warning-modal.component';

/**
 * Warning data interface.
 */
export interface WarningDataInterface {
  title: string;
  message: string;
}
/**
 * This service is used to open the warning modal.
 */
@Injectable()
export class WarningModalService {
  /**
   * Constructor function responsible for injecting the needed services.
   *
   * @param dialog Is an instance of MatDialog.
   */
  constructor(public dialog: MatDialog) {}

  /**
   * Opens the warning modal.
   *
   * @param warningTitle Represents the title of the warning message.
   * @param warningMessage Represents the description of the warning message.
   *
   * @return An instance of MatDialogRef.
   */
  openModal(
    warningTitle: string,
    warningMessage: string
  ): MatDialogRef<WarningModalComponent> {
    const modalData: WarningDataInterface = {
      title: warningTitle,
      message: warningMessage,
    };
    return this.dialog.open(WarningModalComponent, {
      panelClass: 'error-class',
      data: modalData,
    });
  }
}
