import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { EncodingService } from './encoding.service';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';

/**
 * Base service that implements basic intowords auth and req logic.
 */
@Injectable()
export class IntowordsBaseService<T> {
  protected readonly API_VERSION = 1;

  // Reference to URL endpoints located in environment.
  protected readonly UrlEndpoints = environment.UrlEndpoints;

  /**
   * We inject all the services that correspond to all implemented adapters.
   *
   * @param httpService Performs HTTP requests.
   * @param encoding Service responsible for encoding hmac data for intowords requests.
   * @param cookieService Service responsible for managing cookies.
   */
  constructor(
    protected readonly httpService: HttpClient,
    protected readonly encoding: EncodingService,
    protected readonly cookieService: CookieService
  ) {}

  /**
   * Generates the default header required by intowords api.
   * clientID "intowords:" is added in this step.
   *
   * @param hmac The hmac encoded secret.
   * @param timestamp The timestamp of the request.
   */
  private generateDefaultHeader(
    hmac: string,
    timestamp: string
  ): { [header: string]: string } {
    return {
      sessionid: this.cookieService.get('mv_session_id'),
      'x-custom-authorization': `intowords:${hmac}`,
      requestdatetime: timestamp,
      'Content-Type': 'application/json',
      accept: 'application/json',
    };
  }

  /**
   * Makes a get request.
   *
   * @param url The url of the request.
   * @param isJson Marks if the response is a json or not. Defaults to true.
   * @param isPdfResponse Marks if the response is a pdf resource or not. Defaults to false.
   */
  protected get<R>(url: string): Observable<R>;
  protected get<S>(url: string, isJson: boolean): Observable<S>;
  protected get(
    url: string,
    isJson: boolean,
    isPdfResponse: boolean
  ): Observable<{ body: ArrayBuffer }>;
  protected get(
    url: string,
    isJson = true,
    isPdfResponse = false
  ): Observable<T | { body: ArrayBuffer }> {
    const timestamp = Math.round(new Date().getTime() / 1000).toString();
    const hmac = this.encoding.encodeMessage('', 'GET', url, timestamp);

    const headers = Object.assign(this.generateDefaultHeader(hmac, timestamp), {
      Accepts: 'application/json' + (isPdfResponse ? ', application/pdf' : ''),
    });
    return this.httpService
      .get<T>(url, {
        headers,
        observe: isPdfResponse ? ('response' as 'body') : 'body',
        responseType: isPdfResponse ? ('arraybuffer' as 'json') : 'json',
      })
      .pipe(
        map(
          (res: T | { body: T }): T => {
            if (isJson && 'body' in res) {
              return res.body;
            }
            return res as T;

            // return isJson && 'body' in res ? res.body : res;
          }
        )
      );
  }

  /**
   * Makes a post request.
   *
   * @param url The url of the request.
   * @param body The body of the request.
   */
  protected post<R, S>(url: string, body: R): Observable<S>;
  protected post<S, R>(
    url: string,
    body: S,
    extraHeaders: { [header: string]: string }
  ): Observable<R>;
  protected post<R>(
    url: string,
    body: R,
    extraHeaders: { [header: string]: string } = {}
  ): Observable<T> {
    const timestamp = Math.round(new Date().getTime() / 1000).toString();
    const stringBody = JSON.stringify(body);
    const hmac = this.encoding.encodeMessage(
      stringBody,
      'POST',
      url,
      timestamp
    );
    const headers = Object.assign(
      this.generateDefaultHeader(hmac, timestamp),
      extraHeaders
    );
    return this.httpService
      .post<T>(url, stringBody, { headers })
      .pipe(map((res: T) => res));
  }

  /**
   * Make an update request.
   *
   * @param url The url of the request.
   * @param body The body for the request that will be updated.
   */
  protected put<S>(url: string, body: S): Observable<T> {
    const timestamp = Math.round(new Date().getTime() / 1000).toString();
    const hmac = this.encoding.encodeMessage('', 'DELETE', url, timestamp);
    const headers = this.generateDefaultHeader(hmac, timestamp);
    const stringBody = JSON.stringify(body);

    return this.httpService
      .put<T>(url, stringBody, { headers })
      .pipe(map((res: T) => res));
  }

  // TODO: implement if needed.
  protected patch(): Observable<T> {
    return of(null);
  }

  /**
   * Make a Delete request.
   *
   * @param url The url of the request.
   */
  protected delete(url: string): Observable<T> {
    const timestamp = Math.round(new Date().getTime() / 1000).toString();
    const hmac = this.encoding.encodeMessage('', 'DELETE', url, timestamp);
    const headers = this.generateDefaultHeader(hmac, timestamp);
    return this.httpService.delete<T>(url, { headers });
  }
}
