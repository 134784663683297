import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

/**
 * This service exposes a Subject(isLoading) to whom we can emit true/false values from the interceptor
 * and listen for changes from our InterceptorLoaderComponent.
 */
@Injectable()
export class InterceptorLoaderService {
  /**
   * The subject used to control the service communication.
   */
  isLoading = new Subject<boolean>();

  /**
   * Calls the source of the observable and cascades the action. isLoading is set to true.
   */
  show(): void {
    this.isLoading.next(true);
  }

  /**
   * Calls the source of the observable and cascades the action. isLoading is set to false.
   */
  hide(): void {
    this.isLoading.next(false);
  }
}
