import { Injectable } from '@angular/core';
import {
  MAX_SPEED,
  MIN_SPEED,
  REGEX_WITH_DIACRITICS,
} from '../common/constants';
import { ProfileInfoExtendedDTOI } from '../dto/profile/profile-dto';
import { LanguageHelperService } from './language.service';

/**
 * This service is used for profile validation (name and speed).
 */

@Injectable()
export class ProfileValidationService {
  // Error messages used to display information to the user.
  errorEmpty = '';
  errorNotValid = '';
  errorNotUnique = '';

  // Regex for validating profile name (matches everything that is not alphanumeric or diacritics).
  regex = REGEX_WITH_DIACRITICS;
  // Stores the translations.
  translatedObj: { [key: string]: string };

  /**
   * Constructor function responsible for injecting the needed services.
   *
   * @param languageHelperService Reference to LanguageHelperService.
   */
  constructor(private readonly languageHelperService: LanguageHelperService) {
    this.translatedObj = this.languageHelperService.translationObject;
    if (this.translatedObj) {
      this.errorEmpty = this.translatedObj.translateProfileNameCantBeEmpty;
      this.errorNotValid = this.translatedObj.translateProfileNameShoulContainAlphaNumericValues;
      this.errorNotUnique = this.translatedObj.translateProfileNameShoulBeUnique;
    }
  }

  /**
   * Checks if the profile name is valid.
   *
   * @param profiles Represents the profiles array.
   * @param name Represents the name of the profile.
   *
   * @return The error as string.
   */
  isNameValid(profiles: ProfileInfoExtendedDTOI[], name: string): string {
    const regexWhiteSpace = /\s+/g;
    const nameWithSpace = name.replace(regexWhiteSpace, '');
    if (nameWithSpace === '') {
      return this.errorEmpty;
    }
    if (!this.checkIfNameIsUnique(profiles, nameWithSpace)) {
      return this.errorNotUnique;
    }
    if (this.regex.test(nameWithSpace)) {
      return this.errorNotValid;
    }
  }

  /**
   * Checks if the profile name is unique.
   *
   * @param profiles Represents the profiles array.
   * @param name Represents the name of the profile.
   *
   * @return True if it's unique, false otherwise.
   */
  checkIfNameIsUnique(
    profiles: ProfileInfoExtendedDTOI[],
    name: string
  ): boolean {
    if (profiles && profiles.length) {
      const findProfileName = profiles.find(
        (profile) => profile.profile.name.toLowerCase() === name.toLowerCase()
      );
      if (findProfileName) {
        return false;
      }
    }
    return true;
  }

  /**
   * Checks if the speed is in allowed range.
   *
   * @param speed Represents the input speed.
   *
   * @return The speed as number.
   */
  isSpeedValid(speed: number): number {
    if (speed < MIN_SPEED) {
      return MIN_SPEED;
    }
    if (speed > MAX_SPEED) {
      return MAX_SPEED;
    }
    return speed;
  }
}
