<div class="dialog-hld">
  <div mat-dialog-title class="mat-dialog-title">
    <div class="title" fxLayout="row" fxLayoutAlign="start center">
      <div class="title-hld">{{ data.title }}</div>
    </div>
  </div>

  <div mat-dialog-content class="content-hld">
    <div>{{ data.message }}</div>
  </div>

  <div
    mat-dialog-actions
    fxLayout="row"
    fxLayoutAlign="end center"
    class="modal-footer"
  >
    <div (click)="save()" class="action-btn">
      {{ "translateCancel" | translate }}
    </div>
    <div (click)="save(true)" class="action-btn">
      {{ "translateOk" | translate }}
    </div>
  </div>
</div>
