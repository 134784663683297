import { Injectable } from '@angular/core';
import * as crypto from 'crypto-js';
import { environment } from 'src/environments/environment';

/**
 * Service responsible for encoding hmac data for intowords requests.
 */
@Injectable()
export class EncodingService {
  constructor() {}

  /**
   * Returns a HMAC SHA256 encryption of the val/key pair.
   *
   * @param val Value string.
   * @param key Key string.
   */
  hmacSha256(val: string, key: string): string {
    return crypto.HmacSHA256(val, key).toString(crypto.enc.Base64);
  }

  /**
   * Returns the val parameter encoded as base64.
   *
   * @param val Value string.
   */
  base64(val: string): string {
    return crypto.enc.Base64.stringify(crypto.enc.Utf8.parse(val));
  }

  /**
   * Intowords specific authorization encoding.
   *
   * @param message Url request body.
   * @param verb Url request verb.
   * @param uri Url request url.
   * @param timestamp current timestamp.
   */
  encodeMessage(
    message: string,
    verb: string,
    uri: string,
    timestamp: string
  ): string {
    // HMAC only uses first 10.000 bytes of message.
    const maxBytes = 10000;
    if (message.length > maxBytes) {
      message = message.slice(0, maxBytes);
    }
    const hmacString = this.hmacSha256(
      `${message}${verb}${uri}${timestamp}`,
      environment.hmacSecret
    );
    return hmacString;
  }
}
