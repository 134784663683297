import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { of, ReplaySubject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { profiles } from 'src/app/common/profiles';
import { CreateProfileInputDataI, ProfileInfoExtendedDTOI } from 'src/app/dto/profile/profile-dto';
import { CustomIconService } from 'src/app/services/custom-icon.service';
import { EventStoreService } from 'src/app/services/event-store.service';
import { LanguageHelperService, TranslationsI } from 'src/app/services/language.service';
import { ProfileHelperService } from 'src/app/services/profile-helper.service';
import { WarningModalService } from 'src/app/services/warning-modal.service';
import { environment } from 'src/environments/environment';
import { SettingsModalsProfileCreateComponent } from '../shared/common/settings/modals/profile/create/create.component';
import { SettingsModalsProfileRenameComponent } from '../shared/common/settings/modals/profile/rename/rename.component';

/**
 * This component is used as a placeholder for the app.
 */
@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit, OnDestroy {
  // Selected profile.
  selectedProfile: ProfileInfoExtendedDTOI;
  // Dummy data for profiles.
  profiles: ProfileInfoExtendedDTOI[] = profiles;
  // Stores the avialable languages.
  languages = environment.availableLanguages;
  // Stores the translations.
  translatedObj: TranslationsI;
  // Stores the subscribers until they're destroyed.
  private readonly destroyed = new ReplaySubject<never>();

  /**
   * Constructor function responsible for injecting the needed services.
   *
   * @param customIconService Reference to CustomIconService.
   * @param router Reference to Router.
   * @param dialog Reference to MatDialog.
   * @param languageHelperService Reference to LanguageHelperService.
   * @param warningModalService Reference to WarningModalService.
   * @param profileHelperService Reference to ProfileHelperService.
   * @param eventStoreService Reference to EventStoreService.
   */
  constructor(
    private readonly customIconService: CustomIconService,
    private readonly router: Router,
    public readonly dialog: MatDialog,
    private readonly languageHelperService: LanguageHelperService,
    private readonly warningModalService: WarningModalService,
    private readonly profileHelperService: ProfileHelperService,
    private readonly eventStoreService: EventStoreService,
  ) {
    // Returns the icons used in UI.
    this.customIconService.getCustomIcons([
      'Favicon-svg',
      'icon-Profile',
      'profile_settings',
      'radio_checked',
      'radio_unchecked',
      'reset',
      'icon-Delete',
      'icon-Create-new',
      'settings_speech',
      'settings_suggestions'
    ]);
    const profileFlags: string[] = [];
    for (const prof of this.profiles) {
      profileFlags.push(prof.profile.language);
    }
    this.getCountryFlags(profileFlags);
    this.translatedObj = this.languageHelperService.translationObject;
    this.selectedProfile = this.profiles.find(el => el.isCurrent);
  }

  /**
   * A lifecycle hook that is called after Angular has initialized all data-bound properties of a directive.
   */
  ngOnInit(): void {
    // Gets the translations if the language was changed.
    this.languageHelperService.OnLanguageChanged.pipe(
      takeUntil(this.destroyed)
    ).subscribe((trans) => {
      this.translatedObj = trans;
    });
  }

  /**
   * Unsubscribe Observables and detach event handlers to avoid memory leaks.
   */
  ngOnDestroy(): void {
    this.destroyed.next();
  }

  /**
   * Gets the flag for each country from profiles array.
   */
  getCountryFlags(profileFlags: string[]): void {
    this.customIconService.getCustomIcons(profileFlags, 'flags');
  }

  /**
   * Select the profile that will be edited/set as current.
   *
   * @param profile Represents the selected profile.
   */
  setSelectedProfile(profile: ProfileInfoExtendedDTOI): void {
    const findSelectedProfile = this.profiles.find(el => el.isCurrent);
    if (findSelectedProfile && findSelectedProfile.profile.language !== profile.profile.language) {
      findSelectedProfile.isCurrent = !findSelectedProfile.isCurrent;
      profile.isCurrent = !profile.isCurrent;
      this.selectedProfile = profile;
    }
  }

  /**
   * Opens SettingsModalsProfileCreateComponent modal.
   */
  createProfile(): void {
    const createData: CreateProfileInputDataI = {
      profiles: this.profiles,
      currentProfile: this.selectedProfile,
    };
    const dialogRef = this.dialog.open(SettingsModalsProfileCreateComponent, {
      panelClass: 'documents-class',
      data: createData,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) { }
    });
  }

  /**
   * Remove profile.
   *
   * @param profile Represents the selected profile.
   */
  removeProfile(profile: ProfileInfoExtendedDTOI): void {
    const dialogRef = this.warningModalService.openModal(
      this.translatedObj.translateAreYouSure,
      this.translatedObj.translateAboutToDeleteProfile
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result) { }
    });
  }

  /**
   * Opens rename profile modal.
   *
   * @param profile Represents the selected profile.
   */
  renameProfile(profile: ProfileInfoExtendedDTOI): void {
    const createData: CreateProfileInputDataI = {
      profiles: this.profiles,
      currentProfile: profile,
    };
    const dialogRef = this.dialog.open(SettingsModalsProfileRenameComponent, {
      panelClass: 'documents-class',
      data: createData,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) { }
    });
  }

  /**
   * Navigates to speech view.
   */
  goToSpeech(): void {
    const obs = of(true).pipe(
      map(() => {
        this.profileHelperService.sendCurrentProfile({
          profiles: this.profiles,
          currentProfile: this.selectedProfile
        });
        return true;
      })
    );
    this.eventStoreService.registerActions({
      type: 'profile.loaded',
      observable: obs,
    });

    this.router.navigate(['/speech']);
  }

  /**
   * Navigates to suggestions view.
   */
  goToSuggestions(): void {
    const obs = of(true).pipe(
      map(() => {
        this.profileHelperService.sendCurrentProfile({
          profiles: this.profiles,
          currentProfile: this.selectedProfile
        });
        return true;
      })
    );
    this.eventStoreService.registerActions({
      type: 'profile.loaded',
      observable: obs,
    });
    this.router.navigate(['/suggestions']);
  }

}
