<div appDisableMouseClick *ngIf="voiceOptions">
  <div mat-menu-item class="menu-item" (click)="toggleVoiceOption('writeLetterName')"
    [ngClass]="{ 'full-width': extraOption }">
    <div class="icon-container">
      <mat-icon svgIcon="letter_name" [ngClass]="{ 'app-icon-active': voiceOptions.writeLetterName }"></mat-icon>
    </div>
    {{ "translateReadLetterName" | translate }}
    <mat-slide-toggle class="ml-auto" [checked]="voiceOptions.writeLetterName"
      (click)="toggleVoiceOption('writeLetterName')" appDisableMouseClick></mat-slide-toggle>
  </div>

  <div mat-menu-item class="menu-item" (click)="toggleVoiceOption('writeLetterSound')"
    [ngClass]="{ 'full-width': extraOption }">
    <div class="icon-container mrg-r-10">
      <mat-icon id="fix" svgIcon="letter_sound" [ngClass]="{
          'app-icon-active': voiceOptions.writeLetterSound
        }"></mat-icon>
    </div>
    {{ "translateReadLetterSound" | translate }}
    <mat-slide-toggle class="ml-auto" [checked]="voiceOptions.writeLetterSound"
      (click)="toggleVoiceOption('writeLetterSound')" appDisableMouseClick></mat-slide-toggle>
  </div>

  <div mat-menu-item class="menu-item" (click)="toggleVoiceOption('writeWord')"
    [ngClass]="{ 'full-width': extraOption }">
    <div class="icon-container">
      <mat-icon svgIcon="word" [ngClass]="{ 'app-icon-active': voiceOptions.writeWord }"></mat-icon>
    </div>
    {{ "translateReadWord" | translate }}
    <mat-slide-toggle class="ml-auto" [checked]="voiceOptions.writeWord" (click)="toggleVoiceOption('writeWord')"
      appDisableMouseClick></mat-slide-toggle>
  </div>

  <div mat-menu-item class="menu-item" (click)="toggleVoiceOption('writeSentence')"
    [ngClass]="{ 'full-width': extraOption }">
    <div class="icon-container">
      <mat-icon svgIcon="sentence" [ngClass]="{ 'app-icon-active': voiceOptions.writeSentence }"></mat-icon>
    </div>
    {{ "translateReadSentence" | translate }}
    <mat-slide-toggle class="ml-auto" [checked]="voiceOptions.writeSentence"
      (click)="toggleVoiceOption('writeSentence')" appDisableMouseClick></mat-slide-toggle>
  </div>

  <div mat-menu-item class="menu-item" (click)="toggleVoiceOption('readForward')"
    [ngClass]="{ 'full-width': extraOption }">
    <div class="icon-container">
      <mat-icon svgIcon="continuous" [ngClass]="{ 'app-icon-active': voiceOptions.readForward }"></mat-icon>
    </div>
    {{ "translateReadContinuously" | translate }}
    <mat-slide-toggle class="ml-auto" [checked]="voiceOptions.readForward" (click)="toggleVoiceOption('readForward')"
      appDisableMouseClick></mat-slide-toggle>
  </div>

  <div *ngIf="extraOption" mat-menu-item class="menu-item" (click)="toggleVoiceOption('highlightWord')"
    [ngClass]="{ 'full-width': extraOption }">
    <div class="icon-container">
      <mat-icon svgIcon="highlight" [ngClass]="{ 'app-icon-active': voiceOptions.highlightWord }"></mat-icon>
    </div>
    {{ "translateHighlight" | translate }}
    <mat-slide-toggle class="ml-auto" [checked]="voiceOptions.highlightWord"
      (click)="toggleVoiceOption('highlightWord')" appDisableMouseClick></mat-slide-toggle>
  </div>
</div>
