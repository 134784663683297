import { Component, Input, Output, EventEmitter } from '@angular/core';
import { VoiceResult } from 'src/app/services/intowords/intowords-voice.service';

/**
 * This component is used to hold the voices list.
 */
@Component({
  selector: 'app-shared-common-settings-profile-voice-list',
  templateUrl: './voice-list.component.html',
  styleUrls: ['./voice-list.component.scss'],
})
export class SettingsProfileVoiceListComponent {
  @Input() voices: VoiceResult[];

  /**
   * Allows new data to flow from the child to the parent.
   * Everytime when the user selects a profile, the parent get's updated with the new selected profile
   * and then sends the new profile to it's children.
   */
  @Output() setVoice = new EventEmitter<VoiceResult>();

  /**
   * Select the voice that will be used for the selected profile.
   *
   * @param voice Represents the selected voice.
   */
  selectVoice(voice: VoiceResult): void {
    this.setVoice.emit(voice);
  }
}
