import { REGEX_WITH_DIACRITICS } from 'src/app/common/constants';
import { HTMLCaretHelper } from './caret-helper';

/**
 * Selected text interface.
 */
export interface SelectedText {
  start: SelectedNode;
  end: SelectedNode;
}

/**
 * Selected node interface.
 */
export interface SelectedNode {
  node: any;
  offset: number;
}

/**
 * Selected word interface.
 */
export interface SelectedWord {
  text: string;
  node: SelectedText;
}

/**
 * Wrapped range interface.
 */
export interface WrappedRangeI {
  collapsed: boolean;
  commonAncestorContainer: Node;
  endContainer: Node;
  endOffset: number;
  startContainer: Node;
  startOffset: number;
}

/**
 * Original text info interface.
 */
export interface OriginalTextInfo {
  text: string;
  start: number;
  end: number;
}

declare var rangy;

/**
 * Helper class that works with selection on html content.
 */
export class SelectionHelper {
  private rangeAt: WrappedRangeI;
  // Regex for matching everything that is not alphanumeric or diacritics.
  private regex = REGEX_WITH_DIACRITICS;
  // Represents the selected nodes with the additional info of start and end node.
  private selectedWords: SelectedWord[] = [];
  // Stores all the selected text nodes.
  private fileteredNodes: Node[] = [];
  // Stores the word that should be composed (one word can belong to several nodes).
  private complexWord = '';
  // Tells if we have an incomplete word.
  private shouldCompose = false;
  // Stores the selected string.
  private selectionString = '';
  // Stores the original selected array of words (with nonAlphaNumerics chars replaced with ' ').
  private originalSelectedWords: string[] = [];
  // Stores the remaining words used for comparison.
  private selectedChunk: string[] = [];
  // Stores the word that should be composed from multiple nodes.
  private composedWord: SelectedWord;

  /**
   * Find all selected text nodes.
   *
   * @param container Represents the selected node from the iframe.
   */
  findAllSelectedTextNodes(container?: Node): void {
    // To get all nodes within a selection use rangy.getSelection() instead of window.getSelection().
    const isSelected = rangy.getSelection(container);
    if (isSelected.rangeCount > 0) {
      this.rangeAt = isSelected.getRangeAt(0);
      let selectedNodes = [];
      // Get all selected the nodes.
      for (let i = 0; i < isSelected.rangeCount; ++i) {
        selectedNodes = selectedNodes.concat(
          isSelected.getRangeAt(i).getNodes()
        );
      }
      // We only need the text nodes (with nodeType = 3).
      this.fileteredNodes = selectedNodes.filter(
        (el) => el.nodeType === 3 && el.parentNode.nodeName !== 'svg'
      );
    }
  }

  /**
   * Get all the selected words.
   *
   * @param selectedTxt Represents the selected text.
   *
   * @return An array of selected words with extra info.
   */
  getAllSelectedWords(selectedTxt: string): SelectedWord[] {
    this.originalSelectedWords = [];
    // Replace all nonAlphaNumerics chars with whitespace.
    this.selectionString = selectedTxt.replace(this.regex, ' ');
    // Final array of selected words.
    this.originalSelectedWords = this.selectionString.split(' ');
    let startC = 0;

    for (let i = 0; i < this.fileteredNodes.length; i++) {
      // Current node selected content.
      const currentNodeText = this.getOriginalSelectedTextForNode(
        this.fileteredNodes[i]
      )
        .replace(this.regex, ' ')
        .split(' ');
      // Stores the selected words of the node, with the extra info (words position).
      const indexedNodeContent = this.mapOriginalText(currentNodeText, i);

      if (this.shouldCompose) {
        startC = startC - 1;
      }
      const endC = indexedNodeContent.length + startC;
      // Store the original words so that we can match it with each individual word from the current node.
      this.selectedChunk = this.originalSelectedWords
        .filter((el) => el.length)
        .slice(startC, endC);
      startC = endC;
      this.addWords(indexedNodeContent, this.fileteredNodes[i]);
    }
    return this.selectedWords;
  }

  /**
   * Get the selected text for each node.
   *
   * @param node Represents the current node.
   *
   * @return The node content as a string.
   */
  getOriginalSelectedTextForNode(node: Node): string {
    // Stores the node text content (unmodified, it will contain nonAlphaNumeric chars).
    let currentNodeContent = '';
    // Slice the node content based on the node position.
    if (node === this.rangeAt.startContainer) {
      // If it's the first node, get the node text from the startOffset.
      currentNodeContent = node.textContent.slice(
        this.rangeAt.startOffset,
        node.textContent.length
      );
    } else if (node === this.rangeAt.endContainer) {
      // If it's the last node, get the node text from 0 until the endOffset.
      currentNodeContent = node.textContent.slice(0, this.rangeAt.endOffset);
    } else {
      // Get full text.
      currentNodeContent = node.textContent.slice(
        0,
        node.textContent.length < this.selectionString.length
          ? node.textContent.length
          : this.selectionString.length
      );
    }
    return currentNodeContent;
  }

  /**
   * Maps the original words with their exact position from the selected string.
   *
   * @param currentNodeText Represents the words from the current node as an array.
   * @param index Represents the index of the current node.
   *
   * @return An array of maped words with their position.
   */
  mapOriginalText(
    currentNodeText: string[],
    index: number
  ): OriginalTextInfo[] {
    let currentNodesIndexed: OriginalTextInfo[] = [];
    for (let k = 0; k < currentNodeText.length; k++) {
      let startPos = 0;
      if (index === 0) {
        startPos = this.rangeAt.startOffset;
      }
      startPos = k > 0 ? currentNodesIndexed[k - 1].end + 1 : startPos;
      // Stop at the range end offset when we reach the last node.
      // In case the last node is an empty element rangy will not return that so we need to check
      // that the actual node we are looking at matches the end node in the range selection.
      if (
        this.fileteredNodes.length - 1 === index &&
        this.fileteredNodes[this.fileteredNodes.length - 1] ===
        this.rangeAt.endContainer &&
        startPos > this.rangeAt.endOffset
      ) {
        k = currentNodeText.length;
        continue;
      }
      const endPos =
        k > 0
          ? currentNodeText[k].length + currentNodesIndexed[k - 1].end + 1
          : currentNodeText[k].length + startPos;

      currentNodesIndexed.push({
        text: currentNodeText[k],
        start: startPos,
        end: endPos,
      });
    }
    currentNodesIndexed = currentNodesIndexed.filter((el) => el.text.length);
    return currentNodesIndexed;
  }

  /**
   * Add words to the selectedWords array.
   *
   * @param currentNodesIndexed Represents selected words from the current node.
   * @param currentNode Represents the current node.
   */
  addWords(currentNodesIndexed: OriginalTextInfo[], currentNode: Node): void {
    for (let j = 0; j < currentNodesIndexed.length; j++) {
      // Check if the current word matches the word from the remaining words.
      if (
        currentNodesIndexed[j].text.toLocaleLowerCase() ===
        this.selectedChunk[j].toLocaleLowerCase()
      ) {
        // Set the start and end node for the current word and add it to the selectedWords array.
        const startNode: SelectedNode = {
          node: currentNode,
          offset: currentNodesIndexed[j].start,
        };
        const endNode: SelectedNode = {
          node: currentNode,
          offset: currentNodesIndexed[j].end,
        };

        this.selectedWords.push({
          text: currentNodesIndexed[j].text,
          node: { start: startNode, end: endNode },
        });
      } else if (this.shouldCompose) {
        // If the word is incomplete, add the partial word from the current node.
        const findComposed = this.selectedWords.find(
          (el) => el.text === this.composedWord.text
        );
        if (findComposed) {
          const newTxt = findComposed;
          // Compose the word and set the end node (the start node will always be the first node when it didn't match).
          newTxt.text = findComposed.text + currentNodesIndexed[j].text;
          newTxt.node.end.node = currentNode;
          newTxt.node.end.offset = currentNodesIndexed[j].end;
          if (newTxt.text === this.complexWord) {
            // If the new composed word matches the initial word, then remove the old word from the selectedWords array
            // and add the new one and reset the flag.
            this.selectedWords.splice(
              this.selectedWords.indexOf(findComposed),
              1
            );
            this.selectedWords.push(newTxt);
            this.shouldCompose = false;
            this.complexWord = '';
            continue;
          }
        }
      } else {
        // The words don't match.
        const startNode: SelectedNode = {
          node: currentNode,
          offset: currentNodesIndexed[j].start,
        };
        const endNode: SelectedNode = {
          node: currentNode,
          offset: currentNodesIndexed[j].end,
        };

        this.shouldCompose = true;
        // Store the word that doesn't match with the current word.
        this.complexWord = this.selectedChunk[j];
        this.composedWord = {
          text: currentNodesIndexed[j].text,
          node: { start: startNode, end: endNode },
        };
        this.selectedWords.push(this.composedWord);
      }
    }
  }
}

/**
 * Class helper for highlight element.
 */
export class HighlightElement {
  text: string;
  start: SelectedNode;
  end: SelectedNode;
  time: number;
  highlighted = false;
  helper = new HTMLCaretHelper();

  /**
   * Highlights given element.
   */
  highlight(): void {
    this.highlighted = true;
    this.helper.setCaretRange(
      this.start.node,
      this.start.offset,
      this.end.offset,
      this.start.node,
      this.end.node
    );
  }
}
