import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  ProfileDTO,
  ProfileDTOI,
  ProfileInfoDTO,
  ProfileInfoDTOI,
} from 'src/app/dto/profile/profile-dto';
import {
  ProfileEntity,
  ProfileEntityI,
  ProfileInfoEntityI,
} from 'src/app/dto/profile/profile-entity';
import { EncodingService } from './encoding.service';
import { IntowordsBaseService } from './intowords-base.service';

/**
 * Profile body interface for create profile request.
 */
export interface CreateProfileBody {
  name: string;
  language: string;
  application: string;
}

/**
 * Profile id interface.
 */
export interface ProfileIdI {
  profileId: string;
}

/**
 * Service responsible of working with profiles.
 */
@Injectable()
export class UserProfileService extends IntowordsBaseService<ProfileDTOI> {
  // Represents the base url.
  private baseUrl: string;

  /**
   * Constructor function responsible for injecting the needed services.
   *
   * @param httpService Performs HTTP requests.
   * @param encoding Service responsible for encoding hmac data for intowords requests.
   * @param cookieService Service responsible for managing cookies.
   */
  constructor(
    protected readonly httpService: HttpClient,
    protected readonly encoding: EncodingService,
    protected readonly cookieService: CookieService
  ) {
    super(httpService, encoding, cookieService);
    this.baseUrl = this.UrlEndpoints.profiles;
  }

  /**
   * Get all profiles.
   */
  getAllProfiles(): Observable<ProfileInfoDTOI[]> {
    return this.get(
      `${this.baseUrl}/v${this.API_VERSION}/Profiles/GetInfos/intowords/all`,
      false
    ).pipe(
      map((profiles: ProfileInfoEntityI[]) => {
        const i = profiles.map((profile) => ProfileInfoDTO.fromEntity(profile));
        return i;
      })
    );
  }

  /**
   * Create a new profile.
   *
   * @param name Represents the name of the profile.
   * @param language Represents the language for the profile.
   */
  createProfile(
    profileName: string,
    profileLanguage: string
  ): Observable<ProfileDTOI> {
    const createBody: CreateProfileBody = {
      name: profileName,
      language: profileLanguage,
      application: 'intowords',
    };
    return this.post<CreateProfileBody, ProfileEntityI>(
      `${this.baseUrl}/v${this.API_VERSION}/Profiles/Create/`,
      createBody
    ).pipe(
      map((result: ProfileEntityI) => {
        return ProfileDTO.fromEntity(result);
      })
    );
  }

  /**
   * Update profile.
   *
   * @param body Represents the body of the request.
   */
  updateProfile(body: ProfileDTOI): Observable<boolean> {
    const mappedBody = ProfileEntity.toEntity(body);
    return this.post<ProfileDTOI, boolean>(
      `${this.baseUrl}/v${this.API_VERSION}/Profiles/Save`,
      mappedBody
    ).pipe(
      map((result) => {
        return true;
      })
    );
  }

  /**
   * Deletes all profiles.
   */
  deleteAllProfiles(): Observable<boolean> {
    return this.delete(
      `${this.baseUrl}/v${this.API_VERSION}/Profiles/DeleteAll/intowords`
    ).pipe(
      map((result) => {
        return true;
      })
    );
  }

  /**
   * Set current profile for application.
   *
   * @param id Represents the profile ID.
   */
  setCurrentProfile(id: string): Observable<boolean> {
    const profileId: ProfileIdI = { profileId: id };
    return this.post<ProfileIdI, boolean>(
      `${this.baseUrl}/v${this.API_VERSION}/Profiles/SetCurrent/intowords/${id}`,
      profileId
    ).pipe(
      map((result) => {
        return true;
      })
    );
  }

  /**
   * Get current profile for application.
   */
  getCurrentProfile(): Observable<ProfileDTOI> {
    return this.get<ProfileEntityI>(
      `${this.baseUrl}/v${this.API_VERSION}/Profiles/GetCurrent/intowords`,
      false
    ).pipe(
      map((result: ProfileEntityI) => {
        const mappedResult = ProfileDTO.fromEntity(result);
        return mappedResult;
      })
    );
  }

  /**
   * Reset profile.
   *
   * @param id Represents the profile ID.
   */
  resetProfile(id: string): Observable<ProfileDTOI> {
    return this.get<ProfileEntityI>(
      `${this.baseUrl}/v${this.API_VERSION}/Profiles/Reset/${id}`,
      false
    ).pipe(
      map((result: ProfileEntityI) => {
        const mappedResult = ProfileDTO.fromEntity(result);
        return mappedResult;
      })
    );
  }

  /**
   * Delete profile.
   *
   * @param id Represents the profile ID.
   */
  deleteProfile(id: string): Observable<boolean> {
    return this.delete(
      `${this.baseUrl}/v${this.API_VERSION}/Profiles/Delete/${id}`
    ).pipe(
      map((result) => {
        return true;
      })
    );
  }
}
