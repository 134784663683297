import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, NgZone } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { SESSIONID_TIMEOUT, SESSIONID_NOT_VALID } from '../common/constants';
import { ErrorModalService } from './error-modal.service';

/**
 * This service is used to throw the error. It shows the error in a dialog.
 */
@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  /**
   * Constructor function responsible for injecting the needed services.
   *
   * @param modalService Is an instance of ErrorModalService.
   * @param ngZone Is an instance of NgZone.
   * @param logger Is an instance of NGXLogger.
   */
  constructor(
    public readonly modalService: ErrorModalService,
    private ngZone: NgZone,
    private readonly logger: NGXLogger
  ) {}

  /**
   * Calls the openModal method to open the modal and show the error.
   *
   * @param error Represents the error that will be handled.
   */
  handleError(error: HttpErrorResponse): void {
    // TODO: Fix show error when silently crashing.
    this.ngZone.run(() => {
      if (
        error &&
        error.error &&
        error.error.errorCode !== SESSIONID_TIMEOUT &&
        error.error.errorCode !== SESSIONID_NOT_VALID
      ) {
        this.modalService.openModal(error.message || 'Something went wrong');
        this.logger.debug(error);
      }
    });
  }
}
