import { Component } from '@angular/core';
import { CustomIconService } from 'src/app/services/custom-icon.service';

/**
 * This component holds the top menu.
 */
@Component({
  selector: 'app-shared-components-top-menu',
  templateUrl: './top-menu.component.html',
  styleUrls: ['./top-menu.component.scss'],
})
export class TopMenuComponent {
  /**
   * Constructor function responsible for injecting the needed services.
   *
   * @param customIconService Reference to CustomIconService.
   */
  constructor(
    private readonly customIconService: CustomIconService
  ) {
    // Returns the icons used in UI.
    this.customIconService.getCustomIcons([
      'Favicon-svg',
      'icon-Profile',
      'profile_settings'
    ]);
  }
}
