// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { NgxLoggerLevel } from 'ngx-logger';

export const environment = {
  production: false,
  pspdfkitLicense:
    'o8U1iVB06oTsXPY2jKztqMVmA24tzSeKvZ-T4F6Px0Kd8QP_AjC92LKlL5GHlULmSn1toBBCuwPKAHJtoyeCS0Fa8WG3K6b-a_5nhmIU116uz1tzPaAfSlDWFpzig5OOaBHt_5U1vDmH_vEj_o4cvrSiT_1LBpzGLLqw7GmDFwQV56jhgngknxbcUSMCMBDMMnGTHb5J5WAL0N7LnnpnZ-cZ1UW616urOMnRQxGQYp9a9TIMlWQj8pW-hm5dSShogCGEWDyNrkua6mE3LycksDBnPU2xbCY2MgZp5tvXu9Sz9e1z-QFgplipSWe03eu0yyOCh_G863io3WvzG5Y8e7XYCjhUCgWkCUZLwdeMIxFRYPDJTK2QbV6O14Bh9ybis2Mzi7ueO1AD9aGh8ld17rne1btJFBPKM5wpYQYnq1jRWLBftrMvKCUZlMmR3fU6tXGiU36RdeNtEnLi1pYhU2edXQxZ0f4u3BLzL5_DhlfWf3mUxBPNDiN5IbWA8zdegm0_lYczQMVhCP7mw2ij5I_6GjEsZ2j25TIeKG7fn7WMr_m_YR1q9wxNEFkR87pZ1xQoHKqr8IWJBVdcPejC0TjGymLDYavw2iPMLSZ4CU4=',
  googleClientId:
    '398576325316-4hdoude6kpl1pekatea4h6gng6rqiovp.apps.googleusercontent.com',
  googleFilePickerAPI: 'AIzaSyBqfxqdc5atMEF004sw-8MdsgIqd6tWNwQ',
  azureAppID: 'f5365e1a-bffa-4210-a151-d0980c02d3fa',
  availableLanguages: ['en-GB', 'da-DK', 'nl-NL', 'nn-NO', 'sv-SE', 'nb-NO'],

  hmacSecret: 'lohjufbnhtrdbnsjvitywgcnf58u7653whfj58',
  logLevel: NgxLoggerLevel.DEBUG,
  location: 'http://localhost:4200/',
  googleImageSearchURL: 'https://www.google.com/search?tbm=isch&q=',
  awsLambdaTemplatesIdentityPoolId:
    'eu-west-1:d3f689f2-849d-4c1f-9c65-808d33833844',
  appRoutes: {
    enableText: true,
    enablePdf: true,
    enableEpub: true,
    enableTemplates: true,
    enableEducational: true,
    enableStatistics: true,
  },
  UrlEndpoints: {
    wordprediction: 'https://wordprediction-test.vitec-mv.com',
    ocr: 'https://ocr-test.intowords.com/api/ocr',
    voiceservice: 'https://voiceservice-test.vitec-mv.com',
    profiles: 'https://settings-test.vitec-mv.com',
    dictionary:
      'https://dictionary-test.intowords.com/dictservice/DictionaryService/jsonwsp',
    login: 'https://signon-test.vitec-mv.com/?returnUrl=',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
