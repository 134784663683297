/**
 * Interface that holds information about a text and a selected range.
 */
export interface WordMatcherResponse {
  text: string;
  node?: Node;
  range?: Range;
  start: number;
  end: number;
}

/**
 * Class that extends Document.
 */
export abstract class DocumentI extends Document {
  selection: string;
  body: DocumentBodyI;
}

/**
 * Text range interface.
 */
interface TextRangeI {
  text: string;
  moveToElementText(element: HTMLElement): void;
  setEndPoint(position: string, textRange: number): void;
}

/**
 * Document body interface.
 */
interface DocumentBodyI extends HTMLElement {
  createTextRange(): TextRangeI;
}

/**
 * Abstract caret helper class that holds common functionality.
 */
export abstract class CaretHelperI {
  /**
   * Get the pre bound of a word at a specific position.
   * Calculate the starting offset of word whereas the ending offset remains same as position.
   *
   * @param text The string that needs to be analyzed.
   * @param position The position from which to start searching for the word.
   *
   * @return The bound position as an object { start: number; end: number }.
   */
  getWordPreBoundsAtPosition(
    text: string,
    position: number
  ): { start: number; end: number } {
    const isSpace = (c) => /\s/.exec(c);
    let startPosition = position - 1;

    while (startPosition >= 0 && !isSpace(text[startPosition])) {
      startPosition -= 1;
    }
    startPosition = Math.max(0, startPosition + 1);
    return { start: startPosition, end: position };
  }

  /**
   * Get the bounds of a word at a specific position.
   * Calculate the starting and ending offset of word.
   *
   * @param text The string that needs to be analyzed.
   * @param position The position from which to start searching for the word.
   *
   * @return The bound position as an object { start: number; end: number }.
   */
  getWordBoundsAtPosition(
    text: string,
    position: number
  ): { start: number; end: number } {
    const isSpace = (c) => /\s/.exec(c);
    let endPosition = position;
    let startPosition = position - 1;

    while (startPosition >= 0 && !isSpace(text[startPosition])) {
      startPosition -= 1;
    }
    startPosition = Math.max(0, startPosition + 1);

    while (endPosition < text.length && !isSpace(text[endPosition])) {
      endPosition += 1;
    }
    endPosition = Math.max(position, endPosition);

    return { start: startPosition, end: endPosition };
  }

  /**
   * Get the bounds of a sentence at a specific position.
   *
   * @param text The string that needs to be analyzed.
   * @param position The position from which to start searching for the word.
   *
   * @return The bound position as an object { start: number; end: number }.
   */
  getSentenceBoundsAtPosition(
    text: string,
    position: number
  ): { start: number; end: number } {
    const isSpace = (c) => /\s/.exec(c);
    const isDot = (d) => /\./.exec(d);
    let startPos = position - 1;
    let end = position;

    while (startPos >= 0 && !isSpace(text[startPos])) {
      startPos -= 1;
    }
    startPos = Math.max(0, startPos + 1);

    while (end < text.length && !isDot(text[end])) {
      end += 1;
    }
    end = Math.max(startPos, end);

    return { start: startPos, end };
  }

  /**
   * Gets and returns the document and window object based on a HTML element. If the element exists in an iframe,
   * it will return the window and document object of the iframe.
   *
   * @param element The HTML element for which we want to get the document/window element.
   *
   * @return A {document, window} object with the DOM document/window objects from the elements window.
   */
  getWindowAndDocumentFromElement(
    element: HTMLElement
  ): { document: DocumentI; window: Window } {
    const document = element.ownerDocument || (element as any).document;
    const window = document.defaultView || document.parentWindow;
    return { document, window };
  }
}

/**
 * Caret helper class that handles HTML carets.
 */
export class HTMLCaretHelper extends CaretHelperI {
  /**
   * Get the first word at the caret position.
   *
   * @return An instance of WordMatcherResponse which encapsulates the first word at the caret position.
   */
  getWordAtCursor(element: HTMLElement): WordMatcherResponse {
    const { document, window } = this.getWindowAndDocumentFromElement(element);
    const pos = this.getCaretCharacterOffsetWithin(element);
    const node = window.getSelection().focusNode
      ? window.getSelection().focusNode
      : element.childNodes.length > 0
        ? element.childNodes[0]
        : element;
    const text = node.textContent;
    let wordPos = this.getWordPreBoundsAtPosition(text, pos);

    if (wordPos.start === wordPos.end) {
      if (wordPos.start > 0 && pos > 0) {
        // Try to get previous word.
        wordPos = this.getWordPreBoundsAtPosition(text, pos - 1);
      } else {
        // Can't find word.
        return { text: '', start: 0, end: 0, node };
      }
    }

    return {
      text: text.slice(wordPos.start, wordPos.end) as string,
      node,
      ...wordPos,
    };
  }

  /**
   * Returns the last 4 words based on the caret position.
   *
   * @return An instance of WordMatcherResponse which encapsulates the last 4 words based on the caret position.
   */
  getSentenceAtCursor(element: HTMLElement): WordMatcherResponse {
    const { document, window } = this.getWindowAndDocumentFromElement(element);
    const pos = this.getCaretCharacterOffsetWithin(element);
    const node = window.getSelection().focusNode
      ? window.getSelection().focusNode
      : element.childNodes.length > 0
        ? element.childNodes[0]
        : element;
    const text = node.textContent;
    const wordPos = this.getWordPreBoundsAtPosition(text, pos);
    const sentencePosition = { ...wordPos };
    // Get the context up to the last 3 words before the selected word.
    for (let i = 0; i < 3; i++) {
      if (sentencePosition.start > 1) {
        sentencePosition.start = this.getWordPreBoundsAtPosition(
          text,
          sentencePosition.start - 1
        ).start;
      } else {
        break;
      }
    }
    return {
      text: text.slice(sentencePosition.start, sentencePosition.end) as string,
      node,
      ...sentencePosition,
    };
  }

  /**
   * Returns the sub-string starting from caret position and ending on full stop.
   *
   * @param element HTMLElement on which the calculations are made on.
   *
   * @return An instance of WordMatcherResponse which encapsulates progressive sentence on the caret position.
   */
  getProgressiveSentenceAtCursor(element: HTMLElement): WordMatcherResponse {
    const { document, window } = this.getWindowAndDocumentFromElement(element);
    const pos = this.getCaretCharacterOffsetWithin(element);
    const node = window.getSelection().focusNode
      ? window.getSelection().focusNode
      : element.childNodes.length > 0
        ? element.childNodes[0]
        : element;
    const text = node.textContent;
    const sentencePosition = this.getSentenceBoundsAtPosition(text, pos);
    return {
      text: text.slice(sentencePosition.start, sentencePosition.end) as string,
      node,
      ...sentencePosition,
    };
  }

  /**
   * Returns the next text node from current node if exist.
   *
   * @param element HTMLElement on which the calculations are made on.
   * @param currentNode Current node of element on which the calculations are made on.
   *
   * @returns Next text node of element if exist and null otherwise.
   */
  getNextTextNode(element: HTMLElement, currentNode: Node): Node | null {
    while (currentNode) {
      if (currentNode.isEqualNode(element)) {
        break;
      } else if (currentNode.hasChildNodes()) {
        currentNode = currentNode.firstChild;
      } else if (currentNode.nextSibling) {
        currentNode = currentNode.nextSibling;
      } else if (currentNode.parentElement?.id !== element.id) {
        while (currentNode.parentElement?.id !== element.id && !currentNode.parentNode.nextSibling) {
          currentNode = currentNode.parentElement;
        }
        currentNode = currentNode.parentNode.nextSibling;
      }
      if (currentNode && currentNode.nodeType === 3) {
        break;
      }
    }
    return currentNode;
  }

  /**
   * Returns the last text node of given element.
   *
   * @param element HTMLElement on which the calculations are made on.
   * @param lastNode Last node of element on which the calculations are made on.
   *
   * @returns Last text node of element.
   */
  getLastTextNode(element: HTMLElement, lastNode: Node): Node {
    if (lastNode.nodeType === 3) {
      return lastNode;
    }
    while (lastNode.hasChildNodes()) {
      lastNode = lastNode.lastChild;
    }
    while (lastNode && lastNode.nodeType !== 3) {
      if (
        lastNode.isEqualNode(element) ||
        lastNode.isEqualNode(element.firstChild)
      ) {
        break;
      } else if (lastNode.hasChildNodes()) {
        lastNode = lastNode.lastChild;
      } else if (lastNode.previousSibling) {
        lastNode = lastNode.previousSibling;
      } else if (lastNode.parentElement?.id !== element.id) {
        lastNode = lastNode.parentNode.previousSibling;
      }
      if (lastNode && lastNode.nodeType === 3) {
        break;
      }
    }
    return lastNode;
  }

  /**
   * Returns the caret offset in a node.
   *
   * @param element HTMLElement on which the calculations are made on.
   *
   * @return The offset of the caret.
   */
  getCaretCharacterOffsetWithin(element: HTMLElement): number {
    const { document, window } = this.getWindowAndDocumentFromElement(element);
    let caretOffset = 0;
    let sel;
    if (typeof window.getSelection !== 'undefined') {
      sel = window.getSelection();
      if (sel.rangeCount > 0) {
        const range = window.getSelection().getRangeAt(0);
        const preCaretRange = range.cloneRange();
        preCaretRange.selectNodeContents(
          sel.focusNode ? sel.focusNode : element
        );
        preCaretRange.setEnd(range.endContainer, range.endOffset);
        caretOffset = preCaretRange.toString().length;
      }
    } else {
      sel = document.selection;
      if (sel && sel.type !== 'Control') {
        const textRange = sel.createRange();
        const preCaretTextRange = document.body.createTextRange();
        preCaretTextRange.moveToElementText(element);
        preCaretTextRange.setEndPoint('EndToEnd', textRange);
        caretOffset = preCaretTextRange.text.length;
      }
    }
    return caretOffset;
  }

  /**
   * Get the first word at the caret position.
   *
   * @param element HTMLElement on which the calculations are made on.
   *
   * @return An instance of WordMatcherResponse which encapsulates the first word at the caret position.
   */
  getWordAtSelection(element: HTMLElement): WordMatcherResponse {
    const { document, window } = this.getWindowAndDocumentFromElement(element);
    const text = element.textContent;
    const pos = this.getCaretCharacterOffsetWithin(element);
    const node =
      element.childNodes.length > 0
        ? element.childNodes[0]
        : window.getSelection().focusNode;
    let wordPos = this.getWordPreBoundsAtPosition(text, pos);
    if (wordPos.start === wordPos.end) {
      if (wordPos.start > 0 && pos > 0) {
        // Try to get previous word.
        wordPos = this.getWordPreBoundsAtPosition(text, pos - 1);
      } else {
        // Can't find word.
        return { text: '', start: 0, end: 0, node };
      }
    }
    return {
      text: text.slice(wordPos.start, wordPos.end) as string,
      node,
      ...wordPos,
    };
  }

  /**
   * Sets the caret at a specific position.
   *
   * @param element HTMLElement on which the calculations are made on.
   * @param node Node on which the calculations are made on.
   * @param position The position where to move the caret at.
   */
  setCaretAtPosition(element: HTMLElement, node: Node, position: number): void {
    this.setCaretRange(element, position, position, node);
  }

  /**
   * Creates selection on specified nodes in a HTMLElement.
   *
   * @param element HTMLElement on which the calculations are made on.
   * @param start The start of the range.
   * @param end The end of the range.
   * @param node Node on which the calculations are made on.
   * @param endNode Node on which selection ends if selection spans on multiple nodes.
   */
  setCaretRange(
    element: HTMLElement,
    start: number,
    end: number,
    node: Node,
    endNode?: Node
  ): void {
    const { document, window } = this.getWindowAndDocumentFromElement(element);
    // Set the focus.
    if ('focus' in element) {
      this.focus(element);
    }
    const sel = window.getSelection();
    // Prepare the range.
    const range = document.createRange();
    // Set the start/end range to the same index.
    range.setStart(node, start);
    range.setEnd(endNode ? endNode : node, end);
    // Remove any other ranges.
    sel.removeAllRanges();
    // Add the range.
    sel.addRange(range);
  }

  /**
   * Replace the current selection with a new string.
   *
   * @param element HTMLElement on which the calculations are made on.
   * @param value The new value.
   * @param selectionRange Represents the selection range in which the word is being replaced.
   */
  replaceSelection(element: HTMLElement, value: string, selectionRange?: { start: number, end: number }): void {
    const { document, window } = this.getWindowAndDocumentFromElement(element);
    const sel = window.getSelection();
    const selectedRange = this.getWordAtCursor(element);
    let range = document.createRange();

    // Delete the range that would be removed.
    range.setStart(sel.focusNode, selectionRange ? selectionRange.start : selectedRange.start);
    range.setEnd(sel.focusNode, selectionRange ? selectionRange.end : selectedRange.end);
    range.deleteContents();

    // Create a new node to add to the focus element.
    const lastNode = document.createTextNode('' + value + '');
    range.insertNode(lastNode);

    // Prepare the new range that includes t=just one node.
    range = range.cloneRange();
    range.setStartAfter(lastNode);
    range.collapse(true);
    sel.removeAllRanges();
    sel.addRange(range);

    // Normalize the dom element.
    element.normalize();
  }

  /**
   * Focus a specific HTMLElement.
   *
   * @param element HTMLElement to focus.
   */
  focus(element: HTMLElement): void {
    element.focus();
  }

  /**
   * Set a selection and replace it with a specific value.
   *
   * @param element HTMLElement on which the calculations are made on.
   * @param node Node on which the calculations are made on.
   * @param start The start of the range.
   * @param end The end of the range.
   * @param value The new value.
   */
  replaceAtSelection(
    element: HTMLElement,
    node: Node,
    start: number,
    end: number,
    value: string
  ): void {
    this.setCaretRange(element, start, end, node);
    this.replaceSelection(element, value);
  }

  /**
   * Calculate word bounds at caret position thn set a selection and replace it with a specific value.
   *
   * @param element HTMLElement on which the calculations are made on.
   * @param node Node on which the calculations are made on.
   * @param value The new value.
   * @param position The position of cursor.
   */
  replaceWordAtCaret(
    element: HTMLElement,
    node: Node,
    value: string,
    position?: number
  ): void {
    const { document, window } = this.getWindowAndDocumentFromElement(element);
    this.setCaretAtPosition(
      element,
      node,
      position === 0 || position ? position : window.getSelection().focusOffset
    );
    const pos = this.getCaretCharacterOffsetWithin(element);
    const text = node.textContent;
    const wordPosition = this.getWordBoundsAtPosition(text, pos);
    this.setCaretRange(element, wordPosition.start, wordPosition.end, node);
    this.replaceSelection(element, value, wordPosition);
  }

  /**
   * Get selected text with respect to owner document element.
   *
   * @param element HTMLElement on which the calculations are made on.
   *
   * @return An instance of SelectionResponse which encapsulates the selected word at the caret position.
   */
  getSelectedText(element: HTMLElement): WordMatcherResponse {
    const selectionRange = element.ownerDocument.getSelection().getRangeAt(0);
    const node = selectionRange.startContainer;
    const selectedText = element.ownerDocument.getSelection().toString();
    element.ownerDocument.getSelection().empty();
    if (selectionRange.startContainer === selectionRange.endContainer) {
      return {
        range: selectionRange,
        text: selectedText,
        node,
        start: selectionRange.startOffset,
        end: selectionRange.endOffset,
      };
    } else {
      return {
        text: node.textContent.slice(
          selectionRange.startOffset,
          node.textContent.length
        ),
        range: selectionRange,
        node: selectionRange.startContainer,
        start: selectionRange.startOffset,
        end: selectionRange.endOffset,
      };
    }
  }

  /**
   * Checks if text is selected and make sure text selection happens inside text holder.
   *
   * @return True if text is selected inside text holder and false otherwise.
   */
  isTextSelected(element: HTMLElement, textHolderID: string): boolean {
    let parentNode = element.ownerDocument.getSelection()?.focusNode;
    while (parentNode) {
      if (parentNode.parentElement?.id === textHolderID) {
        break;
      }
      parentNode = parentNode.parentNode;
    }
    if (parentNode && element.ownerDocument.getSelection().toString()) {
      return true;
    } else {
      return false;
    }
  }
}

/**
 * Same as HTMLCaretHelper except you can give it a HTMLElement in the constructor and it
 * do all the actions on that specific HTMLElement.
 */
export class HTMLWithElementCaretHelper {
  helper = new HTMLCaretHelper();

  get document(): Document {
    return this.element.ownerDocument;
  }
  constructor(private element: HTMLElement) { }

  /**
   * Sets the caret at a specific position.
   *
   * @param position The position where to move the caret at.
   * @param node Node on which the calculations are made on.
   */
  setCaretAtPosition(position: number, node: Node): void {
    this.helper.setCaretAtPosition(this.element, node, position);
  }

  /**
   * Creates a selection on a specific node in a HTMLElement.
   *
   * @param start The start of the range.
   * @param end The end of the range.
   * @param node Node on which the calculations are made on.
   * @param endNode Node on which selection ends if selection spans on multiple nodes.
   */
  setCaretRange(start: number, end: number, node: Node, endNode?: Node): void {
    this.helper.setCaretRange(this.element, start, end, node, endNode);
  }

  /**
   * Replace the current selection with a new string.
   *
   * @param value The new value.
   */
  replaceSelection(value: string): void {
    this.helper.replaceSelection(this.element, value);
  }

  /**
   * Focus a specific HTMLElement.
   */
  focus(): void {
    this.helper.focus(this.element);
  }

  /**
   * Set a selection and replace it with a specific value.
   *
   * @param node Node on which the calculations are made on.
   * @param start The start of the range.
   * @param end The end of the range.
   * @param value The new value.
   */
  replaceAtSelection(
    node: Node,
    start: number,
    end: number,
    value: string
  ): void {
    this.helper.replaceAtSelection(this.element, node, start, end, value);
  }

  /**
   * Replace the word at caret position with new value.
   *
   * @param node Node on which the calculations are made on.
   * @param value The new value.
   * @param position The position of cursor.
   */
  replaceWordAtCaret(node: Node, value: string, position?: number): void {
    this.helper.replaceWordAtCaret(this.element, node, value, position);
  }

  /**
   * Get the first word at the caret position.
   *
   * @return An instance of WordMatcherResponse which encapsulates the first word at the caret position.
   */
  getWordAtCursor(): WordMatcherResponse {
    return this.helper.getWordAtCursor(this.element);
  }

  /**
   * Returns the last 4 words based on the caret position.
   *
   * @return An instance of WordMatcherResponse which encapsulates the last 4 words based on the caret position.
   */
  getSentenceAtCursor(): WordMatcherResponse {
    return this.helper.getSentenceAtCursor(this.element);
  }

  /**
   * Returns the sub-string starting from caret position and ending on full stop.
   *
   * @return An instance of WordMatcherResponse which encapsulates progressive sentence on the caret position.
   */
  getProgressiveSentenceAtCursor(): WordMatcherResponse {
    return this.helper.getProgressiveSentenceAtCursor(this.element);
  }

  /**
   * Returns the next text node from current node if exist.
   *
   * @param currentNode Current node of element on which the calculations are made on.
   *
   * @returns Next text node of element if exist and null otherwise.
   */
  getNextTextNode(currentNode: Node): Node | null {
    return this.helper.getNextTextNode(this.element, currentNode);
  }

  /**
   * Returns the last text node of given element.
   *
   * @param lastNode Last node of element on which the calculations are made on.
   *
   * @returns Last text node of element.
   */
  getLastTextNode(lastNode: Node): Node {
    return this.helper.getLastTextNode(this.element, lastNode);
  }

  /**
   * Highlight text using offsets of text selection.
   *
   * @param start Indicate starting offset to start highlight.
   * @param end Indicate ending offset to end highlight.
   * @param node Node on which the calculations are made on.
   */
  highlightText(
    start: number,
    end: number,
    node: Node,
    element: HTMLElement = this.element
  ): void {
    this.helper.setCaretRange(element, start, end, node);
  }

  /**
   * Get selected text with respect to owner document element.
   *
   * @param element HTMLElement on which the calculations are made on.
   *
   * @return An instance of SelectionResponse which encapsulates the selected word at the caret position.
   */
  getSelectedText(element: HTMLElement = this.element): WordMatcherResponse {
    return this.helper.getSelectedText(element);
  }

  /**
   * Checks if text is selected and make sure text selection happens inside text holder.
   *
   * @return True if text is selected inside text holder and false otherwise.
   */
  isTextSelected(
    textHolderID: string,
    element: HTMLElement = this.element
  ): boolean {
    return this.helper.isTextSelected(element, textHolderID);
  }
}

/**
 * Caret helper for HTMLInputElement.
 */
export class InputCaretHelper extends CaretHelperI {
  /**
   * Set local instances.
   *
   * @param inputElement HTMLInputElement on witch the actions will be applied to.
   * @param selectionStart Initial start selection.
   * @param selectionEnd Initial end selection.
   */
  constructor(
    private inputElement: HTMLInputElement,
    private selectionStart: number,
    private selectionEnd: number
  ) {
    super();
  }

  /**
   * Set a selection at a specified position.
   *
   * @param start The start of the selection.
   * @param end The end of the selection.
   */
  setSelection(start: number, end: number): void {
    this.inputElement.focus();
    this.inputElement.setSelectionRange(start, end);
  }

  /**
   * Get the first word at the caret position.
   *
   * @return An instance of WordMatcherResponse which encapsulates the first word at the caret position.
   */
  getSelectedWord(): WordMatcherResponse {
    let selection = this.getWordPreBoundsAtPosition(
      this.inputElement.value,
      this.selectionStart
    );

    // Try to get previous word.
    if (selection.start === selection.end) {
      selection = this.getWordPreBoundsAtPosition(
        this.inputElement.value,
        this.selectionStart - 1
      );
    }

    // TODO: add size check.
    return {
      text: this.inputElement.value.substring(selection.start, selection.end),
      start: selection.start,
      end: selection.end,
      node: this.inputElement as Node,
    };
  }

  /**
   * Returns the last 4 words based on the caret position.
   *
   * @return An instance of WordMatcherResponse which encapsulates the last 4 words based on the caret position.
   */
  getSelectedSentence(): WordMatcherResponse {
    const selection = this.getWordPreBoundsAtPosition(
      this.inputElement.value,
      this.selectionStart
    );
    for (let i = 0; i < 3; i++) {
      if (selection.start > 1) {
        const newSelection = this.getWordPreBoundsAtPosition(
          this.inputElement.value,
          selection.start - 1
        );
        selection.start = newSelection.start;
      }
    }
    // TODO: add size check.
    return {
      text: this.inputElement.value.substring(selection.start, selection.end),
      start: selection.start,
      end: selection.end,
    };
  }

  /**
   * Set a selection and replace it with a specific value.
   *
   * @param text The new value.
   * @param start The start of the range.
   * @param end The end of the range.
   *
   * @returns the new value of the entire input.
   */
  replaceAtSelection(text: string, start: number, end: number): string {
    return (this.inputElement.value =
      this.inputElement.value.substring(0, start) +
      text +
      this.inputElement.value.substring(end, this.inputElement.value.length));
  }

  /**
   * Sets the focus to the input and preselects text at specified position.
   *
   * @param start Start position.
   * @param end End position.
   */
  select(start: number, end: number): void {
    this.inputElement.focus();
    this.inputElement.setSelectionRange(start, end);
  }
}
