import { Component } from '@angular/core';
import { profiles } from 'src/app/common/profiles';
import { ProfileInfoExtendedDTOI } from 'src/app/dto/profile/profile-dto';
import { CustomIconService } from 'src/app/services/custom-icon.service';
import { environment } from 'src/environments/environment';

/**
 * This component is used as a placeholder for the app.
 */
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent {
  // Dummy data for profiles.
  profiles: ProfileInfoExtendedDTOI[] = profiles;
  // Stores the avialable languages.
  languages = environment.availableLanguages;

  /**
   * Constructor function responsible for injecting the needed services.
   *
   * @param customIconService Reference to CustomIconService.
   */
  constructor(
    private readonly customIconService: CustomIconService
  ) {
    // Returns the icons used in UI.
    this.customIconService.getCustomIcons([
      'Favicon-svg',
      'icon-Profile',
      'profile_settings',
      'radio_checked',
      'radio_unchecked'
    ]);
    const profileFlags: string[] = [];
    for (const prof of this.profiles) {
      profileFlags.push(prof.profile.language);
    }
    this.getCountryFlags(profileFlags);
  }

  /**
   * Gets the flag for each country from profiles array.
   */
  getCountryFlags(profileFlags: string[]): void {
    this.customIconService.getCustomIcons(profileFlags, 'flags');
  }

  /**
   * Select the profile that will be edited/set as current.
   *
   * @param profile Represents the selected profile.
   */
  selectProfile(profile: ProfileInfoExtendedDTOI): void {
    const findSelectedProfile = this.profiles.find(el => el.isCurrent);
    if (findSelectedProfile && findSelectedProfile.profile.language !== profile.profile.language) {
      findSelectedProfile.isCurrent = !findSelectedProfile.isCurrent;
      profile.isCurrent = !profile.isCurrent;
    }

  }

}
