import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { WarningDataInterface } from 'src/app/services/warning-modal.service';

/**
 * This component is used to handle the warning messsage.
 */
@Component({
  selector: 'app-shared-modals-warning-modal',
  templateUrl: './warning-modal.component.html',
  styleUrls: ['./warning-modal.component.scss'],
})
export class WarningModalComponent {
  /**
   * Constructor function responsible for injecting the needed services.
   *
   * @param dialogRef Is an instance of MatDialogRef.
   */
  constructor(
    public dialogRef: MatDialogRef<WarningModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: WarningDataInterface
  ) {}

  /**
   * Closes the modal.
   *
   * @param data Represents the result value.
   */
  save(data?: boolean): void {
    this.dialogRef.close(data);
  }
}
