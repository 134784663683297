<div class="dialog-hld">
  <div mat-dialog-title class="mat-dialog-title">
    <div class="title" fxLayout="row" fxLayoutAlign="start center">
      <div class="icon-hld">
        <mat-icon svgIcon="error" class="app-icon-hover"></mat-icon>
      </div>
      <div class="title-hld">Error</div>
    </div>
  </div>

  <div mat-dialog-content class="content-hld">
    <div>{{data}}</div>
  </div>

  <div mat-dialog-actions fxLayout="row" fxLayoutAlign="end center" class="modal-footer">
    <div (click)="cancel()" class="action-btn">got it</div>
  </div>
</div>
