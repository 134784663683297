import { ProfileInfoExtendedDTOI } from '../dto/profile/profile-dto';

export const profiles: ProfileInfoExtendedDTOI[] = [
  {
    profile: {
      id: 'f0f94a09-2d90-444e-b26e-76e4648281e1',
      name: 'Stine',
      language: 'da-DK',
      application: 'intowords',
      settings: {
        substitutionRules: [],
        topicDictionaryIds: [
          12,
          11,
          13
        ],
        dictionaryType: 'Extended',
        userLanguage: 'da',
        dictionaryLanguage: 'da',
        numberOfWords: 10,
        numberOfTopicWords: 10,
        wildCards: true,
        useContext: true,
        predictNextWord: true,
        showHomophones: true,
        showSpelling: true,
        minWordLength: 1,
        maxWordLength: 40,
        minPrefixLength: 1,
        dictation: false,
        minSpellingLength: 3,
        voiceSpeed: 1,
        voiceId: 'mv_da_acl',
        readForward: true,
        highlightWord: true,
        writeLetterSound: false,
        writeLetterName: false,
        writeWord: false,
        writeSentence: false,
        myTerms: []
      }
    },
    isCurrent: true
  },
  {
    profile: {
      id: '03ba0801-8ebf-4a9e-938d-0a5ed862fc21',
      name: 'Felix',
      language: 'de-DE',
      application: 'intowords',
      settings: {
        dictionaryType: 'Extended',
        userLanguage: 'de',
        dictionaryLanguage: 'de',
        numberOfWords: 10,
        numberOfTopicWords: 10,
        wildCards: true,
        useContext: true,
        predictNextWord: true,
        showHomophones: true,
        showSpelling: true,
        minWordLength: 1,
        maxWordLength: 40,
        minPrefixLength: 1,
        dictation: false,
        minSpellingLength: 3,
        voiceSpeed: 1,
        voiceId: 'mv_de_bits4',
        readForward: true,
        highlightWord: true,
        writeLetterSound: false,
        writeLetterName: false,
        writeWord: false,
        writeSentence: false,
        substitutionRules: [],
        topicDictionaryIds: [],
        myTerms: []
      }
    },
    isCurrent: false
  },
  {
    profile: {
      id: 'a350a421-3a6f-4c12-9787-7714f1fedef3',
      name: 'Claire',
      language: 'nl-NL',
      application: 'intowords',
      settings: {
        dictionaryType: 'Extended',
        userLanguage: 'nl',
        dictionaryLanguage: 'nl',
        numberOfWords: 10,
        numberOfTopicWords: 10,
        wildCards: true,
        useContext: true,
        predictNextWord: true,
        showHomophones: true,
        showSpelling: true,
        minWordLength: 1,
        maxWordLength: 40,
        minPrefixLength: 1,
        dictation: false,
        minSpellingLength: 3,
        voiceSpeed: 1,
        voiceId: 'nve_dun_claire',
        readForward: true,
        highlightWord: true,
        writeLetterSound: false,
        writeLetterName: false,
        writeWord: false,
        writeSentence: false,
        substitutionRules: [],
        topicDictionaryIds: [],
        myTerms: []
      }
    },
    isCurrent: false
  },
  {
    profile: {
      id: 'b3de020d-3a0a-4fb3-b00e-691a55523a0e',
      name: 'Samantha',
      language: 'en-US',
      application: 'intowords',
      settings: {
        dictionaryType: 'Extended',
        userLanguage: 'da',
        dictionaryLanguage: 'da',
        numberOfWords: 10,
        numberOfTopicWords: 10,
        wildCards: true,
        useContext: true,
        predictNextWord: true,
        showHomophones: true,
        showSpelling: true,
        minWordLength: 1,
        maxWordLength: 40,
        minPrefixLength: 1,
        dictation: false,
        minSpellingLength: 3,
        voiceSpeed: 1,
        voiceId: 'nve_dad_sara',
        readForward: true,
        highlightWord: true,
        writeLetterSound: false,
        writeLetterName: false,
        writeWord: false,
        writeSentence: false,
        substitutionRules: [],
        topicDictionaryIds: [],
        myTerms: []
      }
    },
    isCurrent: false
  },
  {
    profile: {
      id: 'b697b9a2-4444-4140-bba2-8db19264de4e',
      name: 'Tor',
      language: 'nb-NO',
      application: 'intowords',
      settings: {
        dictionaryType: 'Extended',
        userLanguage: 'nb',
        dictionaryLanguage: 'nb',
        numberOfWords: 10,
        numberOfTopicWords: 10,
        wildCards: true,
        useContext: true,
        predictNextWord: true,
        showHomophones: true,
        showSpelling: true,
        minWordLength: 1,
        maxWordLength: 40,
        minPrefixLength: 1,
        dictation: false,
        minSpellingLength: 3,
        voiceSpeed: 1,
        voiceId: 'mv_nb_hk',
        readForward: true,
        highlightWord: true,
        writeLetterSound: false,
        writeLetterName: false,
        writeWord: false,
        writeSentence: false,
        substitutionRules: [],
        topicDictionaryIds: [],
        myTerms: []
      }
    },
    isCurrent: false
  },
  {
    profile: {
      id: 'e2abd303-dd6a-49a8-b97d-c174f4ae82b4',
      name: 'Claire',
      language: 'en-GB',
      application: 'intowords',
      settings: {
        dictionaryType: 'Extended',
        userLanguage: 'en',
        dictionaryLanguage: 'en',
        numberOfWords: 10,
        numberOfTopicWords: 10,
        wildCards: true,
        useContext: true,
        predictNextWord: true,
        showHomophones: true,
        showSpelling: true,
        minWordLength: 1,
        maxWordLength: 40,
        minPrefixLength: 1,
        dictation: false,
        minSpellingLength: 3,
        voiceSpeed: 1,
        voiceId: 'mv_en_crb',
        readForward: true,
        highlightWord: true,
        writeLetterSound: false,
        writeLetterName: false,
        writeWord: false,
        writeSentence: false,
        substitutionRules: [],
        topicDictionaryIds: [],
        myTerms: []
      }
    },
    isCurrent: false
  },

];
