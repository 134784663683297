import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ToolType } from '../models/common/types';

// TODO: add shortcut actions for each tool
@Injectable()
export class ToolsService {
  /**
   * The subject used to controls the service communication.
   */
  private toolSource = new Subject<ToolType>();

  /**
   * Observable instance of the source object.
   */
  private toolActionObservable = this.toolSource.asObservable();

  /**
   * Getter function for private toolAction Observable.
   *
   * TODO: change name to toolAction
   *
   * @return Observable<ToolType> That listens for any tool actions.
   */
  public get toolText(): Observable<ToolType> {
    return this.toolActionObservable;
  }

  /**
   * Calls the source of the observable and cascades the action.
   *
   * @param toolAction Is the action object you want to cascade.
   */
  selectedTool(toolAction: ToolType): void {
    this.toolSource.next(toolAction);
  }
}
