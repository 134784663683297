import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EncodingService } from './encoding.service';
import { IntowordsBaseService } from './intowords-base.service';
import { CookieService } from 'ngx-cookie-service';
import { map } from 'rxjs/operators';

// Topic dictionary interface.
export interface TopicDictionary {
  id: number;
  title: string;
  publisher: string;
  language: string;
  imageLink: string;
}

// Sub group interface.
export interface SubGroup {
  name: string;
  topicDictionaries: Array<TopicDictionary>;
}

// Expected response format from the topic dictionaries service.
export interface TopicDictionariesResult {
  name: string;
  subGroups: Array<SubGroup>;
}

/**
 * Service responsible of working with intowords topic dictionaries api.
 */
@Injectable()
export class IntowordsTopicDictionariesService extends IntowordsBaseService<
  TopicDictionariesResult[]
> {
  private baseUrl: string;

  /**
   * @param httpService Performs HTTP requests.
   * @param encoding Service responsible for encoding hmac data for intowords requests.
   * @param cookieService Service responsible for managing cookies.
   */
  constructor(
    protected readonly httpService: HttpClient,
    protected readonly encoding: EncodingService,
    protected readonly cookieService: CookieService
  ) {
    super(httpService, encoding, cookieService);
    this.baseUrl = this.UrlEndpoints.wordprediction;
  }

  /**
   * Retrieve all the topic dictionaries that exist for a language.
   *
   * @param language Represents the language of resulting topic dictionaries.
   */
  getTopicDictionaries(
    language: string
  ): Observable<TopicDictionariesResult[]> {
    return this.get<TopicDictionariesResult[]>(
      `${this.baseUrl}/v${this.API_VERSION}/TopicDictionaries?language=${language}`,
      false
    ).pipe(
      map((response) => {
        response.forEach((term) => {
          term.subGroups.forEach((subGroup) => {
            subGroup.topicDictionaries.forEach((topic) => {
              topic.imageLink = this.baseUrl + '/' + topic.imageLink;
            });
          });
        });
        return response;
      })
    );
  }
}
