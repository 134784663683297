import { Injectable } from '@angular/core';
import { fromEvent, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { READING_IDENTIFIER } from '../models/common/types';
import { Pattern } from '../modules/shared/regex.pattern';

/**
 * This service is used to handle the logic for the text editor.
 */
@Injectable()
export class TextEventsService {
  /**
   * The subject used to controls the service communication.
   */
  private wordCountSource = new Subject<number>();
  /**
   * Observable instance of the source object.
   */
  private wordCountActionObservable = this.wordCountSource.asObservable();

  /**
   * Getter function for private printAction Observable.
   *
   * @return Observable<boolean> That listens for any actions.
   */
  public get wordCountAction(): Observable<number> {
    return this.wordCountActionObservable;
  }

  /**
   * Calls the source of the observable and cascades the action.
   *
   * @param wordsNbr Is the action object you want to cascade.
   */
  wordsCounted(wordsNbr: number): void {
    this.wordCountSource.next(wordsNbr);
  }

  /**
   * Keyboard events.
   *
   * @param document Represents the HTML element.
   * @param eventType Represents the mouse type event.
   *
   * @return An Observable of string.
   */
  keyboardEvent(
    document: HTMLInputElement,
    eventType: 'keyup' | 'keydown'
  ): Observable<string> {
    return fromEvent(document, eventType).pipe(
      map((event: KeyboardEvent) => event.key)
    );
  }

  /**
   * Mouse events.
   *
   * @param document Represents the HTML element.
   * @param eventType Represents the mouse type event.
   *
   * @return An Observable of string.
   */
  mouseEvent(
    document: HTMLInputElement,
    eventType: 'mouseup' | 'mousedown'
  ): Observable<MouseEvent> {
    return fromEvent(document, eventType).pipe(
      map((event: MouseEvent) => event)
    );
  }

  /**
   * Word counter for text editor.
   *
   * @param document Represents the HTML element.
   *
   * @return The number of counted words.
   */
  wordCounter(document: HTMLInputElement): number {
    // Regex expresion that checks for alpha numeric characters.
    const regExp = /[a-zA-Z0-9]+([-_][a-zA-Z0-9]+)*/gm;
    const innerTxt = document.innerText;
    return innerTxt && innerTxt.length
      ? innerTxt.match(regExp)
        ? innerTxt.match(regExp).length
        : 0
      : 0;
  }

  /**
   * Identifies and returns the reading type based on inserted character.
   *
   * @param character Represents the input letter.
   *
   * @returns The reading type.
   */
  getReadingType(character: string): string {
    if (character.match(Pattern.sentenceReadRegex)) {
      return READING_IDENTIFIER.READ_SENTENCE;
    } else if (
      character.match(Pattern.wordReadRegex) ||
      character.match(Pattern.whiteSpaceRegex)
    ) {
      return READING_IDENTIFIER.READ_WORD;
    } else {
      return READING_IDENTIFIER.READ_CHARACTER;
    }
  }
}
