import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ProfileInfoExtendedDTOI } from 'src/app/dto/profile/profile-dto';

/**
 * This component is used to hold the profile list.
 */
@Component({
  selector: 'app-shared-common-settings-profile-language-list',
  templateUrl: './language-list.component.html',
  styleUrls: ['./language-list.component.scss'],
})
export class SettingsProfileLanguageListComponent {
  @Input() profiles: ProfileInfoExtendedDTOI[];

  /**
   * Allows new data to flow from the child to the parent.
   * Everytime when the user selects a profile, the parent get's updated with the new selected profile
   * and then sends the new profile to it's children.
   */
  @Output() profileToEdit = new EventEmitter<ProfileInfoExtendedDTOI>();

  /**
   * Select the profile that will be edited/set as current.
   *
   * @param profile Represents the selected profile.
   */
  selectProfile(profile: ProfileInfoExtendedDTOI): void {
    this.profileToEdit.emit(profile);
  }
}
