import { Component, Input, OnDestroy } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { MAX_SPEED, MIN_SPEED } from 'src/app/common/constants';
import { ProfileInfoExtendedDTOI } from 'src/app/dto/profile/profile-dto';
import { CustomIconService } from 'src/app/services/custom-icon.service';
import { ProfileValidationService } from 'src/app/services/profile-validation.service';
import { ProfileService } from 'src/app/services/profile.service';

/**
 * This component holds the logic for speed settings.
 */
@Component({
  selector: 'app-shared-common-settings-speech-speed',
  templateUrl: './speed.component.html',
  styleUrls: ['./speed.component.scss'],
})
export class SettingsSpeechSpeedComponent implements OnDestroy {
  /**
   * Setter and getter for the current profile. Used to update the input data after it was changed.
   */
  @Input()
  set currentProfile(profile: ProfileInfoExtendedDTOI) {
    this.currentProfileValues = profile;
    this.readingSpeed = profile.profile.settings.voiceSpeed;
  }
  get currentProfile(): ProfileInfoExtendedDTOI {
    return this.currentProfileValues;
  }
  // Current profile holder.
  private currentProfileValues: ProfileInfoExtendedDTOI;

  // Holds reading speed of active profile.
  readingSpeed: number;
  // Represents the minimum speed allowed.
  minSpeed = MIN_SPEED;
  // Represents the maximum speed allowed.
  maxSpeed = MAX_SPEED;

  // Stores the subscribers until they're destroyed.
  private readonly destroyed = new ReplaySubject<never>();

  /**
   * Constructor function responsible for injecting the needed services.
   *
   * @param customIconService Reference to CustomIconService.
   * @param profileService Reference to ProfileService.
   * @param profileValidationService Reference to ProfileValidationService.
   */
  constructor(
    private readonly customIconService: CustomIconService,
    private readonly profileService: ProfileService,
    private readonly profileValidationService: ProfileValidationService
  ) {
    // Returns the speed icon (used in UI).
    this.customIconService.getCustomIcons([
      'turtle',
      'rabbit',
      'speed_popup_box',
    ]);
  }

  /**
   * Unsubscribe Observables and detach event handlers to avoid memory leaks.
   */
  ngOnDestroy(): void {
    this.destroyed.next();
  }

  /**
   * Updates reading speed of active profile.
   */
  updateReadingSpeed(): void {
    this.readingSpeed = this.profileValidationService.isSpeedValid(
      this.readingSpeed
    );
    this.currentProfile.profile.settings.voiceSpeed = this.readingSpeed;
  }
}
