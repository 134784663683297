import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReplaySubject } from 'rxjs';
import { voices } from 'src/app/common/voices';
import { CreateProfileBodyI, CreateProfileInputDataI } from 'src/app/dto/profile/profile-dto';
import { CustomIconService } from 'src/app/services/custom-icon.service';
import { VoiceResult } from 'src/app/services/intowords/intowords-voice.service';
import { ProfileValidationService } from 'src/app/services/profile-validation.service';
import { ProfileService, SortedVoicesInterface } from 'src/app/services/profile.service';

/**
 * This component holds the logic for creating a new profile.
 */
@Component({
  selector: 'app-shared-common-settings-modals-profile-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss'],
})
export class SettingsModalsProfileCreateComponent implements OnInit, OnDestroy {
  // Represents the profile name.
  profileName = '';
  // Error to be shown to the user.
  error = '';
  // Stores the name validation.
  validName = false;
  // Stores the subscribers until they're destroyed.
  private readonly destroyed = new ReplaySubject<never>();
  // Available languages for profile.
  availableProfileLanguages: SortedVoicesInterface[] = [];
  // Stores the selected language.
  selectedLanguage: SortedVoicesInterface;
  // Stores the selected voice.
  selectedVoice: VoiceResult;

  /**
   * Constructor function responsible for injecting the needed services.
   *
   * @param customIconService Reference to CustomIconService.
   * @param profileService Reference to ProfileService.
   * @param profileValidationService Reference to ProfileValidationService.
   * @param dialogRef Reference to MatDialogRef.
   * @param data Is an instance of input data.
   */
  constructor(
    private readonly customIconService: CustomIconService,
    private readonly profileService: ProfileService,
    private readonly profileValidationService: ProfileValidationService,
    public dialogRef: MatDialogRef<SettingsModalsProfileCreateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CreateProfileInputDataI
  ) {
    if (this.data && this.data.currentProfile) {
      this.selectedLanguage = {
        language: this.data.currentProfile.profile.language,
        voiceId: this.data.currentProfile.profile.settings.voiceId,
        voices: []
      };
    }
  }

  /**
   * A lifecycle hook that is called after Angular has initialized all data-bound properties of a directive.
   */
  ngOnInit(): void {
    this.getLanguageVoices();
  }

  /**
   * Unsubscribe Observables and detach event handlers to avoid memory leaks.
   */
  ngOnDestroy(): void {
    this.destroyed.next();
  }

  /**
   * Get all available languages with voices data.
   */
  getLanguageVoices(): void {
    this.availableProfileLanguages = this.profileService.sortVoicesByLanguages(
      voices.voices
    );
    if (this.availableProfileLanguages) {
      const currentLang = this.availableProfileLanguages.find(el => el.language === this.data.currentProfile.profile.language);
      if (currentLang) {
        this.selectedLanguage.voices = currentLang.voices;
        this.selectedVoice = currentLang.voices.find(el =>
          el.id === this.data.currentProfile.profile.settings.voiceId);

        const profileFlags: string[] = [];
        for (const prof of this.availableProfileLanguages) {
          profileFlags.push(prof.language);
        }
        this.getCountryFlags(profileFlags);
      }
    }
  }

  /**
   * Gets the flag for each country from profiles array.
   */
  getCountryFlags(profileFlags: string[]): void {
    this.customIconService.getCustomIcons(profileFlags, 'flags');
  }

  /**
   * Select profile language.
   *
   * @param language Represents the profile language.
   */
  selectLanguage(language: SortedVoicesInterface): void {
    this.selectedLanguage = language;
    this.selectedVoice = this.selectedLanguage.voices.find(el => el.id === this.selectedLanguage.voiceId);
  }

  /**
   * Select profile voice.
   *
   * @param voice Represents the selected voice.
   */
  selectVoice(voice: VoiceResult): void {
    this.selectedVoice = voice;
  }

  /**
   * Closes the modal.
   */
  close(): void {
    this.dialogRef.close();
  }

  /**
   * Save method.
   */
  save(): void {
    if (this.validName) {
      const profileData: CreateProfileBodyI = {
        language: this.selectedLanguage.language,
        name: this.profileName,
        voiceId: this.selectedVoice.id,
      };
      this.dialogRef.close();
    }
  }

  /**
   * Validates the user input.
   */
  isValid(): void {
    this.validName = true;
    this.error = this.profileValidationService.isNameValid(
      this.data.profiles,
      this.profileName
    );
    if (this.error && this.error.length) {
      this.validName = false;
    }
  }
}
