import {
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { SuggestionsTest } from 'src/app/common/suggestions';
import { WordPredictionResult } from 'src/app/services/intowords/intowords-suggestions.service';

/**
 * Prediction inteface.
 */
export interface PredictionInstance {
  word: string;
  key: string;
  type: 'terms' | 'regular';
}

/**
 * This component holds the suggestions data.
 */
@Component({
  selector: 'app-settings-suggesstions-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class SuggestionsListComponent implements OnInit, OnDestroy {
  // Predictions array.
  predictions: PredictionInstance[] = [];
  // Original predictions array.
  originalPredictions: WordPredictionResult[] = [];
  // Stores the subscribers until they're destroyed.
  private readonly destroyed = new ReplaySubject<never>();
  // Stores the page value.
  page = 1;
  // Maximum items per page.
  maxItemsPerPage = 10;
  // Stores terms state.
  termsState = true;

  /**
   * Constructor function responsible for injecting the needed services.
   */
  constructor() {
    this.originalPredictions = SuggestionsTest;
    this.sortPredictions(SuggestionsTest);
    this.setPage(1);
  }

  /**
   * A lifecycle hook that is called after Angular has initialized
   * all data-bound properties of a directive.
   */
  ngOnInit(): void { }

  /**
   * Unsubscribe Observables and detach event handlers to avoid memory leaks.
   */
  ngOnDestroy(): void {
    this.destroyed.next();
  }

  /**
   * Maps predictions array (WordPredictionResult) into a new array (PredictionInstance).
   *
   * @param predictions Represents the predictions array.
   */
  mappedPredictions(predictions: WordPredictionResult[]): void {
    this.predictions = [];
    const slicedArray = [];
    const chunkedArr = [];
    let index = 0;
    while (index < predictions.length) {
      slicedArray[index] = predictions
        .slice(index, this.maxItemsPerPage + index)
        .map((el, i) => {
          return {
            word: el.word,
            key: 'ctrl+' + (i === 9 ? 0 : i + 1),
            type: el.origin === 'Topics' ? 'terms' : 'regular',
          };
        });
      chunkedArr.push(slicedArray[index]);
      index += this.maxItemsPerPage;
    }
    chunkedArr.forEach((elem) => {
      this.predictions.push(...elem);
    });
  }

  /**
   * Set page number.
   *
   * @param page Represents the number of the page.
   */
  setPage(page: number): void {
    this.page = page;
  }

  /**
   * Close the prediction box.
   */
  closePredictionBox(): void { }


  /**
   * Call selectPrediction on wordPredictionService if the provided index is valid.
   *
   * @param index The index of the selected prediction.
   */
  selectPrediction(index: number): void {
    if (this.predictions.length > index) {
      const selectedPrediction = this.originalPredictions.find(
        (originalPrediction) =>
          originalPrediction.word ===
          this.predictions[this.maxItemsPerPage * (this.page - 1) + (index - 1)]
            .word
      );
    }
  }

  /**
   * Checks if the key value is in range[0-9].
   *
   * @param key Represents the key value from the event.
   */
  checkKeyRange(key: string): boolean {
    if (Number(key) >= 0 && Number(key) <= 9) {
      return true;
    }
    return false;
  }

  /**
   * Insert selected prediction.
   *
   * @param selected Represents the selected prediction.
   */
  insertPrediction(selected: string): void {

  }

  /**
   * Sorts prediction with respect to terms state and maximum items per page.
   *
   * @param predictions Represents the predictions array.
   */
  sortPredictions(predictions: WordPredictionResult[]): void {
    const entryTypeCount = this.maxItemsPerPage / 2;
    let sortedPredictions: WordPredictionResult[] = [];
    const regularPredictions = predictions.filter(
      (prediction) => prediction.origin === 'Normal'
    );
    if (!this.termsState) {
      sortedPredictions = regularPredictions;
    } else {
      const entriesPerPage = Math.trunc(entryTypeCount);
      const termsPredictions = predictions.filter(
        (prediction) => prediction.origin === 'Topics'
      );
      const maxPredictions = regularPredictions.length >= termsPredictions.length ? regularPredictions : termsPredictions;
      const iterations =
        maxPredictions.length % entryTypeCount === 0
          ? maxPredictions.length / entryTypeCount
          : maxPredictions.length / entryTypeCount + 1;
      for (let counter = 1; counter <= iterations; counter++) {
        const regularEntries = regularPredictions.splice(
          0,
          regularPredictions.length >= entriesPerPage
            ? entriesPerPage
            : regularPredictions.length
        );
        const termsEntries = termsPredictions.splice(
          0,
          termsPredictions.length >= entriesPerPage
            ? entriesPerPage
            : termsPredictions.length
        );
        while (regularEntries.length < entriesPerPage) {
          regularEntries.push({ word: '', origin: 'Normal', spelling: '' });
        }
        while (termsEntries.length < entriesPerPage) {
          termsEntries.push({ word: '', origin: 'Topics', spelling: '' });
        }
        for (const entry of regularEntries) {
          sortedPredictions.push(entry);
        }
        for (const entry of termsEntries) {
          sortedPredictions.push(entry);
        }
      }
    }
    this.mappedPredictions(sortedPredictions);
  }
}
