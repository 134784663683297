import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CreateProfileInputDataI } from 'src/app/dto/profile/profile-dto';
import { ProfileValidationService } from 'src/app/services/profile-validation.service';

/**
 * This component holds the logic for renaming the profile.
 */
@Component({
  selector: 'app-shared-common-settings-modals-profile-rename',
  templateUrl: './rename.component.html',
  styleUrls: ['./rename.component.scss'],
})
export class SettingsModalsProfileRenameComponent {
  // Represents the profile name.
  profileName = '';
  // Error to be shown to the user.
  error = '';
  // Stores the name validation.
  validName = false;

  /**
   * Constructor function responsible for injecting the needed services.
   *
   * @param profileValidationService Reference to ProfileValidationService.
   * @param dialogRef Reference to MatDialogRef.
   * @param data Is an instance of input data.
   */
  constructor(
    private readonly profileValidationService: ProfileValidationService,
    public dialogRef: MatDialogRef<SettingsModalsProfileRenameComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CreateProfileInputDataI) {
    if (this.data && this.data.currentProfile) {
      this.profileName = this.data.currentProfile.profile.name;
    }
  }

  /**
   * Closes the modal.
   */
  close(): void {
    this.dialogRef.close();
  }

  /**
   * Save method.
   */
  save(): void {
    if (this.validName) {
      this.data.currentProfile.profile.name = this.profileName;
      this.dialogRef.close(this.data.currentProfile);
    }
  }

  /**
   * Validates the user input.
   */
  isValid(): void {
    this.validName = true;
    this.error = this.profileValidationService.isNameValid(
      this.data.profiles,
      this.profileName
    );
    if (this.error && this.error.length) {
      this.validName = false;
    }
  }
}
