import { Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatTabGroup } from '@angular/material/tabs';
import { ReplaySubject } from 'rxjs';
import { SUGGESTION_RULES_DATA } from 'src/app/common/constants';
import {
  SubstitutionGroupsResponseDTO,
  SubstitutionSubGroupsDTO,
} from 'src/app/dto/profile/suggestion-rules/suggestion-rules-dto';
import { CustomIconService } from 'src/app/services/custom-icon.service';

/**
 * This component holds the logic for suggestions rules settings.
 */
@Component({
  selector: 'app-shared-common-settings-suggestions-rules',
  templateUrl: './rules.component.html',
  styleUrls: ['./rules.component.scss'],
})
export class SettingsSuggestionsRulesComponent implements OnDestroy {
  @ViewChild('triggerSuggestions') triggerSuggestions: MatMenuTrigger;
  @ViewChild('matTabs') matTabs: MatTabGroup;

  // Stores the suggestions rules data.
  suggestionRules: SubstitutionGroupsResponseDTO[] = SUGGESTION_RULES_DATA;
  // Stores the current selected group.
  selectedGroup: SubstitutionGroupsResponseDTO;
  // Stores the current subGroup of the selected group.
  selectedSubGroup: SubstitutionSubGroupsDTO;
  // Represents the input text.
  textToTest = '';
  // Stores the subscribers until they're destroyed.
  private readonly destroyed = new ReplaySubject<never>();
  // Stores the current index.
  currentIndex = 0;

  toggleTest = false;

  /**
   * Constructor function responsible for injecting the needed services.
   *
   * @param customIconsService Reference to CustomIconService.
   * @param suggestionsService Reference to IntowordsSuggestionsService.
   * @param wordPredictionService Reference to WordPredictionService.
   * @param profileService Reference to ProfileService.
   */
  constructor(
    private readonly customIconsService: CustomIconService,
  ) {
    // Returns the icons (used in UI).
    this.customIconsService.getCustomIcons([
      'suggestion_rules',
      'rules_group',
      'rules_group_checked',
      'rules_checkbox',
      'rules_checkbox_checked',
      'suggestion_bar',
      'close_x',
    ]);
    if (this.suggestionRules && this.suggestionRules.length > 0) {
      // Preselect first rule and first group.
      this.suggestionRules[0].selected = true;
      this.suggestionRules[0].subGroups[0].selected = true;
      this.selectedGroup = this.suggestionRules[0];
      this.selectedSubGroup = this.suggestionRules[0].subGroups[0];
    }
  }

  /**
   * Unsubscribe Observables and detach event handlers to avoid memory leaks.
   */
  ngOnDestroy(): void {
    this.destroyed.next();
  }

  navigateTo(index: number): void {
    this.currentIndex = index;
  }


  /**
   * Select a group.
   *
   * @param group Represents the selected group.
   */
  selectGroup(group: SubstitutionGroupsResponseDTO): void {
    // Find selected group and unselect it.
    const findSelectedGroup = this.suggestionRules.find((el) => el.selected);
    if (findSelectedGroup.index !== group.index) {
      findSelectedGroup.selected = false;
      findSelectedGroup.subGroups[0].selected = false;
      group.selected = true;
      group.subGroups[0].selected = true;
      this.selectedGroup = group;
      this.selectedSubGroup = group.subGroups[0];
      this.currentIndex = 1;
    }
  }

  /**
   * Select a subgroup.
   *
   * @param subgroup Represents the selected subgroup.
   */
  selectGroupRule(subgroup: SubstitutionSubGroupsDTO): void {
    const findSelectedGroup = this.selectedGroup.subGroups.find(
      (el) => el.selected
    );
    if (findSelectedGroup.index !== subgroup.index) {
      findSelectedGroup.selected = false;
      subgroup.selected = true;
      this.selectedSubGroup = subgroup;
      this.currentIndex = 2;
    }
  }

  /**
   * Toggle default enabled of the selected rule.
   *
   * @param group Represents the selected group.
   */
  toggleDefaultGroup(group: SubstitutionGroupsResponseDTO): void {
    group.defaultEnabled = !group.defaultEnabled;
  }

  /**
   * Toggle default enabled of the selected group.
   *
   * @param subgroup Represents the selected subgroup.
   */
  toggleDefaultSubGroup(subgroup: SubstitutionSubGroupsDTO): void {
    // Allow changes only if the parent group is enabled.
    if (this.selectedGroup && this.selectedGroup.defaultEnabled) {
      subgroup.defaultEnabled = !subgroup.defaultEnabled;
    }
  }

  /**
   * Toggle default enabled of the selected rule of the selected group.
   *
   * @param rule Represents the selected rule of the selected group.
   */
  toggleDefaultSubGroupRule(rule: SubstitutionSubGroupsDTO): void {
    // Allow changes only if the parent group and subGroup are enabled.
    if (
      this.selectedGroup.defaultEnabled &&
      this.selectedSubGroup.defaultEnabled
    ) {
      rule.defaultEnabled = !rule.defaultEnabled;
    }
  }

  /**
   * Opens the test suggestion window.
   */
  testSuggestion(): void {
    this.toggleTest = !this.toggleTest;
  }

  /**
   * Closes the test suggestion window.
   */
  close(): void {
    this.triggerSuggestions.closeMenu();
  }

  /**
   * Get new predictions based on the input text.
   */
  getSuggestions(): void { }
}
