<div
  class="loader-hld"
  [ngClass]="{ 'full-page': fullPage, 'fit-container': !fullPage }"
  *ngIf="preload"
>
  <div class="centered-hld">
    <div class="lds-roller">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</div>
