import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CustomIconService } from 'src/app/services/custom-icon.service';

/**
 * This component is used to show the error in a modal.
 */
@Component({
  selector: 'app-error-modal',
  templateUrl: './error-modal.component.html',
  styleUrls: ['./error-modal.component.scss'],
})
export class ErrorModalComponent implements OnInit {
  /**
   * Constructor function responsible for injecting the needed services.
   *
   * @param dialogRef Is an instance of MatDialogRef.
   * @param data Is an instance of input data.
   * @param customIconService Is an instance of CustomIconService.
   */
  constructor(
    public dialogRef: MatDialogRef<ErrorModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: string,
    private readonly customIconService: CustomIconService
  ) {
    // Returns the language icon (used in UI).
    this.customIconService.getCustomIcons('error');
  }
  /**
   * A lifecycle hook that is called after Angular has initialized all data-bound properties of a directive.
   */
  ngOnInit(): void {}

  /**
   * Closes the modal.
   */
  cancel(): void {
    this.dialogRef.close();
  }
}
