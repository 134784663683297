import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';

import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './modules/shared/shared.module';
import { MaterialModule } from './modules/shared/material.module';
import { IntowordsServiceModule } from './services/intowords/intowords-service.module';

import { GlobalErrorHandler } from './services/global-error-handler.service';
import { InterceptorLoaderService } from './services/interceptor-loader.service';
import { LoaderInterceptor } from './interceptors/loader.interceptor';
import { LoaderService } from './services/loader.service';
import { AuthInterceptor } from './services/auth-interceptor.service';
import { UserProfileService } from './services/intowords/intowords-profile.service';
import { IntowordsVoiceService } from './services/intowords/intowords-voice.service';
import { HomeComponent } from './modules/home/home.component';
import { ErrorModalService } from './services/error-modal.service';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { environment } from 'src/environments/environment';
import { ProfileService } from './services/profile.service';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { AuthService } from './services/auth.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CustomIconService } from './services/custom-icon.service';
import { ProfileComponent } from './modules/profile/profile.component';
import { SettingsComponent } from './modules/settings/settings.component';
import { SpeechComponent } from './modules/speech/speech.component';
import { SuggestionsComponent } from './modules/suggestions/suggestions.component';
import { WarningModalService } from './services/warning-modal.service';
import { ProfileValidationService } from './services/profile-validation.service';
import { EventStoreService } from './services/event-store.service';
import { ProfileHelperService } from './services/profile-helper.service';
import { SpeechService } from './services/speech.service';
import { IntowordsSuggestionsService } from './services/intowords/intowords-suggestions.service';
import { SuggestionStateHandlerService } from './services/suggestion-state-handler.service';

// TranslateHttpLoader is used to load the translations automatically.
// AoT requires an exported function for factories.
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}

/**
 * Main entry-point module on the angular app. This module is responsible for bootstrapping the app
 * and loading any critical sub-modules, required at 0-level.
 */
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ProfileComponent,
    SettingsComponent,
    SpeechComponent,
    SuggestionsComponent,
  ],
  imports: [
    BrowserModule,
    FlexLayoutModule,
    BrowserAnimationsModule,
    FormsModule,
    AppRoutingModule,
    IntowordsServiceModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    // Logger config based on environment.
    LoggerModule.forRoot({
      level: !environment.production
        ? environment.logLevel
          ? environment.logLevel
          : NgxLoggerLevel.LOG
        : NgxLoggerLevel.OFF,
      // serverLogLevel
      serverLogLevel: NgxLoggerLevel.OFF,
    }),
    HttpClientModule,
    MaterialModule,
    SharedModule,
  ],
  providers: [
    UserProfileService,
    IntowordsVoiceService,
    ErrorModalService,
    LoaderService,
    ProfileService,
    AuthService,
    CustomIconService,
    WarningModalService,
    ProfileValidationService,
    EventStoreService,
    ProfileHelperService,
    SpeechService,
    IntowordsSuggestionsService,
    SuggestionStateHandlerService,
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    InterceptorLoaderService,
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
