<div class="dialog-hld">
  <div mat-dialog-title class="mat-dialog-title">
    <div class="title">{{ "translateCreateProfile" | translate }}</div>
  </div>

  <div mat-dialog-content class="content-hld" *ngIf="data && data.currentProfile">
    <div class="padd-t-b-10">
      <div class="settings-label">
        {{ "translateSelectProfileLanguage" | translate }}
      </div>
      <div class="dropdown-btn" mat-button [matMenuTriggerFor]="profileLang" fxLayout="row">
        <div fxFlex class="padd-t-7">
          <mat-icon svgIcon="{{ selectedLanguage.language }}" class="app-icon-hover"></mat-icon>
        </div>
        <div fxFlex="nogrow" class="padd-t-7">
          <mat-icon>expand_more</mat-icon>
        </div>
      </div>
      <mat-menu #profileLang="matMenu" class="panel-full-width">
        <button *ngFor="let language of availableProfileLanguages" mat-menu-item class="lang-hld"
          (click)="selectLanguage(language)">
          <ng-container>
            <mat-icon svgIcon="{{ language.language }}" class="app-icon-hover"></mat-icon>
          </ng-container>
        </button>
      </mat-menu>
    </div>
    <div class="padd-t-b-10" *ngIf="selectedLanguage">
      <div class="settings-label">{{ "translateSelectVoice" | translate }}</div>
      <div class="dropdown-btn" mat-button [matMenuTriggerFor]="langVoices" fxLayout="row">
        <div fxFlex>
          {{ selectedVoice.name }}
        </div>
        <div fxFlex="nogrow" class="padd-t-7">
          <mat-icon>expand_more</mat-icon>
        </div>
      </div>
      <mat-menu #langVoices="matMenu" class="panel-full-width">
        <app-shared-common-settings-profile-voice-list [voices]="selectedLanguage.voices"
          (setVoice)="selectVoice($event)">
        </app-shared-common-settings-profile-voice-list>
      </mat-menu>
    </div>
    <div class="padd-t-10">
      <mat-form-field class="input-hld">
        <mat-label>{{ "translateProfileName" | translate }}</mat-label>
        <input matInput type="text" [(ngModel)]="profileName" (keydown.enter)="isValid()" (blur)="isValid()"
          autocomplete="off" />
      </mat-form-field>
      <mat-error class="error-hld" *ngIf="!validName">{{ error }}</mat-error>
    </div>
  </div>

  <div mat-dialog-actions fxLayout="row" fxLayoutAlign="end center" class="modal-footer">
    <div (click)="close()" class="action-btn">
      {{ "translateCancel" | translate }}
    </div>
    <div (click)="save()" class="action-btn">
      {{ "translateCreate" | translate }}
    </div>
  </div>
</div>
