import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EncodingService } from './encoding.service';
import { IntowordsBaseService } from './intowords-base.service';
import { CookieService } from 'ngx-cookie-service';

/**
 * Service responsible of working with intowords language api.
 */
@Injectable()
export class IntowordsLanguagesService extends IntowordsBaseService<string[]> {
  private baseUrl: string;

  /**
   * @param httpService Performs HTTP requests.
   * @param encoding Service responsible for encoding hmac data for intowords requests.
   * @param cookieService Service responsible for managing cookies.
   */
  constructor(
    protected readonly httpService: HttpClient,
    protected readonly encoding: EncodingService,
    protected readonly cookieService: CookieService
  ) {
    super(httpService, encoding, cookieService);
    this.baseUrl = this.UrlEndpoints.wordprediction;
  }

  /**
   * Lists all available languages.
   *
   * @param version Api version.
   */
  list(version = 1): Observable<string[]> {
    return this.get<string[]>(`${this.baseUrl}/v${version}/Languages`);
  }
}
