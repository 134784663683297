import { Component, OnDestroy, OnInit } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { AuthService } from './services/auth.service';
import { ProfileService } from './services/profile.service';
import { ProfileInfoExtendedDTOI } from './dto/profile/profile-dto';

/**
 * Main app component.
 */
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  // Stores the subscribers until they're destroyed.
  private readonly destroyed = new ReplaySubject<never>();
  // Represents the value for the spinner.
  loader: boolean;
  // Represents profiles array.
  profiles: ProfileInfoExtendedDTOI[] = [];

  /**
   * Constructor function responsible for injecting the needed services.
   *
   * @param profileService Reference to profileService.
   * @param authService Reference to AuthService.
   * @param cookieService Reference to CookieService.
   */
  constructor(
    private readonly profileService: ProfileService,
    private readonly authService: AuthService,
    private readonly cookieService: CookieService
  ) { }

  ngOnInit(): void {
    // Listens if the user is logged in.
    this.authService.loggedInAction
      .pipe(takeUntil(this.destroyed))
      .subscribe((isLogged?: boolean) => {
        if (isLogged) {
          this.getAllProfiles();
        }
      });
    // Check if the cookie exists.
    if (this.cookieService.get('mv_session_id')) {
      this.getAllProfiles();
    }
    // Listens if it should send the profiles.
    this.profileService.onRequestProfilesAction
      .pipe(takeUntil(this.destroyed))
      .subscribe(() => {
        if (this.profiles && this.profiles.length) {
          this.profileService.sendProfiles(this.profiles);
        }
      });
  }

  /**
   * Get all profiles.
   */
  getAllProfiles(): void {
    this.loader = true;
    this.profileService
      .globalProfiles()
      .pipe(takeUntil(this.destroyed))
      .subscribe(
        (profiles) => {
          if (profiles.length) {
            this.profiles = profiles;
            this.profileService.sendProfiles(profiles);
          }
          this.loader = false;
        },
        (error) => {
          this.loader = false;
          throw error;
        }
      );
  }

  /**
   * Unsubscribe Observables and detach event handlers to avoid memory leaks.
   */
  ngOnDestroy(): void {
    this.destroyed.next();
  }
}
