<div class="rel-pos">
  <div class="header">
    <div class="header-hld">
      <mat-icon (click)="goBack()">chevron_left</mat-icon>
      <div class="title">{{ "translateSuggestions" | translate }}</div>
    </div>
  </div>

  <div class="cont-hld" *ngIf="currentProfile">
    <div>
      <div class="section-content suggestions-hld">
        <div>
          <div mat-menu-item class="menu-item full-width padd-l-47" (click)="toggleSettings('predictNextWord')">
            {{ "translateSuggestionNextWord" | translate }}
            <mat-slide-toggle class="ml-auto" [checked]="suggestionSettings.predictNextWord"
              (click)="toggleSettings('predictNextWord')" appDisableMouseClick></mat-slide-toggle>
          </div>

          <div mat-menu-item class="menu-item full-width padd-l-47" (click)="toggleSettings('showSpelling')">
            {{ "translateSuggestAlternativeWords" | translate }}
            <mat-slide-toggle class="ml-auto" [checked]="suggestionSettings.showSpelling"
              (click)="toggleSettings('showSpelling')" appDisableMouseClick></mat-slide-toggle>
          </div>

          <div class="word-settings-hld">
            <div class="menu-item full-width" fxLayout="row">
              <div fxFlex class="flex-display">
                <div class="icon-container">
                  <mat-icon svgIcon="short_word" class="shortest"></mat-icon>
                </div>
                {{ "translateLimitShortestWord" | translate }}
              </div>
              <div fxFlex="nogrow">
                <div class="dropdown-btn" mat-button [matMenuTriggerFor]="shortest" fxLayout="row">
                  <div fxFlex>{{ selectedShortestWord }}</div>
                  <div fxFlex="nogrow" class="padd-t-7">
                    <mat-icon>expand_more</mat-icon>
                  </div>
                </div>
                <mat-menu #shortest="matMenu">
                  <button mat-menu-item *ngFor="let item of defaultWordArray" (click)="selectShortest(item)">
                    {{ item }}
                  </button>
                </mat-menu>
              </div>
            </div>

            <div class="menu-item full-width" fxLayout="row">
              <div fxFlex class="flex-display">
                <div class="icon-container">
                  <mat-icon svgIcon="longest_word" class="longest"></mat-icon>
                </div>
                {{ "translateLimitLongestWord" | translate }}
              </div>
              <div fxFlex="nogrow">
                <div class="dropdown-btn" mat-button [matMenuTriggerFor]="longest" fxLayout="row">
                  <div fxFlex>{{ selectedLongestWord }}</div>
                  <div fxFlex="nogrow" class="padd-t-7">
                    <mat-icon>expand_more</mat-icon>
                  </div>
                </div>
                <mat-menu #longest="matMenu">
                  <button mat-menu-item *ngFor="let item of longestWordArray" (click)="selectLongest(item)">
                    {{ item }}
                  </button>
                </mat-menu>
              </div>
            </div>

            <div class="menu-item full-width" fxLayout="row">
              <div fxFlex class="char-hld">
                {{ "translateCharacterBeforeAlternative" | translate }}
              </div>
              <div fxFlex="nogrow">
                <div class="dropdown-btn" mat-button [matMenuTriggerFor]="chars" fxLayout="row">
                  <div fxFlex>{{ selectedCharacter }}</div>
                  <div fxFlex="nogrow" class="padd-t-7">
                    <mat-icon>expand_more</mat-icon>
                  </div>
                </div>
                <mat-menu #chars="matMenu">
                  <button mat-menu-item *ngFor="let item of defaultWordArray"
                    (click)="selectNbrCharactersBeforeSuggestion(item)">
                    {{ item }}
                  </button>
                </mat-menu>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div mat-menu-item class="menu-item full-width">
            <div class="icon-container">
              <mat-icon svgIcon="letter_case_rules"></mat-icon>
            </div>
            {{ "translateLetterCaseRules" | translate }}
          </div>
          <div class="rules-hld">
            <div class="case-hld flex-display" (click)="selectCaseRule()">
              <div class="flex-display min-w">
                <div class="icon-container">
                  <mat-icon [svgIcon]="
                        !suggestionSettings.dictation
                          ? 'radio_checked'
                          : 'radio_unchecked'
                      "></mat-icon>
                </div>
                {{ "translateNormal" | translate }}
              </div>
              <div class="description-hld">
                {{ "translateThisIsATestUpperCase" | translate }}
              </div>
            </div>
            <div class="case-hld flex-display" (click)="selectCaseRule()">
              <div class="flex-display min-w">
                <div class="icon-container">
                  <mat-icon [svgIcon]="
                        suggestionSettings.dictation
                          ? 'radio_checked'
                          : 'radio_unchecked'
                      "></mat-icon>
                </div>
                {{ "translateDictation" | translate }}
              </div>
              <div class="description-hld">
                {{ "translateThisIsATestLowerCase" | translate }}
              </div>
            </div>
          </div>

          <div class="flex-display terms-hld">
            <div class="flex-display">
              <div class="icon-container">
                <mat-icon svgIcon="terms"></mat-icon>
              </div>
              {{ "translateTerms" | translate }}
            </div>
            <div class="terms-btn-hld ml-auto" (click)="openTermsModal()">
              <div class="terms-btn">
                <mat-icon svgIcon="terms"></mat-icon>
              </div>
            </div>
          </div>

          <div class="vocabulary-hld">
            <div class="menu-item full-width" fxLayout="row">
              <div fxFlex class="flex-display">
                <div class="icon-container">
                  <mat-icon svgIcon="vocabulary"></mat-icon>
                </div>
                {{ "translateVocabulary" | translate }}
              </div>
              <div fxFlex="nogrow" class="ml-auto">
                <div class="dropdown-btn" mat-button [matMenuTriggerFor]="vocabulary" fxLayout="row">
                  <div fxFlex>{{ selectedVocabulary }}</div>
                  <div fxFlex="nogrow" class="padd-t-7">
                    <mat-icon>expand_more</mat-icon>
                  </div>
                </div>
                <mat-menu #vocabulary="matMenu">
                  <button mat-menu-item *ngFor="let item of vocabularyOptions" (click)="selectVocabulary(item)">
                    {{ item }}
                  </button>
                </mat-menu>
              </div>
            </div>
          </div>
        </div>

        <app-shared-common-settings-suggestions-rules>
        </app-shared-common-settings-suggestions-rules>
      </div>
    </div>

  </div>
</div>
