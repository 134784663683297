import { Component, Input, OnDestroy } from '@angular/core';
import { ReplaySubject, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { DEFAULT_DEBOUNCE_MIN_TIME } from 'src/app/common/constants';
import {
  ProfileInfoExtendedDTOI,
  VoiceSettingsI,
} from 'src/app/dto/profile/profile-dto';
import { CustomIconService } from 'src/app/services/custom-icon.service';
import { TranslationsI } from 'src/app/services/language.service';
import { ProfileService } from 'src/app/services/profile.service';

/**
 * This component holds the logic for read settings.
 */
@Component({
  selector: 'app-shared-common-settings-speech-read',
  templateUrl: './read.component.html',
  styleUrls: ['./read.component.scss'],
})
export class SettingsSpeechReadComponent implements OnDestroy {
  // Profiles array.
  @Input() profiles: ProfileInfoExtendedDTOI[];
  // Show/hide the highligth option.
  @Input() extraOption: boolean;
  /**
   * Setter and getter for the current profile. Used to update the input data after it was changed.
   */
  @Input()
  set currentProfile(profile: ProfileInfoExtendedDTOI) {
    this.currentProfileValues = profile;
    this.voiceOptions = {
      writeLetterName: profile.profile.settings.writeLetterName,
      writeLetterSound: profile.profile.settings.writeLetterSound,
      writeWord: profile.profile.settings.writeWord,
      writeSentence: profile.profile.settings.writeSentence,
      readForward: profile.profile.settings.readForward,
      highlightWord: profile.profile.settings.highlightWord,
    };
  }
  get currentProfile(): ProfileInfoExtendedDTOI {
    return this.currentProfileValues;
  }
  // Current profile holder.
  private currentProfileValues: ProfileInfoExtendedDTOI;

  // Stores the translations.
  translatedObj: TranslationsI;
  // Stores the subscribers until they're destroyed.
  private readonly destroyed = new ReplaySubject<never>();
  // Stores the voices options.
  voiceOptions: VoiceSettingsI;

  /**
   * Constructor function responsible for injecting the needed services.
   *
   * @param customIconService Reference to CustomIconService.
   * @param profileService Reference to ProfileService.
   */
  constructor(
    private readonly customIconService: CustomIconService,
    private readonly profileService: ProfileService
  ) {
    // Returns the icons (used in UI).
    this.customIconService.getCustomIcons([
      'letter_name',
      'letter_sound',
      'word',
      'sentence',
      'continuous',
      'highlight',
    ]);
  }

  /**
   * Unsubscribe Observables and detach event handlers to avoid memory leaks.
   */
  ngOnDestroy(): void {
    this.destroyed.next();
  }

  /**
   * Method to toggle voice options.
   *
   * @param voiceOption Name of voice option.
   */
  toggleVoiceOption(
    voiceOption:
      | 'writeLetterName'
      | 'writeLetterSound'
      | 'writeWord'
      | 'writeSentence'
      | 'readForward'
      | 'highlightWord'
  ): void {
    // We need a debounceTime in order to allow change to trigger first but not interfere.
    const changeSubject = new Subject();
    changeSubject
      .pipe(debounceTime(DEFAULT_DEBOUNCE_MIN_TIME))
      .subscribe(() => {
        // WriteLetterName and WriteLetterSound can't be active at the same time.
        if (
          voiceOption === 'writeLetterName' &&
          this.voiceOptions.writeLetterSound
        ) {
          this.voiceOptions.writeLetterSound = !this.voiceOptions
            .writeLetterSound;
        }
        if (
          voiceOption === 'writeLetterSound' &&
          this.voiceOptions.writeLetterName
        ) {
          this.voiceOptions.writeLetterName = !this.voiceOptions
            .writeLetterName;
        }
        this.voiceOptions[voiceOption] = !this.voiceOptions[voiceOption];
        this.saveVoiceOptions();
      });
    changeSubject.next();
  }

  /**
   * Save voice's options.
   */
  saveVoiceOptions(): void { }

  /**
   * Returns true if active voice option contains LetterSound feature and false otherwise.
   *
   * @returns True if active voice option contains LetterSound feature and false otherwise.
   */
  shouldDisplayLetterSoundOption(): boolean {
    return this.currentProfile.voices
      .find(
        (voice) => voice.id === this.currentProfile.profile.settings.voiceId
      )
      ?.features.includes('LetterSound');
  }
}
