<div class="prediction-hld">
  <div class="list-hld">
    <div *ngFor="
        let prediction of predictions
          | paginate: { itemsPerPage: maxItemsPerPage, currentPage: page };
        let i = index
      " fxLayout="row" class="items" (click)="insertPrediction(prediction.word)"
      [ngClass]="{ 'terms-hld': prediction.type === 'terms' }">
      <div fxFlex="row" [ngClass]="{ 'border-top': true, first: i === 5 }" *ngIf="prediction.word">
        <div fxFlex class="word-hld">{{ prediction.word }}</div>
        <div fxFlex="nogrow" class="keys-hld">
          {{ prediction.key }}
        </div>
      </div>
    </div>
  </div>
</div>
<div class="pagination-hld">
  <pagination-controls directionLinks="false" (pageChange)="setPage($event)">
  </pagination-controls>
</div>
