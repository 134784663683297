<div class="menu-hld">
  <div class="menu-items">
    <div class="item" routerLink="/home" routerLinkActive="active-link">
      <mat-icon svgIcon="Favicon-svg" class="section-icon intowords"></mat-icon>
    </div>
    <div class="item" routerLink="/profile" routerLinkActive="active-link">
      <mat-icon svgIcon="icon-Profile" class="section-icon profile"></mat-icon>
    </div>
    <div class="item" routerLink="/settings" routerLinkActive="active-link">
      <mat-icon svgIcon="profile_settings" class="section-icon settings"></mat-icon>
    </div>
  </div>
</div>
