import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EncodingService } from './encoding.service';
import { IntowordsBaseService } from './intowords-base.service';
import { CookieService } from 'ngx-cookie-service';
import { map } from 'rxjs/operators';
import { ProfileInfoExtendedDTOI } from 'src/app/dto/profile/profile-dto';

/**
 * Expected voice format from api.
 */
export interface VoiceResult {
  id: string;
  name: string;
  type: string;
  gender: string;
  langCode: string;
  port?: number;
  features: Array<string>;
  nuanceML?: boolean;
}

/**
 * Expected response format from voice api.
 */
export interface VoiceServiceResult {
  limits: {
    speak: number;
    audioFile: number;
  };
  voices: Array<VoiceResult>;
}

/**
 * Expected body format for speak request.
 */
export interface SpeakBody {
  format: string;
  speed: number;
  text: string;
  type: string;
  voiceID: string;
}

/**
 * Speak timestamp format.
 */
export interface SpeakTimestamp {
  /**
   * Start time for the given range.
   */
  time: number;

  /**
   * First letter/character of word that is highlighted.
   */
  start: number;

  /**
   * Length of the word that is highlighted.
   */
  length: number;
}

/**
 * Response format for TTS request.
 */
export interface SpeakResult {
  /**
   * Relative url for audio file used for speech.
   */
  soundLink: string;

  /**
   * List of SpeakTimestamps.
   */
  speakTimestamps: Array<SpeakTimestamp>;
}

/**
 * Service responsible of working with intowords voice api.
 */
@Injectable()
export class IntowordsVoiceService extends IntowordsBaseService<VoiceServiceResult> {
  private baseUrl: string;

  /**
   * @param httpService Performs HTTP requests.
   * @param encoding Service responsible for encoding hmac data for intowords requests.
   * @param cookieService Service responsible for managing cookies.
   */
  constructor(
    protected readonly httpService: HttpClient,
    protected readonly encoding: EncodingService,
    protected readonly cookieService: CookieService
  ) {
    super(httpService, encoding, cookieService);
    this.baseUrl = this.UrlEndpoints.voiceservice;
  }

  /**
   * Retrieve voice configurations for the specified user.
   */
  getVoiceConfigurations(): Observable<VoiceServiceResult> {
    return this.get<VoiceServiceResult>(
      `${this.baseUrl}/v${this.API_VERSION}/Voice/GetVoiceConfig`,
      false
    );
  }

  /**
   * Request TTS for a specified text.
   *
   * @param text The text to convert to speech.
   * @param currentProfile Represents the current profile.
   * @param speechType Represents the type of speech.
   */
  speak(
    text: string,
    currentProfile: ProfileInfoExtendedDTOI,
    speechType: string
  ): Observable<SpeakResult> {
    const speakSettings = {
      format: 'OGG',
      speed: currentProfile.profile.settings.voiceSpeed,
      text,
      type: speechType,
      voiceID: currentProfile.profile.settings.voiceId,
    };
    return this.post<SpeakBody, SpeakResult>(
      `${this.baseUrl}/v${this.API_VERSION}/Voice/Speak`,
      speakSettings
    ).pipe(
      map((result) => {
        const completePath = this.baseUrl + '/' + result.soundLink;
        result.soundLink = completePath;
        return result;
      })
    );
  }
}
