import { NgModule } from '@angular/core';
import { EncodingService } from './encoding.service';
import { IntowordsLanguagesService } from './intowords-languages.service';
import { IntowordsSuggestionsService } from './intowords-suggestions.service';
import { OcrService } from './intowords-ocr.service';
import { IntowordsVoiceService } from './intowords-voice.service';
import { UserProfileService } from './intowords-profile.service';
import { IntowordsDictionaryService } from './intowords-dictionary.service';
import { IntowordsTopicDictionariesService } from './intowords-topic-dictionaries.service';

/**
 * Module that holds all Intowords services.
 */
@NgModule({
  providers: [
    IntowordsLanguagesService,
    IntowordsSuggestionsService,
    IntowordsVoiceService,
    IntowordsDictionaryService,
    IntowordsTopicDictionariesService,
    EncodingService,
    OcrService,
    UserProfileService,
  ],
})
export class IntowordsServiceModule {}
