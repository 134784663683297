export const SuggestionsTest = [
  { word: 'Testbilen', origin: 'Normal', spelling: 'Normal' },
  { word: 'Testen', origin: 'Normal', spelling: 'Normal' },
  { word: 'Test', origin: 'Normal', spelling: 'Normal' },
  { word: 'Testens', origin: 'Normal', spelling: 'Normal' },
  { word: 'Testene', origin: 'Normal', spelling: 'Normal' },
  { word: 'Testet', origin: 'Normal', spelling: 'Normal' },
  { word: 'Testamentet', origin: 'Normal', spelling: 'Normal' },
  { word: 'Teste', origin: 'Normal', spelling: 'Normal' },
  { word: 'Testamente', origin: 'Normal', spelling: 'Normal' },
  { word: 'Tester', origin: 'Normal', spelling: 'Normal' },
  { word: 'Testiklerne', origin: 'Topics', spelling: 'Normal' },
  { word: 'Testikel', origin: 'Topics', spelling: 'Normal' },
  { word: 'Testikler', origin: 'Topics', spelling: 'Normal' },
  { word: 'Testosteron', origin: 'Topics', spelling: 'Normal' },
  { word: 'Testikeludvikling', origin: 'Topics', spelling: 'Normal' },
];
