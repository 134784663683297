import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { from, Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { LanguageHelperService } from '../language.service';
import { EncodingService } from './encoding.service';
import { IntowordsBaseService } from './intowords-base.service';

// Expected response format from the uploadContent request.
export interface OcrUploadResult {
  id: string;
}

/**
 * Service responsible of working with OCR.
 */
@Injectable()
export class OcrService extends IntowordsBaseService<OcrUploadResult> {
  // Represents the base url.
  private baseUrl: string;

  /**
   *
   * @param httpService Performs HTTP requests.
   * @param encoding Service responsible for encoding hmac data for intowords requests.
   * @param cookieService Service responsible for managing cookies.
   * @param languageService Service responsible for managing languages.
   */
  constructor(
    protected readonly httpService: HttpClient,
    protected readonly encoding: EncodingService,
    protected readonly cookieService: CookieService,
    protected readonly languageService: LanguageHelperService
  ) {
    super(httpService, encoding, cookieService);
    this.baseUrl = this.UrlEndpoints.ocr;
  }

  /**
   * Upload content.
   *
   * @param resource The actual resource from getPdfWithOperationsAsResource.
   * @param language Requested language.
   */
  uploadContent(
    resource: File,
    language?: 'en' | 'dk'
  ): Observable<OcrUploadResult> {
    if (!language) {
      language = this.languageService.currentLangUsed === 'en' ? 'en' : 'dk';
    }
    const lang = this.translateLanguage(language);
    const convertBlobToBase64: Promise<string> = new Promise<string>(
      (resolve, reject) => {
        const reader = new FileReader();
        reader.onerror = reject;
        reader.onload = () => {
          resolve(
            (reader.result as string).replace(
              'data:application/octet-stream;base64,',
              ''
            )
          );
        };
        reader.readAsDataURL(resource);
      }
    );
    return from(convertBlobToBase64).pipe(
      mergeMap((base64string) => {
        return this.post<string, OcrUploadResult>(
          `${this.baseUrl}/v${this.API_VERSION}/processToOCR/application%2fpdf?language=${lang}&returnMimeType=application%2Fpdf`,
          base64string,
          { 'Content-Type': 'application/json' }
        );
      })
    );
  }

  /**
   * Makes a request to get the status (Finished, InProgress or Failed).
   *
   * @param ocrId The id of the request.
   *
   * @return A string representing the status of the request(Finished, InProgress or Failed).
   */
  getTaskStatus(
    ocrId: string
  ): Observable<'Finished' | 'InProgress' | 'Failed'> {
    return this.get<'Finished' | 'InProgress' | 'Failed'>(
      `${this.baseUrl}/v${this.API_VERSION}/getTaskStatus/${ocrId}`,
      false
    );
  }

  /**
   * Download the pdf.
   *
   * @param ocrId Represents the id of the request.
   *
   * @return The downloaded content of the Pdf as an ArrayBuffer.
   */
  donwloadOcr(ocrId: string): Observable<ArrayBuffer> {
    return this.get(
      `${this.baseUrl}/v${this.API_VERSION}/downloadOCR/${ocrId}`,
      false,
      true
    ).pipe(
      map((data) => {
        return (data as { body: ArrayBuffer }).body;
      })
    );
  }

  /**
   * Converts the language format.
   *
   * @param language Represents the language format from the available languages array.
   *
   * @return The language for the language format.
   */
  // TODO Add all languages: Danish, English, NorwegianBokmal, NorwegianNynorsk, Swedish, Thai, Greek,
  // Czech, Korean, Hebrew, Basque, Arabic, Dutch, DutchBelgian, French, Polish, German, Turkish, Chinese,
  // Finnish, Russian, Catalan, Italian, Spanish, Romanian, Japanese, Galician, Hungarian, Bulgarian, Portuguese, Indonesian
  private translateLanguage(language: 'en' | 'dk'): 'English' | 'Danish' {
    switch (language) {
      case 'dk':
        return 'Danish';
      case 'en':
      default:
        return 'English';
    }
  }
}
