import { Injectable } from '@angular/core';
import { EventStoreInterface, EventStoreTypes } from '../models/common/types';

/**
 * This service is used to handle the events when.
 */
@Injectable()
export class EventStoreService {
  // Represents events holder.
  events: { [key: string]: EventStoreInterface[] } = {};

  /**
   * Registers the events.
   *
   * @param action Represents the event that needs to be handled.
   *
   * @return A list of events.
   */
  registerActions(action: EventStoreInterface): EventStoreInterface[] {
    if (!this.events[action.type]) {
      this.events[action.type] = [];
    }
    this.events[action.type].push(action);
    return this.events[action.type];
  }

  /**
   * Gets the events for the selected type.
   *
   * @param type Represents the type of the event.
   *
   * @return A list of events.
   */
  requestActions(type: EventStoreTypes): EventStoreInterface[] {
    if (!this.events[type]) {
      return [];
    }
    const returnEvent = this.events[type].slice();
    this.events[type] = [];
    return returnEvent;
  }
}
