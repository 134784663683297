import { Injectable } from '@angular/core';
import { Observable, ObservableInput, of, Subject, zip } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import {
  isProfileInfoExtendedDTOI,
  ProfileDTOI,
  ProfileInfoDTOI,
  ProfileInfoExtendedDTOI,
} from '../dto/profile/profile-dto';
import { UserProfileService } from './intowords/intowords-profile.service';
import {
  IntowordsVoiceService,
  VoiceResult,
} from './intowords/intowords-voice.service';
import { LanguageHelperService } from './language.service';

/**
 * This is how the sorted voices array should look.
 */
export interface SortedVoicesInterface {
  language: string;
  voiceId: string;
  voices?: VoiceResult[];
}
/**
 * Interface for the grouped voices.
 */
export interface GroupedVoicesInterface {
  [index: string]: SortedVoicesInterface;
}

/**
 * This service is used to handle all the necessary steps when getting the profiles.
 */
@Injectable()
export class ProfileService {
  // Profiles array.
  profiles: ProfileInfoDTOI[] = [];

  /**
   * The subject used to controls the service communication.
   */
  private profilesSource = new Subject<ProfileInfoExtendedDTOI[]>();
  /**
   * The subject used to controls the service communication.
   */
  private requestProfilesSource = new Subject<void>();
  /**
   * The subject used to controls the service communication.
   */
  private currentProfilesSource = new Subject<ProfileInfoExtendedDTOI>();
  /**
   * The subject used to controls the service communication.
   */
  private currentProfileChangedSource = new Subject<boolean>();
  /**
   * Observable instance of the source object.
   */
  private profilesObservable = this.profilesSource.asObservable();
  /**
   * Observable instance of the source object.
   */
  private onRequestProfilesObservable = this.requestProfilesSource.asObservable();
  /**
   * Observable instance of the source object.
   */
  private requestCurrentProfileObservable = this.currentProfilesSource.asObservable();
  /**
   * Observable instance of the source object.
   */
  private currentProfileChangedObservable = this.currentProfileChangedSource.asObservable();

  /**
   * Getter function for private profiles Observable.
   *
   * @return Observable<UserProfileInfoInterface[]> That listens for any actions.
   */
  public get profilesAction(): Observable<ProfileInfoExtendedDTOI[]> {
    return this.profilesObservable;
  }
  /**
   * Getter function for private onRequestProfiles Observable.
   *
   * @return Observable<void> That listens for any actions.
   */
  public get onRequestProfilesAction(): Observable<void> {
    return this.onRequestProfilesObservable;
  }
  /**
   * Getter function for private requestCurrentProfile Observable.
   *
   * @return Observable<UserProfileInterface> That listens for any actions.
   */
  public get requestCurrentProfileAction(): Observable<ProfileInfoExtendedDTOI> {
    return this.requestCurrentProfileObservable;
  }
  /**
   * Getter function for private currentProfileChanged Observable.
   *
   * @return Observable<boolean> That listens for any actions.
   */
  public get currentProfileHasChangedAction(): Observable<boolean> {
    return this.currentProfileChangedObservable;
  }
  // Represents user's language.
  userLanguage: string;

  /**
   * Constructor function responsible for injecting the needed services.
   *
   * @param userProfileService Reference to UserProfileService.
   * @param getVoiceConfigService Reference to IntowordsVoiceService.
   * @param languageHelperService Reference to LanguageHelperService.
   */
  constructor(
    private readonly userProfileService: UserProfileService,
    private readonly getVoiceConfigService: IntowordsVoiceService,
    private readonly languageHelperService: LanguageHelperService
  ) {
    this.languageHelperService.OnLanguageChanged.subscribe((trans) => {
      // Stores the user language based on the browser language.
      this.userLanguage = this.languageHelperService.currentLangUsed;
    });
  }

  /**
   * Calls the source of the observable and cascades the action.
   *
   * @param profiles Is the action object you want to cascade.
   */
  sendProfiles(profiles: ProfileInfoExtendedDTOI[]): void {
    this.profilesSource.next(profiles);
  }
  /**
   * Calls the source of the observable and cascades the action.
   */
  onRequestProfiles(): void {
    this.requestProfilesSource.next();
  }
  /**
   * Calls the source of the observable and cascades the action.
   */
  onCurrentProfileRequest(currentProfile: ProfileInfoExtendedDTOI): void {
    this.currentProfilesSource.next(currentProfile);
  }
  /**
   * Calls the source of the observable and cascades the action.
   */
  currentProfileHasChanged(hasChanged: boolean): void {
    this.currentProfileChangedSource.next(hasChanged);
  }

  /**
   * Gets all profiles.
   *
   * @return An observable of profiles.
   */
  getProfiles(): Observable<ProfileInfoDTOI[]> {
    return this.getProfilesData().pipe(
      catchError((error) => {
        throw error;
      }),
      mergeMap((data: ProfileInfoDTOI[]) => {
        if (data.length) {
          return of(data);
        } else {
          return this.userProfileService.getAllProfiles();
        }
      })
    );
  }

  /**
   * Gets the profiles data.
   *
   * @return An observable of profiles.
   */
  getProfilesData(): Observable<ProfileInfoDTOI[]> {
    return this.userProfileService.getAllProfiles().pipe(
      mergeMap((profiles: ProfileInfoDTOI[]) => {
        if (profiles.length) {
          return of(profiles);
        } else {
          // If no profiles we'll have to create initial profiles.
          return this.initializeProfiles();
        }
      })
    );
  }

  /**
   * Initializes profiles.
   *
   * @return An observable of profiles.
   */
  initializeProfiles(): Observable<ProfileInfoDTOI[]> {
    const newProfiles: Array<Observable<ProfileDTOI>> = [];
    return this.getVoiceConfigService.getVoiceConfigurations().pipe(
      mergeMap((voicesData) => {
        // Sort voices by languages.
        const languages = this.sortVoicesByLanguages(voicesData.voices);
        for (const lang of languages) {
          // Create new profile.
          newProfiles.push(
            this.userProfileService.createProfile(
              lang.voices[0].name,
              lang.language
            )
          );
        }
        return this.mergeProfileOperations(newProfiles);
      })
    );
  }

  /**
   * Merges profiles operations(create and update).
   *
   * @param profiles An input Observable of profiles.
   *
   * @return An observable of profiles.
   */
  mergeProfileOperations(
    profiles: ObservableInput<ProfileDTOI>[]
  ): Observable<ProfileInfoDTOI[]> {
    const updatedProfiles = [];
    return zip(...profiles).pipe(
      mergeMap((newProf: ProfileDTOI[]) => {
        for (const profile of newProf) {
          // Update the standard profile from the server.
          const update: ProfileDTOI = profile;
          updatedProfiles.push(this.updateProfileDefaultSettings(update));
        }
        // Update profile.
        return zip(...updatedProfiles).pipe(
          mergeMap(() => {
            // Set initial profile based on the browser's language.
            this.setInitialProfile(newProf).subscribe();
            return of([]);
          })
        );
      })
    );
  }

  /**
   * Sorts the voices based on language.
   *
   * @param voices Represents the voices array.
   *
   * @return An array of sorted voices by language.
   */
  sortVoicesByLanguages(voices: VoiceResult[]): SortedVoicesInterface[] {
    const languagesArr: SortedVoicesInterface[] = [];
    const group: GroupedVoicesInterface = {};
    for (const voice of voices) {
      const languageKey = voice.langCode.toLowerCase();
      if (!(languageKey in group)) {
        group[languageKey] = {
          language: voice.langCode,
          voices: [voice],
          voiceId: voice.id,
        };
      } else {
        group[languageKey].voices.push(voice);
      }
    }
    for (const i in group) {
      if (group[i]) {
        languagesArr.push(group[i]);
      }
    }
    return languagesArr;
  }

  /**
   * Delete all profiles.
   */
  deleteProfiles(): void {
    this.userProfileService.deleteAllProfiles().subscribe();
  }

  /**
   * Update profile.
   *
   * @param profile Represents the profile that will be updated.
   *
   * @return An observable that emits the value of true if no errors.
   */
  saveProfile(profile: ProfileDTOI): Observable<boolean> {
    return this.userProfileService.updateProfile(profile).pipe(
      catchError((error) => {
        throw error;
      }),
      mergeMap((newProf) => {
        return of(true);
      })
    );
  }

  /**
   * Set current profile.
   *
   * @param id Represents the profile id.
   *
   * @return An observable that emits the value of true if no errors.
   */
  setCurrentProfile(id: string): Observable<boolean> {
    return this.userProfileService.setCurrentProfile(id).pipe(
      catchError((error) => {
        throw error;
      }),
      mergeMap(() => {
        return of(true);
      })
    );
  }

  /**
   * Get current profile.
   *
   * @return An observable of profile data.
   */
  getCurrentProfile(): Observable<ProfileDTOI> {
    return this.userProfileService.getCurrentProfile().pipe(
      catchError((error) => {
        throw error;
      }),
      mergeMap((profile: ProfileDTOI) => {
        return of(profile);
      })
    );
  }

  /**
   * Set current profile based on browser language.
   *
   * @param profiles Represents the profiles data.
   * @param lang Represents the selected language.
   *
   * @return An observable that emits the value of true/false.
   */
  setInitialProfile(profiles: ProfileDTOI[]): Observable<boolean> {
    const findProfile = profiles.find(
      (el) => el.language === this.userLanguage
    );
    if (findProfile) {
      return this.setCurrentProfile(findProfile.id);
    } else {
      // TODO: Handle default profile. This was introduced due to issues with backend and Esbens profile (ega)
      // being corrupted in some fashion.
      return this.setCurrentProfile(profiles[0].id);
    }
  }

  /**
   * Reset profile.
   *
   * @return An observable of profile data.
   */
  resetProfile(id: string): Observable<ProfileDTOI> {
    return this.userProfileService.resetProfile(id).pipe(
      catchError((error) => {
        throw error;
      }),
      mergeMap((profile: ProfileDTOI) => {
        return of(profile);
      })
    );
  }

  /**
   * Delete profile.
   */
  deleteProfile(id: string): Observable<boolean> {
    return this.userProfileService.deleteProfile(id).pipe(
      catchError((error) => {
        throw error;
      }),
      mergeMap(() => {
        return of(true);
      })
    );
  }

  /**
   * Fetch the current profile and all profiles.
   *
   * @param profile Represents the selected profile.
   */
  fetchCurrentProfileAndProfiles(
    profile: ProfileInfoExtendedDTOI | ProfileDTOI
  ): void {
    this.globalProfiles().subscribe((profiles: ProfileInfoExtendedDTOI[]) => {
      // Check if the selected profile is the current profile.
      if (profile && isProfileInfoExtendedDTOI(profile) && profile.isCurrent) {
        const findProfile = profiles.find(
          (el) => el.profile.id === profile.profile.id
        );
        if (findProfile) {
          this.onCurrentProfileRequest(findProfile);
        }
      }
      this.sendProfiles(profiles);
    });
  }

  /**
   * Merges get all profiles and get all voices.
   *
   * @return An observable of profiles data with the voices data.
   */
  globalProfiles(): Observable<ProfileInfoExtendedDTOI[]> {
    return zip(
      this.getProfiles(),
      this.getVoiceConfigService.getVoiceConfigurations()
    ).pipe(
      catchError((error) => {
        throw error;
      }),
      mergeMap(([profiles, voices]) => {
        const mappedProfiles: ProfileInfoExtendedDTOI[] = [];
        for (const profile of profiles) {
          const newProf: ProfileInfoExtendedDTOI = profile as ProfileInfoExtendedDTOI;
          newProf.voices = voices.voices.filter(
            (voice) =>
              voice.langCode.toLowerCase() ===
              profile.profile.language.toLowerCase()
          );
          mappedProfiles.push(newProf);
        }
        mappedProfiles.sort((a, b) => {
          const nameA = a.profile.name.toUpperCase();
          const nameB = b.profile.name.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        return of(mappedProfiles);
      })
    );
  }

  /**
   * Create profile.
   *
   * @param name Represents the name of the profile.
   * @param language Represents the language of the profile.
   *
   * @return An observable of profile data.
   */
  createProfile(name: string, language: string): Observable<ProfileDTOI> {
    return this.userProfileService.createProfile(name, language).pipe(
      catchError((error) => {
        throw error;
      }),
      mergeMap((profile: ProfileDTOI) => {
        return of(profile);
      })
    );
  }

  /**
   * Find voice for the selected profile/language.
   *
   * @param profile Represents the selected profile/language.
   *
   * @return The voice for the selected profile/language.
   */
  findLanguageVoice(
    profile: ProfileInfoExtendedDTOI | SortedVoicesInterface
  ): VoiceResult {
    let findVoice: VoiceResult;
    if (isProfileInfoExtendedDTOI(profile)) {
      findVoice = profile.voices.find(
        (voice) => voice.id === profile.profile.settings.voiceId
      );
    } else {
      findVoice = profile.voices.find((voice) => voice.id === profile.voiceId);
    }

    if (findVoice) {
      return findVoice;
    } else {
      // There are some issues with the voiceId from the profile data(it does not match with the existing voices).
      // Set selected voice for now as the first voice from the voices array.
      return profile.voices[0];
    }
  }

  /*
   * Update profile voiceSpeed (initially is set to 1000).
   *
   * @param profile Represents the selected profile.
   *
   * @return An observable that emits the value of true if no errors.
   */
  updateProfileDefaultSettings(profile: ProfileDTOI): Observable<boolean> {
    profile.settings.voiceSpeed = 1;
    return this.userProfileService.updateProfile(profile).pipe(
      catchError((error) => {
        throw error;
      }),
      mergeMap((success) => {
        return of(true);
      })
    );
  }
}
