<div class="rel-pos">
  <div class="header">
    <div class="header-hld">
      <mat-icon (click)="goBack()">chevron_left</mat-icon>
      <div class="title">{{ "translateSpeech" | translate }}</div>
    </div>
  </div>

  <div class="cont-hld">
    <div>
      <div fxLayout="row" class="label-hld">
        <div fxFlex="nogrow">
          <mat-icon svgIcon="voice_profile" class="label-icon"></mat-icon>
        </div>
        <div fxFlex class="label-title">
          {{ "translateProfileToRead" | translate }}
        </div>
      </div>
      <div class="voices-hld" *ngIf="selectedVoice">
        <div class="dropdown-btn" mat-button [matMenuTriggerFor]="voiceName" fxLayout="row">
          <div fxFlex>{{ selectedVoice.name }}</div>
          <div fxFlex="nogrow" class="padd-t-7">
            <mat-icon>expand_more</mat-icon>
          </div>
        </div>
        <mat-menu #voiceName="matMenu" class="panel-full-width">
          <app-shared-common-settings-profile-voice-list [voices]="voices" (setVoice)="selectVoice($event)">
          </app-shared-common-settings-profile-voice-list>
        </mat-menu>
      </div>
      <div class="actions-hlds">
        <button mat-button class="reset-btn blue" (click)="listenDemo()">
          {{ "translateListenDemo" | translate }}
        </button>
      </div>
      <div class="padd-t-10">
        <app-shared-common-settings-speech-speed [currentProfile]="currentProfile">
        </app-shared-common-settings-speech-speed>
      </div>
      <div class="read-hld">
        <app-shared-common-settings-speech-read [currentProfile]="currentProfile" [extraOption]="true"
          [profiles]="profiles"></app-shared-common-settings-speech-read>
      </div>
    </div>
  </div>
</div>
