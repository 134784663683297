import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

/**
 * LoaderService is used to handle the loader(spinner) value.
 */
@Injectable()
export class LoaderService {
  /**
   * The subject used to controls the service communication.
   */
  private loadSource = new Subject<boolean>();
  /**
   * Observable instance of the source object.
   */
  private loadActionObservable = this.loadSource.asObservable();

  /**
   * Getter function for private loadAction Observable.
   *
   * @return Observable<boolean> That listens for any tool actions.
   */
  public get loadAction(): Observable<boolean> {
    return this.loadActionObservable;
  }

  /**
   * Calls the source of the observable and cascades the action.
   *
   * @param isLoaded Is the action object you want to cascade.
   */
  shouldLoad(isLoaded: boolean): void {
    this.loadSource.next(isLoaded);
  }
}
