import {
  ProfileDTOI,
  ProfileInfoDTOI,
  SuggestionPartialSettingsI,
  SuggestionSettingsI,
  VoiceSettingsI,
} from './profile-dto';
import { SubstitutionGroupsResponseDTO } from './suggestion-rules/suggestion-rules-dto';

/**
 * Profile info.
 */
export interface ProfileInfoEntityI {
  isCurrent: boolean;
  profile: ProfileEntityI;
}

/**
 * Profile interface.
 */
export interface ProfileEntityI {
  id: string;
  name: string;
  language: string;
  application?: string;
  settings?: ProfileSettingsEntityI;
}

/**
 * Profile settings.
 */
export interface ProfileSettingsEntityI {
  substitutionRules?: SubstitutionGroupsResponseDTO[];
  topicsId?: Array<number>;
  values?: SuggestionExtraSettingsEntityI;
  myTerms?: [];
}

/**
 * Suggestions extra fields settings.
 */
export interface SuggestionExtraSettingsEntityI
  extends SuggestionSettingsI,
  SuggestionPartialSettingsI,
  VoiceSettingsI {
  dictLanguage?: string;
}

/**
 * Implementation of ProfileInfoEntityI (used for updating profile).
 */
export class ProfileInfoEntity implements ProfileInfoEntityI {
  isCurrent: boolean;
  profile: ProfileEntityI;

  static toEntity(dto: ProfileInfoDTOI): ProfileInfoEntity {
    const instance = new ProfileInfoEntity();
    instance.isCurrent = dto.isCurrent;
    instance.profile = ProfileEntity.toEntity(dto.profile);
    return instance;
  }
}

/**
 * Implementation of ProfileEntityI (used for updating profile).
 */
export class ProfileEntity implements ProfileEntityI {
  id: string;
  name: string;
  language: string;
  application?: string;
  settings?: ProfileSettingsEntityI;

  static toEntity(dto: ProfileDTOI): ProfileEntity {
    const instance = new ProfileEntity();
    instance.id = dto.id;
    instance.language = dto.language;
    instance.name = dto.name;
    instance.application = dto.application;
    instance.settings = {
      substitutionRules: dto.settings.substitutionRules,
      myTerms: dto.settings.myTerms,
      topicsId: dto.settings.topicDictionaryIds,
      values: {
        dictionaryType: dto.settings.dictionaryType,
        userLanguage: dto.settings.userLanguage,
        numberOfWords: dto.settings.numberOfWords,
        numberOfTopicWords: dto.settings.numberOfTopicWords,
        voiceId: dto.settings.voiceId,
        wildCards: dto.settings.wildCards,
        useContext: dto.settings.useContext,
        predictNextWord: dto.settings.predictNextWord,
        showHomophones: dto.settings.showHomophones,
        showSpelling: dto.settings.showSpelling,
        minWordLength: dto.settings.minWordLength,
        maxWordLength: dto.settings.maxWordLength,
        minPrefixLength: dto.settings.minPrefixLength,
        minSpellingLength: dto.settings.minSpellingLength,
        writeLetterName: dto.settings.writeLetterName,
        writeLetterSound: dto.settings.writeLetterSound,
        writeWord: dto.settings.writeWord,
        writeSentence: dto.settings.writeSentence,
        readForward: dto.settings.readForward,
        highlightWord: dto.settings.highlightWord,
        dictLanguage: dto.settings.dictionaryLanguage,
        voiceSpeed: dto.settings.voiceSpeed,
        dictation: dto.settings.dictation,
      },
    };
    return instance;
  }
}
